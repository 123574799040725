import React, {Component, Fragment} from 'react';
import {NavLink, Link} from 'react-router-dom';
import {inject, observer} from 'mobx-react';
import classNames from 'classnames';
import Modal from 'antd/lib/modal';

import {ReactComponent as Close} from '../../../images/icons/cross.svg';
import {ReactComponent as ArrowBack} from '../../../images/icons/arrow-left-line.svg';
import {ReactComponent as ArrowIcon, ReactComponent as ArrowRigthIcon} from '../../../images/icons/arrow-right-line.svg';

import {I18n} from '../../helpers/i18n/I18n';
import {STATIC_PAGE_URL} from '../../../config/const';
import {Preloader} from '../../helpers/preloader/Preloader';
import {FILTER_TYPE} from '../../mainPages/hotelSuggestion/HotelSuggestion';

export const CMP_STEPS = {
  MAIN_MENU: 'MAIN_MENU',
  DISCOUNT_LIST: 'DISCOUNT_LIST',
  COUNTRY_LIST: 'COUNTRY_LIST',
  REGION_LIST: 'REGION_LIST',
};

const linksOptions = [
  {path: '/faq', title: 'FAQ', useStaticPath: true},
  {path: '/voucher', title: 'Gutschein', useStaticPath: true},
  {path: '/best-price-guarantee', title: 'Best Preis Garantie', useStaticPath: true},
  {path: '/payment', title: 'Zahlungsmöglichkeiten', useStaticPath: true},
  {path: '/partner', title: 'Partner werden', useStaticPath: true},
  {path: '/contacts', title: 'Kontakt & Servicе', useStaticPath: true},
  {path: '/newsletter', title: 'Newsletter', useStaticPath: true},
  {path: '/imprint', title: 'Impressum', useStaticPath: true},
  {path: '/privacy', title: 'Datenschutzbestimmungen', useStaticPath: true},
  {path: '/agb', title: 'AGB', useStaticPath: true},
];

@inject('searchStore')
@observer
class MobileMainMenu extends Component {
  state = {
    step: CMP_STEPS.MAIN_MENU,
    prev_step: null,
  };

  setStep = (step, selectedCountry = null) => {
    this.setState({step, msg: null, selectedCountry});
  };

  setPreviousStep = () => {
    this.setState({msg: null});
    switch (this.state.step) {
      case 'COUNTRY_LIST':
        this.setState({step: CMP_STEPS.MAIN_MENU, selectedCountry: null, prev_step: CMP_STEPS.COUNTRY_LIST});
        break;
      case 'DISCOUNT_LIST':
        this.setState({step: CMP_STEPS.MAIN_MENU, selectedCountry: null, prev_step: CMP_STEPS.DISCOUNT_LIST});
        break;
      case 'REGION_LIST':
        this.setState({step: CMP_STEPS.COUNTRY_LIST, selectedCountry: null, prev_step: CMP_STEPS.REGION_LIST});
        break;
      default:
        return null;
    }
  };

  render() {
    const {step, prev_step} = this.state;
    const {modalOpen, closeModal, searchStore} = this.props;
    return (
      <div>
        <Modal
          wrapClassName='vertical-center-modal menu-modal'
          className='custom-modal main-menu-modal'
          closeIcon={<Close />}
          visible={modalOpen}
          onOk={closeModal}
          onCancel={closeModal}
          footer={null}
          transitionName={null}
        >
          <div className='modal-body'>
            {(step === CMP_STEPS.COUNTRY_LIST || step === CMP_STEPS.REGION_LIST) && (
              <div className='modal-header'>
                <ArrowBack onClick={this.setPreviousStep} />
                <I18n t={step === CMP_STEPS.COUNTRY_LIST ? 'Reiseziele' : this.state.selectedCountry} />
                &#58;
              </div>
            )}

            {step === CMP_STEPS.DISCOUNT_LIST && (
              <div className='modal-header'>
                <ArrowBack onClick={this.setPreviousStep} />
                <span>Sonderangebote</span>
                &#58;
              </div>
            )}

            {step === CMP_STEPS.MAIN_MENU && (
              <div className={classNames('menu-list', {'left-animation': prev_step === CMP_STEPS.COUNTRY_LIST || prev_step === CMP_STEPS.DISCOUNT_LIST})}>
                <div className='modal-header no-animation'>
                  <I18n t='Menü' />
                  &#58;
                </div>

                <span className='mobile-nav with-arrow' onClick={this.setStep.bind(this, CMP_STEPS.COUNTRY_LIST, null)}>
                  <I18n t='Reiseziele' />
                  <ArrowRigthIcon />
                </span>

                <span className='mobile-nav with-arrow' onClick={this.setStep.bind(this, CMP_STEPS.DISCOUNT_LIST, null)}>
                  <I18n t='Sonderangebote' />
                  <ArrowRigthIcon />
                </span>

                {linksOptions.map((link, i) => (
                  <NavLink key={i} to={link.useStaticPath ? `${STATIC_PAGE_URL}${link.path}` : link.path} activeClassName='active-mobile-link' className='mobile-nav' onClick={closeModal} exact>
                    <I18n t={link.title} />
                  </NavLink>
                ))}
              </div>
            )}

            {step === CMP_STEPS.COUNTRY_LIST && (
              <div className={classNames('links-list', {'left-animation': prev_step == CMP_STEPS.REGION_LIST})}>
                {searchStore.countriesAndRegions.map(
                  elem =>
                    elem.regions.length > 0 && (
                      <div className='mobile-nav with-arrow'>
                        <NavLink className='name-count-hotel' target='_blank' exact to={`/region/${(elem.cms || {}).slug}`} activeClassName='active-mobile-link'>
                          <I18n t={elem.name} className='item-title' />
                          <span className='count-hotels'>{elem.hotelsCount} Hotels</span>
                        </NavLink>
                        <span className='arrow-wrapper' onClick={this.setStep.bind(this, CMP_STEPS.REGION_LIST, elem.name)}>
                          <ArrowRigthIcon />
                        </span>
                      </div>
                    )
                )}
              </div>
            )}

            {step === CMP_STEPS.REGION_LIST && (
              <ul className='regions-list'>
                {searchStore.countriesAndRegions
                  .filter(country => country.name === this.state.selectedCountry)
                  .map(elem =>
                    elem.regions.map((region, i) => (
                      <li className='region-item'>
                        <NavLink key={i} className='mobile-nav' exact target='_blank' to={`/region/${(region.cms || {}).slug}`} activeClassName='active-mobile-link'>
                          <I18n t={region.name} />
                          <span className='count-hotels'>{region.hotelsCount} Hotels</span>
                        </NavLink>
                      </li>
                    ))
                  )}
              </ul>
            )}

            {step === CMP_STEPS.DISCOUNT_LIST && (
              <div className={classNames('links-list')}>
                <div onClick={closeModal} className='mobile-nav with-arrow'>
                  <NavLink activeClassName='active-mobile-link' exact to={`/sonderangebote/${FILTER_TYPE.lastMoment}`}>
                    Last-Minute-Reisen
                  </NavLink>
                </div>
                <div onClick={closeModal} className='mobile-nav with-arrow'>
                  <NavLink activeClassName='active-mobile-link' exact to={`/sonderangebote/${FILTER_TYPE.general}`}>
                    Angebot der Woche
                  </NavLink>
                </div>
                <div onClick={closeModal} className='mobile-nav with-arrow'>
                  <NavLink activeClassName='active-mobile-link' exact to={`/sonderangebote/${FILTER_TYPE.firstMoment}`}>
                    Frühbucher-Aktion
                  </NavLink>
                </div>
              </div>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

export {MobileMainMenu};
