import React, {Component} from 'react';
import {CheckBox} from '../checkBox/CheckBox';
import {I18n} from '../i18n/I18n';
import {inject, observer} from 'mobx-react';

@inject('searchFilters')
@observer
class CheckBoxList extends Component {
  render() {
    const {header, options, searchFilters, applyFilters} = this.props;
    return (
      <ul className='params-list'>
        {header && (
          <li className='list-header'>
            <I18n t={header} />
          </li>
        )}
        {Object.values(options)[0].map((option, i) => (
          <li key={i}>
            <CheckBox
              checked={searchFilters.selectedFilters.includes(option.id)}
              onChange={searchFilters.selectFilter.bind(this, event, option.id, applyFilters)}
              title={option.label}
              className='checkbox-option
              '
            />
            <div className='positions-cont'>{option.count > 0 ? option.count : ''}</div>
          </li>
        ))}
      </ul>
    );
  }
}

export default CheckBoxList;
