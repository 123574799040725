import React, {Component} from 'react';
import {I18n} from '../../helpers/i18n/I18n';
import {ReservationInputs} from './ReservationInputs';
import {removeMetaData, setMetaData} from '../../../utils/Utils';

export class CancelReservation extends Component {
  constructor(props) {
    super(props);
    const t = window.location.search
      .replace('?', '')
      .split('&')
      .filter(s => s.indexOf('token') === 0);

    this.state = {
      Card: ReservationInputs,
      email: '',
      reservationId: '',
      inputError: {},
    };

    if (t.length > 0) {
      this.state.reservationId = t[0].split('=')[1];
    }
  }

  componentDidMount() {
    setMetaData('Cancel Order | Booksana.com ');
  }

  componentWillUnmount() {
    removeMetaData();
  }

  changeCard = (Cmp, order = {}) => {
    this.setState({Card: Cmp, order: order});
  };

  removeWrongEmail = () => {
    this.setState({email: '', inputError: {...this.state.inputError, email: true}});
  };

  onChange = (propName, newValue) => {
    const {inputError} = this.state;
    if (inputError[propName] && newValue) {
      this.setState({[propName]: newValue, inputError: {...inputError, [propName]: false}}); //remove input error state
    } else {
      this.setState({[propName]: newValue});
    }
  };

  render() {
    const {Card, order, inputError, email, reservationId} = this.state;
    return (
      <section className='cancel-reservation-page'>
        <Card order={order} reservationId={reservationId} email={email} inputError={inputError} removeWrongEmail={this.removeWrongEmail} onChange={this.onChange} changeCard={this.changeCard} />
      </section>
    );
  }
}
