import React, {Component, Fragment} from 'react';

import {ReactComponent as BusIcon} from '../../../images/icons/bus.svg';

import {I18n} from '../../helpers/i18n/I18n';
import {Price} from '../../helpers/price/Price';
import {PriceView} from '../../helpers/priceView/PriceView';

export class GuestsCount extends Component {
  render() {
    const {guests, transferPrice, withDiscont = true, withDetails = false} = this.props;
    let adults = 0;
    let children = 0;
    return (
      <Fragment>
        {guests.map((elem, i) => (
          <div key={i} className={`${i === 0 ? 'pad' : ''} guests-count-block`}>
            <div className='guest-count'>
              {elem.adult ? (
                <span>
                  <I18n t='search.form.adults' /> {++adults}
                </span>
              ) : (
                <span>
                  <I18n t='search.form.child' /> {++children} ({elem.age} <I18n t='common.years' />)
                </span>
              )}
            </div>
            <div className='prise-for-guest'>
              <PriceView
                className='price-direction for-guest'
                price={withDiscont ? elem.price : elem.priceWithoutDiscount}
                oldPrice={elem.priceWithoutDiscount}
                hasDiscount={withDiscont ? elem.discount : false}
              />
              {withDetails && <I18n t='pro Person' className='price-descr' />}
            </div>
          </div>
        ))}
        {transferPrice ? (
          <div className='guests-count-block transfer-row'>
            <div className='guest-count'>
              <BusIcon />
              <I18n t='Bustransfer' />
            </div>
            <div className='prise-for-guest'>
              <Price price={transferPrice} />
            </div>
          </div>
        ) : null}
      </Fragment>
    );
  }
}
