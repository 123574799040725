import React from 'react';
import {computed, observable} from 'mobx';
import {getPageContent} from '../utils/Fetcher';
import {removeHtmlTxt} from '../utils/Utils';

class ContentStore {
  @observable content = [];

  constructor(callBack) {
    getPageContent().then(resp => {
      if (!resp.isError) {
        this.content = resp.data;
      }
      callBack(!resp.isError);
    });
  }

  returnContent = (key, returnHtml) =>
    computed(() => {
      const value = this.content.find(elem => elem.key === key)?.value;
      return returnHtml ? value : removeHtmlTxt(value);
    }).get();
}

export default ContentStore;
