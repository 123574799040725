import React, {Component, Fragment} from 'react';
import classNames from 'classnames';
import {withRouter} from 'react-router';
import {inject, observer} from 'mobx-react';
import {isMobileOnly} from 'react-device-detect';
import ReactHtmlParser from 'react-html-parser';

import {ReactComponent as FoodIcon} from '../../../images/icons/food.svg';
import {ReactComponent as CalendarIcon} from '../../../images/icons/calendar-event-fill.svg';
import {ReactComponent as HomeIcon} from '../../../images/icons/home.svg';
import {ReactComponent as MoonIcon} from '../../../images/icons/moon-fill.svg';
import {ReactComponent as ServiceIcon} from '../../../images/icons/services.svg';
import {ReactComponent as BusIcon} from '../../../images/icons/bus.svg';

import {concatDateStrings, doSmoothScroll, returnObligatoryArr, returnPicSize, isTablet, setElemShadow} from '../../../utils/Utils';
import {DATE_FORMAT} from '../../../config/const';

import {GuestsCount} from '../../mainPages/hotelRooms/GuestsCount';
import {RoomOptions} from '../../modals/roomOptions/RoomOptions';
import {BookingBtn} from '../../mainPages/hotelRooms/BookingBtn';
import {TaxBill} from '../../modals/taxBill/TaxBill';
import {RoomDescriptionLink} from '../roomDescriptionLink/RoomDescriptionLink';
import {TaxIncluded} from '../taxIncluded/taxIncluded';
import {MEALS_TYPES} from '../searchForm/SearchForm';
import {PriceView} from '../priceView/PriceView';
import {I18n} from '../i18n/I18n';

import {Price} from '../price/Price';
import {ProsList} from '../prosList/ProsList';
import {HotelName} from '../hotelName/HotelName';
import {AddedInfo} from '../addedInfo/AddedInfo';
import Employee from '../../../images/employee.jpg';
import transfer from '../../modals/transfer/Transfer';

const prosOptions = [
  {title: '100% risikofrei: 48 Stunden Bedenkzeit und kostenfrei stornierbar', isBold: false},
  {title: 'Mit Bestpreis Garantie', isBold: false},
  {title: 'Deutscher Reiseveranstalter. Ihre EU-Pauschalreise ist vollständig im Rahmen der aktuellen EU-Reiserichtlinie abgesichert.', isBold: false},
];

@withRouter
@inject('popup')
@observer
class OfferRoom extends Component {
  state = {
    descriptionIsOpen: false,
    activeSlide: 0,
    isOpen: false,
    photoIndex: 0,
  };

  toggleExpander = () => {
    this.setState({descriptionIsOpen: !this.state.descriptionIsOpen});
  };

  bookRoom = (room, hotel, request) => {
    this.props.history.push(`/${hotel.nameUrl}/payment/${room.resultId}`);
  };

  openModal = (modalCmp, txt) => {
    this.props.popup.open(modalCmp, null, null, txt);
  };

  openObligatoryModal = (modalCmp, params) => {
    if (!params.description) {
      return;
    }

    this.props.popup.open(modalCmp, null, null, params);
  };

  highlightDateInputs = () => {
    setElemShadow('#search_start_date');
    setElemShadow('#search_end_date');
  };

  scrollToForm = room => {
    isMobileOnly && doSmoothScroll('#main-search-form', 36);
    this.props.checkDateInputs && this.props.checkDateInputs();
    room.treatmentNeedsExtraDays && this.highlightDateInputs();
  };

  returnHostAddress = () => {
    const protocol = location.protocol;
    const hostName = window.location.hostname === 'localhost' ? `${window.location.hostname}:3000` : window.location.hostname;
    const slashes = protocol.concat('//');
    return slashes.concat(hostName);
  };

  returnCopyLink = (url, resultId) => {
    const urlParams = this.props.match.params;
    const searchRequestId = urlParams.searchRequestId;
    return this.returnHostAddress() + url + `${searchRequestId ? `/${searchRequestId}` : ''}` + `?resultId=${resultId}`;
  };

  renderRoomSlides = media => {
    const querySize = returnPicSize('room', window.innerWidth);
    return media.map((elem, i) => <img className='room-pic' key={i} itemProp='photo' alt='hotel img' src={`${elem}${querySize}`} />);
  };
  toggleGallery = newVal => {
    this.setState({isOpen: newVal});
  };
  onMoveRequest = val => {
    this.setState({photoIndex: val});
  };

  render() {
    const {isOpen, photoIndex} = this.state;
    const {room, hotel, request, formData = {}, hideRoomDescription, showFees, showHotelPic, paymentsType} = this.props;
    const {kurFeeExists, kurFeeFreeAdults, kurFeeFreeAdultsFrom, kurFeeFreeChildren, kurFeeFreeChildrenTill, kurFeePrice, kurFeeType, url} = hotel;
    const fees = {
      kurFeeExists,
      kurFeeFreeAdults,
      kurFeeFreeAdultsFrom,
      kurFeeFreeChildren,
      kurFeeFreeChildrenTill,
      kurFeePrice,
      kurFeeType,
    };

    return (
      <div className='offer-room'>
        <h2 className='offer-date'>
          <I18n t='Ihr Reiseangebot:' />{' '}
          {!isMobileOnly && (
            <Fragment>
              {concatDateStrings(request.arrivalDate).format(DATE_FORMAT)} - {concatDateStrings(request.departureDate).format(DATE_FORMAT)}
            </Fragment>
          )}
        </h2>
        {!isMobileOnly && (
          <div className='offer-hotel'>
            <HotelName name={hotel.name} stars={hotel.stars} separator={'im '} />
          </div>
        )}
        {isMobileOnly && (
          <div className='offer-packages'>
            <p className='packages-hotel'>
              <HotelName name={hotel.name} stars={hotel.stars} separator={'im '} />
            </p>
          </div>
        )}
        <div className='offer-detail'>
          <h3 className='detail-title'>
            <I18n t='Ihre Reise und Leistungen im Überblick:' />
          </h3>
          {/*<p className='detail-services'>*/}
          {/*  <I18n t='Leistungen im Preis inkludiert:' />*/}
          {/*</p>*/}
          <ul>
            <li className='detail-item marked'>
              <div className='detail-days'>
                <CalendarIcon />
                <I18n t='Reisezeitraum:' className='detail-subItem' />
                <span>
                  {concatDateStrings(request.arrivalDate).format(DATE_FORMAT)} - {concatDateStrings(request.departureDate).format(DATE_FORMAT)}
                </span>
              </div>
              <div className='detail-days'>
                <MoonIcon />
                <span className='nights-count detail-subItem'>
                  {room.nights} <I18n t='common.night' />
                </span>
              </div>
            </li>
            <li className='detail-item marked'>
              <HomeIcon />
              <div className='detail-room'>
                <I18n t='Ihre Zimmerkategorie:' className='detail-subItem' />
                <span itemProp='name' className='room-name detail-subItem'>
                  {room.roomName}
                </span>
                {isMobileOnly && (
                  <div className='actions-popup-wrapper'>
                    {!hideRoomDescription && (
                      <RoomDescriptionLink
                        hotel={hotel}
                        room={room}
                        openRoomModal={this.openModal}
                        modal={RoomOptions}
                        roomAmenities={room.roomAmenities}
                        classModal='room-modal'
                        classIcon='custom-icon sm-md check green'
                      />
                    )}
                    {/*{showFees && <RoomsFeeLink price={room.price} openModal={this.openModal} arrivalDate={request.arrivalDate} cancellationOptions={hotel.cancellationFees} />}*/}
                  </div>
                )}
              </div>
            </li>
            <li className='detail-item marked'>
              <FoodIcon />
              <I18n t='Verpflegung:' className='detail-subItem' />
              <span className='detail-meal'>{MEALS_TYPES[room.kindOfCatering]}</span>
            </li>
            {room.transferPrice ? (
              <li className='detail-item marked'>
                <BusIcon />
                <I18n t='Haustürtransfer mit Hin- und Rückfahrt:' />
                <span className='detail-transfer' style={{paddingLeft: '5px'}}>
                  ab PLZ {room.transferIndex}
                </span>
              </li>
            ) : null}
            <li className='detail-service'>
              <div>
                <ServiceIcon />
                <I18n t='Ihre Kurpaket: ' className='detail-service-title' />
                <span className='detail-service-package'>{`"${room.treatmentName.trim()}"`}</span>
              </div>
              {/*<div className='service-list'>{ReactHtmlParser(room.treatmentDescription)}</div>*/}
              <div className='service-list'>{room.treatmentDescription ? ReactHtmlParser(room.treatmentDescription.slice(0, room.treatmentDescription.indexOf('</ul>'))) : ''}</div>
              <AddedInfo info={room.treatmentDescription.slice(room.treatmentDescription.indexOf('<p>'))} />
            </li>
          </ul>
          <h3 className='detail-title'>
            <I18n t='Kostenaufstellung Ihres Angebots:' />
          </h3>
          <ul>
            <li className='detail-item '>
              <div className='detail-days'>
                <CalendarIcon />
                <I18n t='Reisezeitraum:' className='detail-subItem' />
                <span>
                  {concatDateStrings(request.arrivalDate).format(DATE_FORMAT)} - {concatDateStrings(request.departureDate).format(DATE_FORMAT)}
                </span>
              </div>
              <div className='detail-days'>
                <MoonIcon />
                <span className='nights-count detail-subItem'>
                  {room.nights} <I18n t='common.night' />
                </span>
              </div>
            </li>
            <li className='detail-item room-info'>
              <div className='room-info-block'>
                <HomeIcon />
                <span>
                  <I18n t={room.roomName} /> inkl. {room.treatmentName.trim()} mit {MEALS_TYPES[room.kindOfCatering]}
                </span>
              </div>
              <div className='room-cost'>
                <I18n t='Reisende:' />
                <GuestsCount transferPrice={null} guests={room.perGuestPrice} withDiscont={room.discountInfo.discountType} withDetails={true} />
              </div>
            </li>
            {room.transferPrice ? (
              <li className='detail-item transfer-info'>
                <div className='guest-count'>
                  <span className='transfer-icon'>
                    <BusIcon />
                  </span>
                  <span className='transfer-details'>
                    <span className='detail-transfer'>Haustürtransfer ab PLZ {room.transferIndex}</span>
                    <I18n t='(Hin- und Rückfahrt)' />
                  </span>
                </div>
                <div className='prise-for-guest'>
                  <Price price={room.transferPrice} claccName='transfer-cost' />
                  <I18n t='für alle Reisende' className='final-cost' />
                </div>
              </li>
            ) : null}
            <li className='detail-item detail-item-price'>
              <div className='price-block'>
                <div className='total-price-block'>
                  <I18n t='Gesamtpreis:' className='title-block' />
                  <PriceView className='price-direction total-price' hasDiscount={room.discountInfo.discountType} oldPrice={room.priceWithoutDiscount} price={room.price} />
                  <I18n t='für alle Reisende' className='final-cost' />
                </div>
                <span className='tax-bill'>
                  <TaxIncluded openModal={this.openModal.bind(this, TaxBill, fees)} />
                </span>
              </div>
            </li>
          </ul>
          <div className='offer-contacts'>
            <div className='offer-tel'>
              <div className='offer-contacts-info'>
                <div className='contact-img'>
                  <img alt='employee' src={Employee} />
                </div>
                <div className='contacts-info'>
                  <span>Haben Sie Rückfragen?</span>
                  <span className='contacts-info-sm'>Wir beraten Sie persönlich:</span>
                  <span className='contacts-info-sm'>Mo. - Fr.: 10 - 19 Uhr</span>
                  <span className='contacts-info-sm'>Sa.: 10 - 14 Uhr</span>
                </div>
              </div>
              <a className='custom-empty-btn' href='tel:030403687799'>
                Tel.: 030 - 40 368 77 99
              </a>
              <div className='contacts-notice'>*Bitte beachten Sie, dass die Verfügbarkeiten (Zimmer-Kontingente) aufgrund zwischenzeitlicher Buchungen Dritter sich jederzeit ändern kann.</div>
            </div>
            <div className='offer-online'>
              <div className='offer-promo'>
                <p className='offer-notice'>Aktion:</p>
                <p className='offer-code'>
                  Bei einer Online-Buchung innerhalb der nächsten 24 Stunden erhalten Sie mit dem Rabatt-Code <span className='offer-code-red'>ANG20EUR</span> einen Rabatt in Hohe von 20€!
                </p>
              </div>
              <BookingBtn extraDays={room.treatmentNeedsExtraDays} bookRoom={this.bookRoom.bind(this, room, hotel)} scrollToForm={this.scrollToForm.bind(this, room)} titleBtn={'Zur online Buchung'} />
              <div className='room-pros'>
                <ProsList list={prosOptions} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OfferRoom;
