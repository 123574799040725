import React, {Component} from 'react';
import Collapse from 'antd/lib/collapse';
import CheckBoxList from './CheckBoxList';
import {translate} from '../../../utils/Translater';
import {inject, observer} from 'mobx-react';

const {Panel} = Collapse;

@inject('searchStore')
@observer
export class CollapseList extends Component {
  onChange = activeFilterTabs => {
    this.props.searchStore.activeFilterTabs = activeFilterTabs;
  };
  render() {
    const {applyFilters, filterAttributes} = this.props;

    function createPanel(panelKey, group, attributes) {
      let options = [];
      attributes?.forEach((attr, i) => {
        if (attr.group === group && attr.count != 0) {
          options.push({id: i, label: attr.label, count: attr.count == -1 ? 0 : attr.count});
        }
      });

      if (options.length < 1) {
        return;
      }

      return (
        <Panel key={panelKey} header={translate('modal.checkbox.' + group)}>
          <CheckBoxList applyFilters={applyFilters} options={{options}} />
        </Panel>
      );
    }
    return (
      <Collapse onChange={this.onChange} defaultActiveKey={this.props.searchStore.activeFilterTabs} className='custom-collapse options-collapse'>
        {createPanel('1', 'FILTER_GROUP_HOTEL_CATEGORIES', filterAttributes)}
        {createPanel('2', 'FILTER_GROUP_INDICATIONS', filterAttributes)}
        {createPanel('4', 'FILTER_GROUP_HOTEL_FACILITIES', filterAttributes)}
        {createPanel('5', 'FILTER_GROUP_WELLNESS_AND_SPA', filterAttributes)}
        {createPanel('6', 'FILTER_GROUP_SPORT_AND_REST', filterAttributes)}
        {createPanel('7', 'FILTER_GROUP_FOOD', filterAttributes)}
      </Collapse>
    );
  }
}
