import React, {Component} from 'react';
import moment from 'moment';
import classNames from 'classnames';
import {Link} from 'react-router-dom';

import {ReactComponent as GoodMark} from '../../../images/icons/good_mark.svg';
import {ReactComponent as PersonIcon} from '../../../images/icons/user-fill.svg';
import {ReactComponent as MoonIcon} from '../../../images/icons/moon-fill.svg';
import {ReactComponent as CalendarIcon} from '../../../images/icons/calendar-event-fill.svg';

import {HotelAddress, RENDER_TYPES} from '../hotelAddress/HotelAddress';
import {OfferCard} from '../gridSuggestions/OfferCard';
import {HotelName} from '../hotelName/HotelName';
import {HotelLink} from '../HotelLink';
import {Price} from '../price/Price';
import {I18n} from '../i18n/I18n';

import {returnPicSize} from '../../../utils/Utils';
import {DATE_FORMAT} from '../../../config/const';

export class GalleryCard extends Component {
  click = e => {
    const target = '_blank';
    const {searchRequestId} = this.props.card;
    if (searchRequestId) {
      const url = e.target.closest('a').href + '/' + searchRequestId;
      if (target === '_blank') window.open(url);
      else window.location.href = url;
      e.stopPropagation();
      e.preventDefault();
    }
  };

  render() {
    const {description = true, card, className} = this.props;
    const {hotel, results, searchRequestId} = card;
    const imagesArr = hotel.media || [];
    const previewImg = imagesArr[0];
    const querySize = returnPicSize('home', window.innerWidth);
    const startDate = moment(`${results[0].arrivalDate.dayOfMonth}.${results[0].arrivalDate.monthValue}.${results[0].arrivalDate.year}`, 'DD.MM.YYYY').format(DATE_FORMAT);
    const endDate = moment(`${results[0].departureDate.dayOfMonth}.${results[0].departureDate.monthValue}.${results[0].departureDate.year}`, 'DD.MM.YYYY').format(DATE_FORMAT);
    const {discountType = ''} = results[0].discountInfo || {};

    return (
      <Link
        target='_blank'
        to={hotel.url}
        onClick={this.click}
        itemScope
        itemType='http://schema.org/Hotel http://schema.org/Product'
        className={classNames('gallery-block', {'with-banner': results[0].discountInfo.discountType}, className)}
      >
        <div className='img-container'>
          <OfferCard discount={results[0].discountInfo} />
          <HotelLink className='hotel-img-block' target='_blank' hotel={hotel} searchRequestId={searchRequestId}>
            {previewImg ? (
              <img itemProp='photo' alt='hotel img' src={`${previewImg}${querySize}`} className='region-img' />
            ) : (
              <div className='empty-img'>
                <I18n t='hotel image' />
              </div>
            )}
          </HotelLink>
        </div>
        <div className='txt-block'>
          {Number(hotel.rating) > 0 && (
            <div className='mark-block'>
              <I18n t='common.raiting' className='rating-txt' />
              <div className='mark-good sm'>{hotel.rating}</div>
              {results[0].featured && <GoodMark className='good-mark-icon' />}
            </div>
          )}
          <h3>
            <HotelLink target='_blank' hotel={hotel} searchRequestId={searchRequestId}>
              <HotelName blockType stars={hotel.stars || 0} name={hotel.name} />
            </HotelLink>
          </h3>
          <div className='hotel-location'>
            <HotelAddress hotel={hotel} separator='・' renderType={RENDER_TYPES.HALF} />
          </div>
          <div className='mark-and-price'>
            <div>
              <div className='description-txt'>
                <p>
                  {(description || discountType === 'First moment discount') && (
                    <span className='banner-profit spa-package'>
                      <span className=''>{results[0].treatmentName}</span>
                    </span>
                  )}
                  {Number(hotel.rating) > 0 && (
                    <div className='mark-block'>
                      <I18n t='common.raiting' className='rating-txt' />
                      <div className='mark-good sm'>{hotel.rating}</div>
                      {results[0].featured && <GoodMark className='good-mark-icon' />}
                    </div>
                  )}
                </p>
                {(description || discountType === 'First moment discount') && (
                  <div itemScope itemType='http://schema.org/HotelRoom' className='short-description'>
                    <h4>
                      <span itemProp='name'>{results[0].roomName}</span>
                    </h4>
                  </div>
                )}
              </div>
            </div>
            <div className='price-block'>
              <div className='info-card-trip'>
                <div className='info-item'>
                  <PersonIcon className='item-icon' />
                  <I18n t='Pro Person ' />
                </div>
                <div className='info-item'>
                  <CalendarIcon className='item-icon' />
                  <span className='period'>
                    <span>{startDate} &#8211; </span>
                    <span>{endDate}</span>
                  </span>
                </div>
                <div className='info-item'>
                  <MoonIcon className='item-icon' />
                  <span className='count-nights'>{card.nights}</span>
                  <I18n t='common.night' />
                </div>
              </div>
              <div className={classNames('price', {'row-type': !results[0].discountInfo.discountType})}>
                {!!results[0].discountInfo.discountType ? (
                  <span className='old-price'>
                    <Price isDiscount price={results[0].perGuestPrice[0].priceWithoutDiscount} />
                  </span>
                ) : (
                  <span className='current-price-txt'>
                    <I18n t='ab ' />
                  </span>
                )}
                <span itemProp='priceSpecification' itemScope itemType='http://schema.org/UnitPriceSpecification' className='current-price'>
                  <span>
                    <meta itemProp='price' content={results[0].perGuestPrice[0].price} />
                    <Price withDiscount={!!results[0].discountInfo.discountType} price={results[0].perGuestPrice[0].price} className='card-price' />
                    <meta itemProp='priceCurrency' content='€' />
                    {/*todo*/}
                    <meta itemProp='unitCode' content='DAY' />
                    {/*todo*/}
                  </span>
                  {/*<I18n t="€"/>*/}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className='price-horizontal-block'>
          <div className='price-wrapper'>
            <div className='info-card-trip'>
              <div className='info-item'>
                <PersonIcon className='item-icon' />
                <I18n t='Pro Person ' />
              </div>
              <div className='info-item'>
                <CalendarIcon className='item-icon' />
                <span className='period'>
                  <span>{startDate} &#8211; </span>
                  <span>{endDate}</span>
                </span>
              </div>
              <div className='info-item'>
                <MoonIcon className='item-icon' />
                <span className='count-nights'>{card.nights}</span>
                <I18n t='common.night' />
              </div>
            </div>
            <div className={classNames('price', {'row-type': !results[0].discountInfo.discountType})}>
              {!!results[0].discountInfo.discountType ? (
                <span className='old-price'>
                  <Price isDiscount price={results[0].perGuestPrice[0].priceWithoutDiscount} />
                </span>
              ) : (
                <span className='current-price-txt'>
                  <I18n t='ab ' />
                </span>
              )}
              <span itemProp='priceSpecification' itemScope itemType='http://schema.org/UnitPriceSpecification' className='current-price'>
                <span>
                  <meta itemProp='price' content={results[0].perGuestPrice[0].price} />
                  <Price withDiscount={!!results[0].discountInfo.discountType} price={results[0].perGuestPrice[0].price} />
                  <meta itemProp='priceCurrency' content='€' />
                  {/*todo*/}
                  <meta itemProp='unitCode' content='DAY' />
                  {/*todo*/}
                </span>
                {/*<I18n t="€"/>*/}
              </span>
            </div>
          </div>
        </div>
      </Link>
    );
  }
}
