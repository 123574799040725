import React, {Component} from 'react';
import classNames from 'classnames';
import Popover from 'antd/lib/popover';
import {QuestionCircleOutlined} from '@ant-design/icons';
import ReactHtmlParser from 'react-html-parser';
import {isMobile, isTablet} from 'react-device-detect';
import {inject, observer} from 'mobx-react';

import {I18n} from '../../helpers/i18n/I18n';

@inject('contentStore')
@observer
export class OptionRow extends Component {
  render() {
    const {selectedOptions, guestsCount, option, overlayClassName} = this.props;
    const isTransferOption = option.hasOwnProperty('addonId');
    const optionId = isTransferOption ? option.addonId : option.id;
    const currentOptionsCount = selectedOptions[optionId] ? selectedOptions[optionId].options.length : 0;
    const finalPrice = selectedOptions[optionId] ? selectedOptions[optionId].finalPrice : 0;
    const transferTxt = option.name.match(/\bRollator\b/)
      ? this.props.contentStore.returnContent('line settings_rollator', true)
      : this.props.contentStore.returnContent('line settings_rollstuhl', true);
    return (
      <div className={classNames('option-wrapper', {'empty-option': currentOptionsCount <= 0})}>
        <div className='name-option'>
          <span className='name-card'>{option.name}</span>
          <Popover
            overlayClassName={classNames('custom-popover', overlayClassName)}
            content={ReactHtmlParser(isTransferOption ? transferTxt : option.description)}
            placement='right'
            trigger={isMobile || isTablet ? 'click' : 'hover'}
          >
            <QuestionCircleOutlined className='hint-icon' />
          </Popover>
        </div>
        <div className='counter-price-block'>
          <div className='counter-wrapper'>
            <I18n t={isTransferOption ? 'Anzahl:' : 'Personenanzahl:'} className='counter-title' />
            <div className='btn-counter'>
              <button disabled={currentOptionsCount <= 0} onClick={this.props.changeOptionsCount.bind(this, false, option)} className='count-icon minus'>
                &minus;
              </button>
              <p>{currentOptionsCount}</p>
              <button disabled={currentOptionsCount >= guestsCount} onClick={this.props.changeOptionsCount.bind(this, true, option)} className='count-icon plus'>
                &#43;
              </button>
            </div>
          </div>
          <div className={classNames('price-regulation', {'without-regulation': isTransferOption})}>
            {!isTransferOption && <I18n t={option.regulation === 'ONCE' ? 'Preis pro Aufenthalt:' : 'Preis pro Tag:'} className='regulation-name' />}
            <span className='total-price'>{finalPrice} &#8364;</span>
          </div>
        </div>
      </div>
    );
  }
}
