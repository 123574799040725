import {QuestionCircleOutlined} from '@ant-design/icons';
import Popover from 'antd/lib/popover';
import ReactHtmlParser from 'react-html-parser';
import React, {Component} from 'react';
import {InputTxt} from './InputTxt';
import {isMobile, isTablet} from 'react-device-detect';

export class InputPopover extends Component {
  render() {
    const {onChange, onlyLetters, focusOnStart, onlyNumbers, placeholder, value, name, wrappersClass, title, popoverTitle, popoverContent, overlayClassName, iconClass, inputError} = this.props;
    return (
      <div className={`${inputError ? 'error-border' : ''} input-popover-wrapper`}>
        <InputTxt
          onlyNumbers={onlyNumbers}
          onlyLetters={onlyLetters}
          focusOnStart={focusOnStart}
          placeholder={placeholder}
          wrappersClass={wrappersClass}
          onChange={onChange}
          name={name}
          value={value}
        />
        {popoverContent && (
          <Popover overlayClassName={`custom-popover ${overlayClassName || ''}`} content={ReactHtmlParser(popoverContent)} placement='right' trigger={isMobile || isTablet ? 'click' : 'hover'}>
            <QuestionCircleOutlined className={`hint-icon ${iconClass || ''}`} />
          </Popover>
        )}
      </div>
    );
  }
}
