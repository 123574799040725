//hotel suggestion card types
export const RECOMMENDATION = 'RECOMMENDATION';
export const LOW_PRICE = 'LOW_PRICE';
export const DATE_FORMAT = 'DD.MM.YYYY';
export const OPTION_DATE_FORMAT = 'DD-MM-YYYY';
export const PAGE_SIZE = 10;
export const STATIC_PAGE_URL = '/information';
export const MAIL = 'info@booksana.com';

export const STATIC_TXT_KEYS = {
  contacts: 'contact',
  transfer: 'transfer',
  partner: 'partner',
  voucher: 'gutschein_page',
  newsletter: 'gutschein_page',
  agb: 'agb_booksana',
  faq: 'faq',
  imprint: 'Impressum',
  privacy: 'datenschutz_booksana',
  'best-price-guarantee': 'best_preis_garantie',
  'agb-voyager': 'agb_voyager',
  'privacy-voyager': 'datenschutz_voyager',
  'billing-options': 'zahlung_info',
  'formblatt-informationspflichten': 'formblatt_informationspflichten',
};
export const returnSizeString = (w, h) => `width=${w}&height=${h}`;
export const PIC_SIZES = {
  home: {
    pc: returnSizeString(380, 235),
    tablet: returnSizeString(279, 185),
    mobile: returnSizeString(279, 185), //slider
  },
  homeRegions: {
    pc: returnSizeString(590, 263),
    tablet: returnSizeString(478, 240),
    mobile: returnSizeString(283, 240),
  },
  homeRegionsSm: {
    pc: returnSizeString(384, 263),
    tablet: returnSizeString(312, 240),
    mobile: returnSizeString(283, 240),
  },
  searchResults: {
    pc: returnSizeString(242, 200), //slider
    tablet: returnSizeString(400, 232), //slider
    mobile: returnSizeString(162, 107), //slider
  },
  hotelMainSlider: {
    pc: returnSizeString(696, 396), //slider
    tablet: returnSizeString(300, 232), //slider
    mobile: returnSizeString(window.innerWidth, 250), //slider
  },
  roomTopCheckout: {
    pc: returnSizeString(439, 370), //slider
    tablet: returnSizeString(280, 384), //slider
    mobile: returnSizeString(window.innerWidth - 32, 176), //slider 32 is paddings
  },
  room: {
    pc: returnSizeString(439, 352),
    tablet: returnSizeString(280, 350),
    mobile: returnSizeString(180, 122), //slider
  },
  checkout: {
    pc: returnSizeString(290, 194),
    tablet: returnSizeString(290, 194),
    mobile: returnSizeString(window.innerWidth - 56, 194), // 56 is paddings
  },
};
export const ORDER_STATUS = new Map([
  [1, 'Neu'],
  [2, 'In Bearbeitung'],
  [3, 'Bestätigt'],
  [4, 'Eingecheckt'],
  [5, 'Stornierungsanfrage'],
  [6, 'Abgesagt'],
  [7, 'Keine Show'],
  [8, 'Nicht bestätigt'],
  [9, 'Geschlossen'],
  [100, 'Gebucht'],
]);
