import React from 'react';
import {PureComponent} from 'preact/compat';
import {translate} from '../../../utils/Translater';
import {CancelationOption} from '../../helpers/CancelationOption';

class RoomFees extends PureComponent {
  render() {
    const {price, arrivalDate, cancellationOptions = [], vendorName} = this.props;

    return (
      <div className='room-fees'>
        <div className='room-fees__description'>
          <CancelationOption arrivalDate={arrivalDate} price={price} options={cancellationOptions} />
        </div>
      </div>
    );
  }
}

export default RoomFees;
