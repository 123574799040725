import React, {Component} from 'react';

export class CopyField extends Component {
  state = {
    cbClass: false,
    timer: null,
  };

  copyField = React.createRef();

  setTimer() {
    return setTimeout(() => {
      this.setState({timer: null, show: false});
    }, 1000);
  }

  copyTxt = () => {
    if (!this.state.timer) {
      this.copyField.current.value = '';
      this.copyField.current.value = this.props.copyTxtValue || this.props.txt;
      this.copyField.current.select();
      this.copyField.current.setSelectionRange(0, 99999);
      document.execCommand('copy');
      this.copyField.current.blur();
      this.setState({timer: this.setTimer(), show: true});
    }
  };

  render() {
    const {txt, cdTxt = 'Скопировано', children} = this.props;
    return (
      <div className={`copy-field-container  ${this.props.wrapperClass || ''} ${this.state.show ? 'copied' : ''}`}>
        <input ref={this.copyField} type='text' defaultValue={txt} />
        {children && children}
        <div onClick={this.copyTxt}>
          <span>{txt}</span>
        </div>
        <div className='cb-txt'>{cdTxt}</div>
      </div>
    );
  }
}
