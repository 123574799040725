import React, {Component, Fragment} from 'react';

export class AgeDiscounts extends Component {
  render() {
    if (!this.props.ageDiscounts || !this.props.ageDiscounts.length) {
      return null;
    }

    const ageWithoutDiscount = this.props.ageDiscounts[this.props.ageDiscounts.length - 1].till + 1;

    return (
      <Fragment>
        {this.props.ageDiscounts.map((age, key) => (
          <p className='no-pad' key={key}>
            Kinder von {age.from} bis {age.till} Jahren:
            {age.discount == 100 ? ' kostelos' : ` ${age.discount}% Reduktion vom Erwachsenenpreis`}
          </p>
        ))}
        <p className='no-pad'>Ab {ageWithoutDiscount} Jahren: 0% Reduktion vom Erwachsenenpreis</p>
      </Fragment>
    );
  }
}
