import React, {Component} from 'react';
import Modal from 'antd/lib/modal';
import {inject} from 'mobx-react';

import {ReactComponent as Close} from '../../../images/icons/cross.svg';
import {CollapseList} from '../../helpers/gridSuggestions/CollapseList';
import {I18n} from '../../helpers/i18n/I18n';

@inject('searchFilters')
class TableFilter extends Component {
  applyFilters = () => {
    const {selectedFilters} = this.props.searchFilters;
    this.props.onOk(undefined, selectedFilters); //call search method , which is located in main form
    this.props.closeModal();
  };

  resetFilters = () => {
    this.props.searchFilters.resetFilters(this.props.onOk);
    this.props.closeModal();
  };

  render() {
    const {modalOpen, closeModal} = this.props;
    return (
      <div>
        <Modal
          wrapClassName='vertical-center-modal full-size'
          className='custom-modal table-filter-modal'
          closeIcon={<Close />}
          visible={modalOpen}
          onOk={closeModal}
          onCancel={closeModal}
          footer={null}
        >
          <h3 className='modal-header md'>
            <I18n t='modal.header.filterby' />:
          </h3>
          <div className='modal-body'>
            <CollapseList filterAttributes={this.props.params.filterAttributes} />
          </div>
          <div className='buttons-container'>
            <button className='custom-empty-btn' onClick={this.resetFilters}>
              <I18n t={'modal.button.removefilter'} />
            </button>
            <button className='custom-btn' onClick={this.applyFilters}>
              <I18n t={'modal.button.showresults'} />
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default TableFilter;
