import classNames from 'classnames';
import {MenuOutlined} from '@ant-design/icons';
import Select from 'antd/lib/select';
import Popover from 'antd/lib/popover';
import {inject, observer} from 'mobx-react';
import React, {Component, Fragment} from 'react';
import {Link, NavLink, Route, withRouter} from 'react-router-dom';
import {ReactComponent as Logos} from '../../../images/logo.svg';
import {ReactComponent as ArrowIcon} from '../../../images/icons/arrow-right-line.svg';
import {ReactComponent as Arrow} from '../../../images/icons/vector_1.svg';
import {PaymentHeader} from '../paymentHeader/PaymentHeader';
import Logo from '../../../images/logo.png';
import {I18n} from '../i18n/I18n';
import {MobileMainMenu} from '../../modals/mobileMainMenu/MobileMainMenu';
import {STATIC_PAGE_URL} from '../../../config/const';
import {RegionItem} from './RegionItem';
import {RegionsList} from './RegionsList';
import {isMobile} from '../../../utils/Utils';
import {DiscountDropDown} from './DiscountDropDown';
import {isMobileOnly} from 'react-device-detect';
import {ReviewsWidget} from '../../mainPages/payment/ReviewsWidget';
import Employee from '../../../images/employee.jpg';

@withRouter
@inject('popup', 'searchStore', 'contentStore')
@observer
export class Header extends Component {
  openModal = modalCmp => {
    this.props.popup.open(modalCmp);
  };

  goHome = () => {
    this.props.history.push('/');
  };

  render() {
    const {showRegionMenu} = this.state;
    const {searchStore, contentStore, history} = this.props;

    const hideShadow = /\/search-results|\/recommendations|\/sonderangebote/.test(history.location.pathname);
    const className = classNames('main-header', {'no-shadow': hideShadow});
    const isHeaderHidden = /\/payment/.test(history.location.pathname) && !isMobile();

    if (isHeaderHidden) {
      return null;
    }

    return (
      <Fragment>
        {isMobileOnly && (
          <div className='center-container'>
            <ReviewsWidget scriptUrl='https://www.provenexpert.com/widget/richsnippet.js?u=18zA0Vwol82ZjRmA1HGplOKB48TA1LQB&v=3' />
          </div>
        )}
        <header className={className}>
          <nav>
            <div onClick={this.goHome} className='main-logo'>
              <Logos />
            </div>
            <ul style={{marginLeft: 24}}>
              <li>
                <NavLink exact to='/' activeClassName='active-link'>
                  <I18n t='Startseite' />
                </NavLink>
              </li>
              <li className='dropdown-menu'>
                <div className={classNames('title-menu')}>
                  <I18n t='Reiseziele' />
                </div>
                <RegionsList regions={searchStore.countriesAndRegions} />
              </li>

              <li className={classNames('discount-menu-container')}>
                <NavLink exact to='/sonderangebote' activeClassName='active-link'>
                  <I18n t='Sonderangebote' />
                </NavLink>

                <DiscountDropDown discountLink={this.discountLink} />
              </li>
              {!isMobileOnly && (
                <li>
                  <ReviewsWidget scriptUrl='https://www.provenexpert.com/widget/richsnippet.js?u=18zA0Vwol82ZjRmA1HGplOKB48TA1LQB&v=2' />
                </li>
              )}
              {isMobileOnly && (
                <li className='link-delimiter'>
                  <div className='phone-container'>
                    <a href={`tel:${contentStore.returnContent('phone_number')}`}>
                      <i className='custom-icon-phone' />
                      <I18n class='menu-phone' t={contentStore.returnContent('phone_number')} />
                    </a>
                    <Popover overlayClassName='custom-popover md header-phone' content={contentStore.returnContent('phone_tooltip')} placement='bottomRight' trigger='click'>
                      <i className='custom-hint black lg' />
                    </Popover>
                  </div>
                  <span>{this.props.contentStore.returnContent('working_time')}</span>
                </li>
              )}
              {!isMobileOnly && (
                <li className='link-contact'>
                  <div className='employee-img'>
                    <img alt='employee' src={Employee} />
                  </div>
                  <div className='contact-data'>
                    <a href={`tel:${this.props.contentStore.returnContent('phone_number')}`} className='phone-number'>
                      <i className='custom-icon-phone bl' />
                      {this.props.contentStore.returnContent('phone_number')}
                    </a>
                    <p className='working-time'>{this.props.contentStore.returnContent('working_time')}</p>
                  </div>
                </li>
              )}
              {/*<li>*/}
              {/*  <NavLink exact to={`${STATIC_PAGE_URL}/contacts`} activeClassName='active-link'>*/}
              {/*    <I18n t='Kontakt & Servicе' />*/}
              {/*  </NavLink>*/}
              {/*</li>*/}
            </ul>
            <MenuOutlined onClick={this.openModal.bind(this, MobileMainMenu)} className='mobile-view mobile-menu' />
          </nav>
        </header>
      </Fragment>
    );
  }
}
