import React, {Component} from 'react';
import {I18n} from '../../helpers/i18n/I18n';
import {ReservationInputs} from './ReservationInputs';
import {FoundOrder} from './FoundOrder';
import {Preloader} from '../../helpers/preloader/Preloader';
import {withRouter} from 'react-router';
import {cancelOrder, getText} from '../../../utils/Fetcher';
import {Link} from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

@withRouter
export class ApplyCancellation extends Component {
  state = {
    fetching: false,
    cancellationDone: false,
    respData: null,
    cancelationDoneContent: '',
  };

  componentDidMount() {
    getText('cancelation_success_static').then(resp => this.setState({cancelationDoneContent: resp.data?.value}));
  }

  cancelReservation = () => {
    this.setState({fetching: true});
    cancelOrder(this.props.email, this.props.reservationId).then(resp => {
      let respData = null;
      !resp.isError && (respData = resp.data);
      this.setState({fetching: false, cancellationDone: true, respData});
      // !resp.isError && this.props.history.push("/")
    });
  };

  render() {
    const {fetching, cancellationDone, respData} = this.state;
    return (
      <article className='cancel-card'>
        <div className='left-section mobile-hide'></div>
        <div className='right-section apply-cancellation'>
          {!cancellationDone && (
            <div className='sections-footer'>
              <h4>
                <I18n t='Sind Sie sicher?' />
              </h4>
              <button className='custom-empty-btn' onClick={this.props.changeCard.bind(this, ReservationInputs)}>
                <I18n t='Nein / Abbrechen ' />
              </button>
              <button className='custom-btn' onClick={this.cancelReservation}>
                <I18n t='Ja / Stornieren' />
              </button>
            </div>
          )}
          {cancellationDone && respData && (
            <div className='sections-txt cancel-results'>
              <h4>{ReactHtmlParser(this.state.cancelationDoneContent)}</h4>
              {/*<Link to="/"><I18n t="You can return to the home page"/></Link>*/}
            </div>
          )}
          {cancellationDone && !respData && (
            <div className='sections-txt cancel-results'>
              <h4>
                <I18n t='Server side error' />
              </h4>
              <Link to='/'>
                <I18n t='You can return to the home page' />
              </Link>
            </div>
          )}
        </div>
        {fetching && <Preloader />}
      </article>
    );
  }
}
