import React, {Component, PureComponent} from 'react';
import {isMobile, isMobileOnly} from 'react-device-detect';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment';

import {ReactComponent as GoodMark} from '../../../images/icons/good_mark.svg';
import {ReactComponent as Schedule} from '../../../images/icons/schedule.svg';
import {ReactComponent as Pulse} from '../../../images/icons/pulse.svg';
import {ReactComponent as Home} from '../../../images/icons/home.svg';
import {ReactComponent as Food} from '../../../images/icons/food.svg';
import {ReactComponent as Bus} from '../../../images/icons/bus.svg';

import {returnPicSize, isTablet} from '../../../utils/Utils';

import {HotelAddress, RENDER_TYPES} from '../hotelAddress/HotelAddress';
import {TreatmentsPeriod} from '../treatmentsPeriod/TreatmentsPeriod';
import {GuestsCount} from '../../mainPages/hotelRooms/GuestsCount';
import {ServiceIcons} from '../serviceIcons/ServiceIcons';
import {MEALS_TYPES} from '../searchForm/SearchForm';
import {StarsBlock} from '../starsBlock/StarsBlock';
import {PriceView} from '../priceView/PriceView';
import {Slider} from '../slider/Slider';
import {HotelLink} from '../HotelLink';
import {Price} from '../price/Price';
import {I18n} from '../i18n/I18n';
import {FooterBtns} from './FooterBtns';
import {OfferCard} from './OfferCard';
import {FlexibleSlider} from './FlexibleSlider';

@withRouter
class ResultCard extends PureComponent {
  returnFormattedDate = date => {
    return moment([date.year, date.monthValue - 1, date.dayOfMonth]).format('DD.MM.YYYY');
  };

  render() {
    const {result, onDateBtnClick, renderDateSelectBtn} = this.props;
    const hotel = result.hotel;
    const room = result.results[0];
    const media = hotel.media || [];
    const slider = () => {
      if (media.length < 1) {
        return (
          <HotelLink target='_blank' className='slider-item' hotel={hotel} searchRequestId={result.searchRequestId}>
            <div className='main-img'>
              <I18n t='Hotel image' />
            </div>
          </HotelLink>
        );
      }
      const querySize = returnPicSize('searchResults', window.innerWidth);
      return media.map((url, i) => (
        <HotelLink target='_blank' key={i} className='slider-item' hotel={hotel} searchRequestId={result.searchRequestId}>
          <img itemProp='photo' className='main-img' alt='hotelImg' src={`${url}${querySize}`} />
        </HotelLink>
      ));
    };
    const settings = {
      arrows: false,
      lazyLoad: 'ondemand',
      // infinite: false,
      slidesToShow: 2,
      variableWidth: true,
      // responsive: [
      //   {breakpoint: 450, settings: {slidesToShow: 3}},
      //   {breakpoint: 650, settings: {slidesToShow: 4}},
      //   {breakpoint: 850, settings: {slidesToShow: 5}},
      // ],
    };
    return (
      <div className={classNames('results-card', {'discount-info': room.discountInfo.discountType})}>
        <div className='direction-wrapper'>
          <OfferCard discount={room.discountInfo} />
          <div className='left'>
            <div className='header-info'>
              <h3>
                <span className='banner-profit spa-package'>{room.treatmentName}</span>
                <div className='rating-block'>
                  {Number(hotel.rating) > 0 && (
                    <div className='mark-block'>
                      <I18n class='block-txt' t='common.raiting' />
                      <div className='mark-good sm'>{hotel.rating}</div>
                    </div>
                  )}
                  {room.featured && <GoodMark />}
                </div>
              </h3>
            </div>
            <div className='hotel-link'>
              <div className='link-stars-block'>
                <HotelLink target='_blank' hotel={hotel} searchRequestId={result.searchRequestId}>
                  <span itemProp='name'>{hotel.name}</span>
                </HotelLink>
                <StarsBlock count={[...Array(Number(hotel.stars || 0))]} />
              </div>
            </div>
            <div className='location-wrapper'>
              <HotelAddress className='location' hotel={hotel} separator=', ' renderType={RENDER_TYPES.HALF} />
            </div>
            <ServiceIcons service={hotel.mainFields || {}} />
            {isMobileOnly && (
              <div className={`${room.discountInfo.discountType ? 'pad' : ''} rooms-gallery-wrapper`}>
                <Slider settings={settings} wrapperClass='mobile-gallery rooms' renderSlides={slider} />
              </div>
            )}
            {/* <div className='room-name' itemProp='name'>
              {room.roomName}
            </div> */}
            <FlexibleSlider hotel={hotel} searchRequestId={result.searchRequestId} />
          </div>
          <div className='right'>
            <div className={classNames('date-nights', {mt: isMobileOnly})}>
              <div className='date'>
                <Schedule />
                <span>{`${this.returnFormattedDate(room.arrivalDate)} - ${this.returnFormattedDate(room.departureDate)}`}</span>
              </div>
              <div className='nights'>
                {result.nights} <I18n t='common.night' />
              </div>
            </div>
            <div className='supply'>
              <Food />
              <span>{`Verpflegung: ${MEALS_TYPES[room.kindOfCatering]}`}</span>
            </div>
            {(room.treatmentPerDay || room.treatmentPerStay || room.treatmentPerWeek) && (
              <div className='treatments'>
                <Pulse />
                <span className='incl'>Inkl.</span>
                <TreatmentsPeriod perDay={room.treatmentPerDay} perStay={room.treatmentPerStay} perWeek={room.treatmentPerWeek} />
              </div>
            )}
            <div className='price-block'>
              <div className='price-without-transfer'>
                <div>
                  <Home />
                  <span>{room.roomName}</span>
                </div>
                {/* <PriceView className='price-direction' hasDiscount={room.discountInfo.discountType} oldPrice={room.priceWithoutDiscount - room.transferPrice} price={room.price - room.transferPrice} /> */}
              </div>
              <GuestsCount guests={room.perGuestPrice} />
            </div>
            {room.transferPrice !== 0 && (
              <div className='transfer-block'>
                <div>
                  <Bus />
                  <I18n t='Bustransfer' />
                </div>
                <Price price={room.transferPrice} />
              </div>
            )}
            <div className='final-price'>
              <I18n t='Gesamtpreis:' />
              <PriceView className='price-direction total-price' hasDiscount={room.discountInfo.discountType} oldPrice={room.priceWithoutDiscount} price={room.price} />
            </div>
            <FooterBtns
              hotel={hotel}
              extraDays={room.treatmentNeedsExtraDays}
              minimalStay={room.treatmentMinimalStay}
              searchRequestId={result.searchRequestId}
              renderDateSelectBtn={renderDateSelectBtn}
              onDateBtnClick={onDateBtnClick}
              onClick={this.openResult}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ResultCard;
