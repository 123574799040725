import React, {Fragment} from 'react';
import {StarsBlock} from '../starsBlock/StarsBlock';

export const HotelName = ({name, stars = 0, onClick, blockType, separator = ''}) => {
  const content = (
    <Fragment>
      <span itemProp='name'>{`${separator}${name}`}</span> <StarsBlock className='centered' count={[...Array(Number(stars))]} />
    </Fragment>
  );
  if (blockType) {
    return (
      <div onClick={onClick} className='hotel-name-rating'>
        {content}
      </div>
    );
  }
  return (
    <h1 onClick={onClick} className='hotel-name-rating'>
      {content}
    </h1>
  );
};
