import React, {Component} from 'react';
import {I18n} from '../i18n/I18n';
import {GalleryCard} from './GalleryCard';
import {Slider} from '../slider/Slider';
import {isMobileOnly} from 'react-device-detect';
import {withRouter} from 'react-router';
import classNames from 'classnames';

@withRouter
export class HomeGallery extends Component {
  renderSlides = () => {
    const {description, cards = [], cardType} = this.props;
    return cards.map((elem, i) => <GalleryCard className={i === 0 ? 'lg' : ''} card={elem} description={description} cardType={cardType} key={i} />);
  };

  onBtnClick = () => {
    this.props.onBtnClick && this.props.onBtnClick();
    this.props.history.push(`${this.props.linkAddress}`);
  };

  render() {
    const {description, wrapperClass, headerTxt, headerTitle, btnTxt, cards = [], cardType} = this.props;
    const items = cards.length;
    const mobileSettings = {
      arrows: false,
      infinite: cards.length >= 2 ? true : false,
      slidesToShow: 1,
      centerMode: true,
      variableWidth: true,
    };
    const desktopSettings = {
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      variableWidth: true,
    };

    if (items < 1) {
      return null;
    }

    return (
      <article className={`hotel-card  ${wrapperClass || ''}`}>
        <h2 className='section-header'>
          <I18n t={headerTitle} />
          {headerTxt && (
            <p className='header-description'>
              <I18n t={headerTxt} />
            </p>
          )}
        </h2>
        {isMobileOnly && (
          <div className='cards-gallery mobile-view'>
            <Slider settings={mobileSettings} wrapperClass='mobile-gallery hotels' renderSlides={this.renderSlides} />
          </div>
        )}

        {!isMobileOnly && items <= 3 && (
          <div className={classNames('cards-gallery', {'centered-block': cards.length < 2}, {'horizontal-card': cards.length <= 2})}>
            {cards.map((elem, i) => (
              <GalleryCard cardType={cardType} card={elem} description={description} key={i} className={cards.length < 3 ? 'resize-card' : ''} />
            ))}
          </div>
        )}

        {!isMobileOnly && items > 3 && (
          <div className='cards-gallery slider-gallery'>
            <Slider
              useAccent
              useOuterControls
              settings={desktopSettings}
              renderSlides={() => cards.map((elem, i) => <GalleryCard cardType={cardType} card={elem} description={description} key={i} />)}
            />
          </div>
        )}

        {btnTxt && cards.length > 2 && (
          <div className='btn-wrapper'>
            <button onClick={this.onBtnClick} className='apply-btn custom-empty-btn-mdx'>
              <I18n t={btnTxt} />
            </button>
          </div>
        )}
      </article>
    );
  }
}
