import React, {Component} from 'react';

export class ReviewsWidget extends Component {
  componentDidMount() {
    const scriptCollection = document.getElementsByTagName('script');
    const isScriptExist = [...scriptCollection].some(({src}) => src === this.props.scriptUrl);

    if (isScriptExist) {
      [...scriptCollection].find(({src}) => src === this.props.scriptUrl).remove();
    }
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = this.props.scriptUrl;

    document.body.append(script);
  }

  componentWillUnmount() {
    const scriptCollection = document.getElementsByTagName('script');
    const script = [...scriptCollection].find(({src}) => src === this.props.scriptUrl);

    if (script) {
      script.parentNode.removeChild(script);
    }
  }

  render() {
    return <div className='pe-richsnippets' />;
  }
}
