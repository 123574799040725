import React, {Component} from 'react';
import classNames from 'classnames';
import {I18n} from '../../helpers/i18n/I18n';
import {OptionRow} from './OptionRow';

export class OptionsViewer extends Component {
  render() {
    const {options, guestsCount, isTransfer, changeOptionsCount, selectedOptions, transferIsActive} = this.props;

    if (options.length <= 0) {
      return null;
    }

    return (
      <article className={classNames('option-info border-form', {mask: isTransfer && !transferIsActive})} id={options.length > 0 ? 'hotelServices' : undefined}>
        <h3 className='form-header'>
          <I18n t={isTransfer ? 'Transfer-Zusatzleistungen:' : 'Hotel-Zusatzleistungen:'} />
        </h3>
        <div className='option-block'>
          {options.map((option, i) => (
            <OptionRow key={i} guestsCount={guestsCount} selectedOptions={selectedOptions} changeOptionsCount={changeOptionsCount} option={option} overlayClassName='payments md' />
          ))}
        </div>
      </article>
    );
  }
}
