import React, {Component, Fragment} from 'react';
import Collapse from 'antd/lib/collapse';
import classNames from 'classnames';

import {ReactComponent as HomeIcon} from '../../../images/icons/home.svg';
import {ReactComponent as BusIcon} from '../../../images/icons/bus.svg';
import {ReactComponent as CouponIcon} from '../../../images/icons/coupon.svg';
import {ReactComponent as ShieldIcon} from '../../../images/icons/shield.svg';

import {addDiscount} from '../../../utils/Utils';
import {Price} from '../../helpers/price/Price';
import {I18n} from '../../helpers/i18n/I18n';
import {CheckBox} from '../../helpers/checkBox/CheckBox';
import {PriceView} from '../../helpers/priceView/PriceView';

const {Panel} = Collapse;

const RoomInfoHeader = ({room}) => (
  <div className='collapse-header-form option-header'>
    <p>
      <HomeIcon className='item-icon' />
      <I18n t={room.roomName} className='title-row' />
    </p>
    {/* <PriceView className='price-direction' hasDiscount={room.discountInfo.discountType} oldPrice={room.priceWithoutDiscount - room.transferPrice} price={room.price - room.transferPrice} /> */}
  </div>
);

const OptionInfoHeader = ({label, price}) => (
  <div className='option-info-header'>
    <I18n t={label} className='title-row' />
    <Price price={price} className='price-option' />
  </div>
);

const OptionRow = ({optionsObj, className, changeOptionActivity, isTransferOption}) => {
  const objKeys = Object.keys(optionsObj);
  if (!objKeys.length) {
    return null;
  }
  return (
    <div className='wrapper-with-checkbox'>
      {objKeys.map(key => {
        if (optionsObj[key] && optionsObj[key].options.length > 0) {
          const isChecked = optionsObj[key].options.some(option => option.isActive);
          return (
            <div className={classNames('item-with-checkbox', {'inactive-row': !isChecked, 'partly-inactive': optionsObj[key].options.some(option => !option.isActive)})}>
              <CouponIcon className='item-icon' />
              <CheckBox onChange={changeOptionActivity.bind(this, key, null, isTransferOption, true)} className='sm hide_txt padding' checked={isChecked} indeterminate={true} />
              <Collapse expandIconPosition='right'>
                <Panel className={classNames(className)} header={<OptionInfoHeader price={optionsObj[key].priceByActiveElems} label={optionsObj[key].name} />}>
                  {optionsObj[key].options.map((option, i) => (
                    <div key={i} className={classNames('person-item-row', {inactive: !option.isActive})}>
                      <CheckBox onChange={changeOptionActivity.bind(this, key, i, isTransferOption, false)} className='sm hide_txt' checked={option.isActive} />
                      <I18n t='Person' className='person-title' /> <span className='option-index'>{option.index}</span> &#58; <Price price={option.commissionPrice} className='price-for-person' />
                    </div>
                  ))}
                </Panel>
              </Collapse>
            </div>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};

export class GuestsCount extends Component {
  returnActiveTransferPrices = () => {
    return this.props.transferCount.reduce((acc, option) => {
      option.isActive && (acc += option.transferPrice);
      return acc;
    }, 0);
  };
  returnAllOptionsPrice = (optionsObj, isTransferOptions) => {
    // if (isTransferOptions && !this.props.transferIsActive) {
    //   return 0;
    // }
    return Object.keys(optionsObj).reduce((acc, key) => {
      optionsObj[key].options.length && (acc += optionsObj[key].priceByActiveElems);
      return acc;
    }, 0);
  };

  areThereSelectedOption = optionsObj => {
    return Object.keys(optionsObj).some(key => optionsObj[key].options.length > 0);
  };

  render() {
    const {
      transferAddons = {},
      changeTransferCount,
      changeOptionActivity,
      hotelOptions = {},
      transferCount,
      room,
      promoDiscount,
      promoCode,
      showOldPrice,
      minusTransferPrice,
      transferExists,
      transferIsActive,
    } = this.props;
    const {discountInfo, perGuestPrice, price, priceWithoutDiscount} = room;
    let adults = 0;
    let children = 0;
    const hotelOptionsExist = this.areThereSelectedOption(hotelOptions);
    const transferOptionsExist = this.areThereSelectedOption(transferAddons);
    const transferPrice = this.returnActiveTransferPrices();
    const allOptionsPrice = this.returnAllOptionsPrice(transferAddons, true) + this.returnAllOptionsPrice(hotelOptions, false) + this.returnActiveTransferPrices();
    return (
      <div className='booking-item-options'>
        <Collapse defaultActiveKey={['1']} expandIconPosition='right'>
          <Panel key='1' header={<RoomInfoHeader room={room} />}>
            {perGuestPrice.map((guest, i) => (
              <div key={i} className='person-item-row'>
                <span>
                  {guest.adult ? (
                    <span>
                      <I18n t='search.form.adults' /> {++adults}
                    </span>
                  ) : (
                    <span>
                      <I18n t='search.form.child' /> {++children} ({guest.age} <I18n t='common.years' />)
                    </span>
                  )}
                  :
                </span>
                <PriceView className='price-direction' price={guest.price} oldPrice={guest.priceWithoutDiscount} hasDiscount={guest.discount} />
              </div>
            ))}
          </Panel>
        </Collapse>

        {hotelOptionsExist && <OptionRow changeOptionActivity={changeOptionActivity} optionsObj={hotelOptions} />}

        {transferExists && (
          <div className={classNames('transfer-row', {'inactive-row': !transferIsActive, 'partly-inactive': transferCount.some(guest => !guest.isActive)})}>
            <BusIcon className='item-icon' />
            <CheckBox className='sm hide_txt padding' checked={transferIsActive} onChange={changeTransferCount.bind(this, null, true)} />
            <Collapse defaultActiveKey={['1']} expandIconPosition='right'>
              <Panel key='1' header={<OptionInfoHeader label='Bustransfer' price={transferPrice} />}>
                {transferCount.map((guest, i) => (
                  <div className={classNames('person-item-row', {inactive: !guest.isActive})}>
                    <CheckBox checked={guest.isActive} className='sm hide_txt' onChange={changeTransferCount.bind(this, i, false)} />
                    <span className='person-title'>
                      <I18n t='Person' /> {i + 1}&#58;
                    </span>{' '}
                    <Price price={guest.transferPrice} className='price-for-person' />
                  </div>
                ))}
              </Panel>
            </Collapse>
          </div>
        )}

        {transferOptionsExist && <OptionRow changeOptionActivity={changeOptionActivity} isTransferOption optionsObj={transferAddons} />}

        {/* <div className='insurance-row'>
          <CheckBox className='sm hide_txt' checked={true} />
          <ShieldIcon className='item-icon' />
          <I18n t='Reiseschutz (RundumSorglos-Schutz Auto,Bus,Bahn + Covid19)' className='title-row' />
          <Price price={200} className='insurance-price' />
        </div> */}

        {promoDiscount && (
          <div className='coupon-field'>
            <span className='title-row'>
              <I18n class='bold-txt' t='Rabatt' /> (<I18n t='Gutscheincode:' /> {promoCode}):
            </span>
            <Price className='bold-txt' price={-promoDiscount} />
          </div>
        )}

        <div className='final-sum-wrapper'>
          <div className='final-sum'>
            <I18n t='Gesamtpreis:' className='title-total-price' />
            <div className='sum-direction'>
              {showOldPrice && discountInfo.discountType ? (
                <span className='old-price'>
                  <Price minusTransferPrice={minusTransferPrice} transferPrice={transferPrice} isDiscount price={priceWithoutDiscount - room.transferPrice + allOptionsPrice} /> &#47;
                </span>
              ) : null}
              {showOldPrice && !discountInfo.discountType && promoDiscount && (
                <span className='old-price'>
                  <Price minusTransferPrice={minusTransferPrice} transferPrice={transferPrice} isDiscount price={price - room.transferPrice + allOptionsPrice} /> &#47;
                </span>
              )}
              <span className='final-price'>
                <Price
                  price={(promoDiscount ? addDiscount(promoDiscount, price) : price) - room.transferPrice + allOptionsPrice}
                  withDiscount={showOldPrice && (discountInfo.discountType || promoDiscount)}
                  minusTransferPrice={minusTransferPrice}
                  transferPrice={transferPrice}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
