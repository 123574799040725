import React, {Component} from 'react';
import Tabs from 'antd/lib/tabs';
import {I18n} from '../../helpers/i18n/I18n';

import * as Scroll from 'react-scroll/modules';

const SpyLink = Scroll.Link;

const {TabPane} = Tabs;

export class Navigation extends Component {
  state = {
    activeTab: '1',
  };

  checkClickedTab = key => {
    this.setState({activeTab: key});
  };

  render() {
    const {isOffer = false} = this.props;
    const {activeTab} = this.state;
    const offset = this.props.isDesktop ? -211 : -48;
    return (
      <section className='tabs-slider-block'>
        <Tabs animated={false} activeKey={activeTab}>
          <TabPane
            tab={
              <SpyLink spy={true} smooth={true} delay={0} onSetActive={this.checkClickedTab.bind(this, '1')} duration={800} offset={offset} to={isOffer ? 'offer-wrapper' : 'form-hotel-header'}>
                {isOffer ? <I18n t='Ihr Angebot' /> : <I18n t='hotel.tabs.room_and_spa' />}
              </SpyLink>
            }
            key='1'
          />
          <TabPane
            tab={
              <SpyLink spy={true} smooth={true} delay={0} onSetActive={this.checkClickedTab.bind(this, '2')} duration={800} offset={offset} to='descriptionTab'>
                <I18n t='Hotelbeschreibung' />
              </SpyLink>
            }
            key='2'
          />
          <TabPane
            tab={
              <SpyLink spy={true} smooth={true} delay={0} onSetActive={this.checkClickedTab.bind(this, '3')} duration={800} offset={offset} to='rulesTab'>
                <I18n t='Hotelrichtlinien' />
              </SpyLink>
            }
            key='3'
          />
          <TabPane
            tab={
              <SpyLink spy={true} smooth={true} delay={0} onSetActive={this.checkClickedTab.bind(this, '4')} duration={800} offset={offset} to='treatmentTab'>
                <I18n t='Behandlungen' />
              </SpyLink>
            }
            key='4'
          />
          <TabPane
            tab={
              <SpyLink spy={true} smooth={true} delay={0} onSetActive={this.checkClickedTab.bind(this, '5')} duration={800} offset={offset} to='indicationsTab'>
                <I18n t='Indikationen' />
              </SpyLink>
            }
            key='5'
          />
        </Tabs>
      </section>
    );
  }
}
