import React from 'react';
import Modal from 'antd/lib/modal';
import {isMobileOnly} from 'react-device-detect';
import cn from 'classnames';
import {ReactComponent as Close} from '../../../images/icons/cross.svg';

import './style.scss';

const Transfer = ({isActive, onClose}) => (
  <Modal wrapClassName='vertical-center-modal' className='custom-modal transfer-modal' closeIcon={<Close />} visible={isActive} onCancel={onClose} footer={null} destroyOnClose>
    <h3 className={cn('modal-header', {pt: isMobileOnly})}>
      <div>Haustürtransfer</div>
      <div>Werfen Sie Ihre Sorgen über Bord und starten Sie entspannt in den Urlaub.</div>
    </h3>

    <div className='modal-body'>
      <p>
        Bei uns beginnt Ihre Reise nach Ihrem Wunsch bereits ab Ihrer Haustür.
        <br />
        Für Sie bedeutet dies, dass Sie sich um nichts kümmern müssen und Ihrer Erholungs-Pur bereits ab der ersten Minuten geniessen können!
      </p>
      <ul>
        <li>Direkt ab ihrer Haustür</li>
        <li>Stress frei und Entspannt in den Urlaub</li>
        <li>Keine schweren Koffer tragen</li>
        <li>Keine Parkgebühren vor Ort</li>
      </ul>
      <p className='cancelation-fee'>
        <strong>Buchen Sie Ihrer Aufenthalt gleich mit Haustürabholung</strong>
      </p>
      <p>Und so geht’s:</p>
      <ol>
        <li>Wählen Sie in die Suchmaske eine Option „mit Bustransfer“</li>
        <li>Geben Sie Ihre Postleitzahl und wählen Sie Ihr An- und Abreisetermin</li>
        <li>Wählen Sie Ihre Wunschaufenthalt und klicken Sie auf den Button "zur Buchung"</li>
        <li>Schliessen Sie Ihren Buchungsvorgang mit der Abholadresse ab</li>
      </ol>
      <p className='cancelation-fee'>Den Rest werden wir für Sie erledigen, Sie müssen nun Ihr Koffer zum Reisetermin selber packen!</p>
      <p>Über die Abholzeiten wird Ihnen spätestens 1 Tag vor der Abreise vom Fahrdienst telefonisch benachrichtigt.</p>
      <p className='cancelation-fee'>
        <strong>Brauchen Sie mehr Informationen?</strong>
      </p>
      <p>
        Gern sind Ihnen auch unsere Reiseberater unter der Rufnummer:
        <br />
        030 40 368 77 99 persönlich für Sie da!
      </p>
    </div>
  </Modal>
);

export default Transfer;
