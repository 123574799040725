import React, {Component} from 'react';
import Modal from 'antd/lib/modal';
import ReactHtmlParser from 'react-html-parser';
import {I18n} from '../../helpers/i18n/I18n';
import {ReactComponent as Close} from '../../../images/icons/cross.svg';
import {CancelationOption} from '../../helpers/CancelationOption';

class TxtBox extends Component {
  render() {
    const {modalOpen, closeModal, params} = this.props;
    return (
      <div>
        <Modal wrapClassName='vertical-center-modal' className='custom-modal txt-box-modal' closeIcon={<Close />} visible={modalOpen} onOk={closeModal} onCancel={closeModal} footer={null}>
          <h3 className='modal-header'>
            <I18n t={params.header} />
          </h3>
          <div className='modal-body'>
            <CancelationOption arrivalDate={params.arrivalDate} price={params.price} options={params.cancellationOptions} />
            {params.txt && <p>{ReactHtmlParser(params.txt)}</p>}
            {params.roomAmenities && (
              <div className='apartment-options'>
                {params.roomAmenities.map((elem, i) => (
                  <div key={i}>
                    <i className='custom-icon check md' />
                    {elem.label}
                  </div>
                ))}
              </div>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

export {TxtBox};
