import React, {Component} from 'react';
import Employee from '../../../images/employee.jpg';
import {I18n} from '../i18n/I18n';
import {EmployeeCard} from '../employeeCard/EmployeeCard';
import {isMobileOnly} from 'react-device-detect';
import {inject, observer} from 'mobx-react';

@inject('contentStore')
@observer
export class EmployeeLgBoard extends Component {
  render() {
    return !isMobileOnly ? (
      <section className='footer-img-container'>
        <div>
          <div className='img-bord'>
            <img alt='img' src={Employee} />
            <div className='txt-block'>
              <h3>
                <I18n t='Sie benötigen Hilfe bei der Auswahl Ihres Kururlaubs?' />
              </h3>
              <p className='main-txt'>
                <I18n t='Rufen Sie uns einfach an, wir beraten Sie gerne kostenlos und unverbindlich:' />
              </p>
              <p className='phone-number'>{this.props.contentStore.returnContent('phone_number')}</p>
              <p className='working-time'>{this.props.contentStore.returnContent('working_time')}</p>
            </div>
          </div>
        </div>
      </section>
    ) : (
      <section className='employee-card-wrapper'>
        <EmployeeCard />
      </section>
    );
  }
}
