import React, {Component} from 'react';
import {I18n} from '../i18n/I18n';

export class SwimmingBtn extends Component {
  state = {
    isShown: true,
  };

  container = React.createRef();
  posWrap = React.createRef();

  componentDidMount() {
    window.addEventListener('scroll', this.stickyfyBtn);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.stickyfyBtn);
  }

  stickyfyBtn = () => {
    const position = this.container.current.getBoundingClientRect();
    const posToShow = 48;
    if (position.top < posToShow) {
      !this.posWrap.current.classList.contains('fixed') && this.posWrap.current.classList.add('fixed');
    } else {
      this.posWrap.current.classList.contains('fixed') && this.posWrap.current.classList.remove('fixed');
    }
  };

  render() {
    if (!this.state.isShown) {
      return null;
    }
    return (
      <div ref={this.container} className='swimming-container'>
        <div className='swimming-btn-wrapper'>
          <div ref={this.posWrap} className='position-wrapper'>
            <button className='custom-btn' onClick={this.props.onClick}>
              <I18n t='Reisedaten ändern' />
            </button>
          </div>
        </div>
      </div>
    );
  }
}
