import React, {Component} from 'react';
import {InputTxt} from '../../helpers/inputTxt/InputTxt';

export class BirthInputs extends Component {
  month = React.createRef();
  year = React.createRef();

  onChange = (name, value) => {
    const fullState = 2;
    const {onChange, birthDayName, birthMonthName} = this.props;
    if (name === birthDayName) {
      (value.length === fullState || Number(value) > 3) && this.month.current.focus();
    } else if (name === birthMonthName) {
      (value.length === fullState || Number(value) > 1) && this.year.current.focus();
    }
    onChange(name, value);
  };

  render() {
    const {
      wrapperClass,
      birthDay,
      birthDayName,
      birthDayClass,
      birthDayError,
      birthMonth,
      birthMonthName,
      birthMonthClass,
      birthMonthError,
      birthYear,
      birthYearName,
      birthYearClass,
      birthYearError,
      birthDayTitle,
    } = this.props;
    return (
      <div className={wrapperClass || ''}>
        <InputTxt
          onChange={this.onChange}
          onlyNumbers={true}
          dayCheck={true}
          maxLength={2}
          placeholder='DD'
          title={birthDayTitle}
          inputError={birthDayError}
          wrappersClass={birthDayClass || ''}
          name={birthDayName}
          value={birthDay}
        />
        <InputTxt
          ref={this.month}
          onChange={this.onChange}
          onlyNumbers={true}
          monthCheck={true}
          maxLength={2}
          placeholder='MM'
          inputError={birthMonthError}
          wrappersClass={birthMonthClass || ''}
          name={birthMonthName}
          value={birthMonth}
        />
        <InputTxt
          ref={this.year}
          onChange={this.onChange}
          yearCheck={true}
          onlyNumbers={true}
          maxLength={4}
          placeholder='YYYY'
          inputError={birthYearError}
          wrappersClass={birthYearClass || ''}
          name={birthYearName}
          value={birthYear}
        />
      </div>
    );
  }
}
