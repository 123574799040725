import React, {Component} from 'react';
import {I18n} from '../i18n/I18n';
import {TreatmentMinDays} from '../treatmentMinDays/TreatmentMinDays';
import {HotelLink} from '../HotelLink';

export class FooterBtns extends Component {
  render() {
    const {wrapperClass, extraDays, minimalStay, onClick, hotel, renderDateSelectBtn, onDateBtnClick, searchRequestId} = this.props;
    return (
      <div className={`footer-btns ${wrapperClass || ''} ${renderDateSelectBtn ? '' : 'solo-btn'}`}>
        {!renderDateSelectBtn && extraDays ? (
          <button style={{minWidth: '251px'}} className='custom-empty-btn show-all btn-to-form' onClick={onDateBtnClick}>
            <I18n t='search.card.button.change_period' />
          </button>
        ) : (
          <HotelLink target='_blank' hotel={hotel} searchRequestId={searchRequestId}>
            <button className='custom-empty-btn show-all' onClick={onClick}>
              <I18n t='search.card.button.showalloffers' />
            </button>
          </HotelLink>
        )}
        {renderDateSelectBtn && (
          <button className='custom-btn-md enter-data' onClick={onDateBtnClick}>
            <I18n t='search.card.button.tripdate' />
          </button>
        )}
        {!renderDateSelectBtn && <TreatmentMinDays btnClick={onDateBtnClick} extraDays={extraDays} minimalStay={minimalStay} />}
      </div>
    );
  }
}
