import {observable} from 'mobx';
import {nanoid} from 'nanoid';

export const MSG_TYPES = ['OK', 'ERROR', 'WARNING', 'INFO'];

export class LoggerStore {
  @observable msgs = new Map();

  constructor() {
    // this.addItem({type: "OK", msgTxt: "Example"})
    // this.addItem({type: "ERROR", msgTxt: "Example"})
    // this.addItem({type: "WARNING", msgTxt: "Example"})
    // this.addItem({type: "INFO", msgTxt: "Example"})
    // setInterval(() => this.addItem({type: "INFO", msgTxt: "Example"}), 4000)
  }

  addItem = item => {
    this.msgs.set(nanoid(), item);
  };

  removeItem = key => {
    this.msgs.delete(key);
  };
}
