import * as axios from 'axios';

export const BASE_URL = process.env.REACT_APP_SERVER_URL;

export const getXToken = () => ''; //window.stores.app.session?.token;

const Fetcher = axios.create({
  baseURL: BASE_URL,
});

export const getHeader = (header = {}) => {
  return {
    'x-token': getXToken(),
    ...header,
  };
};

const GET = (url, data, headers) => Fetcher.request({url, data, method: 'GET', headers: getHeader(headers)});
const DELETE = (url, data, headers) => Fetcher.request({url, data, method: 'DELETE', headers: getHeader(headers)});
const POST = (url, data, headers) => Fetcher.request({url, data, method: 'POST', headers: getHeader(headers)});

async function makeCall(method, url, data, headers = {}) {
  let result = null;
  try {
    let resp = await method(url, data, headers);
    result = {
      data: resp.data,
      isError: false,
      url: url,
      response: resp,
    };
  } catch (e) {
    console.error(e);

    if (e.response?.status === 403) {
      //window.stores.app.stopSession();
    }

    result = {
      data: e.response?.data,
      isError: true,
      url: url,
      response: e.response,
    };
  }

  return result;
}

export const search = (data, pagination) => {
  let requestString = '';
  if (Object.keys(pagination).length > 0) {
    requestString += '?';
    Object.keys(pagination).forEach((key, i) => {
      const property = i > 0 ? `&${[key]}` : `${[key]}`;
      requestString += `${property + '=' + pagination[key]}`;
    });
  }
  return makeCall(POST, `/search${requestString}`, data);
};
export const changeTransfer = (searchResultId, transferIndex) => makeCall(GET, `/search/change-transfer?searchResultId=${searchResultId}&transferIndex=${transferIndex}`);
export const getCountriesAndRegions = () => makeCall(GET, `dictionary/countries`);
export const regionsAndHotels = (query, country) => makeCall(GET, `/dictionary/regionsAndHotels${query ? `/${query}` : ''}${country ? `?country=${country}` : ''}`);
export const getSavedSearches = () => makeCall(POST, '/cms/pages');
export const getText = key => makeCall(GET, `dictionary/text/${key}`);
export const getPageContent = () => makeCall(GET, `dictionary/texts`);
export const getIndicationOptions = query => makeCall(GET, `/dictionary/indications/${query || encodeURI('%')}`);
export const bookRoom = data => makeCall(POST, '/booking', data);
export const confirmPayment = data => makeCall(POST, '/stripe/confirm_payment', data, {'Content-Type': 'application/json'});
//export const setupIntent = () => makeCall(GET, `/stripe/setup`);
export const setupIntent = email => makeCall(GET, `/stripe/setup/${email}`);
export const findOrder = (email, token) => makeCall(GET, `/booking/findByToken/${email}/${token}`);
export const cancelOrder = (email, token) => makeCall(GET, `/booking/cancel/${email}/${token}`);
export const checkPromoCode = code => makeCall(GET, `/booking/checkPromo/${code}`);
export const returnPaymentRoom = hotelId => makeCall(GET, `/search/details/result/${hotelId}`);
export const callLinkDestroy = (hotelId, searchRequestId) => makeCall(GET, `/search/store_result?hotelId=${hotelId}&searchRequestId=${searchRequestId}`);
export const callLinkDestroyV2 = searchResultId => makeCall(GET, `/search/store_result?searchResultId=${searchResultId}`);
export const getHotelRoomsById = (hotelId, requestId, pagination) => {
  let requestString = '';
  if (Object.keys(pagination).length > 0) {
    requestString += '?';
    Object.keys(pagination).forEach((key, i) => {
      const property = i > 0 ? `&${[key]}` : `${[key]}`;
      requestString += `${property + '=' + pagination[key]}`;
    });
  }
  return makeCall(GET, `/search/details/${hotelId}${requestId ? `/${requestId}` : ''}${requestString}`);
};
