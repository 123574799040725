import React, {Component} from 'react';
import {ReactComponent as Separator} from '../../../images/icons/rectangle-separator.svg';

import {I18n} from '../i18n/I18n';

export class BreadCrumbs extends Component {
  render() {
    const {items = []} = this.props;
    const lastElem = items.length - 1;
    return (
      <div className='hotel-location-menu'>
        <ul>
          {items.map((elem, i) => (
            <li key={i}>
              {elem.src ? (
                <a href={elem.src}>
                  <I18n t={elem.link} />
                </a>
              ) : (
                <I18n t={elem.link} />
              )}
              {i !== lastElem && (
                <span className='location-separator'>
                  <Separator />
                </span>
              )}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}
