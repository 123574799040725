import React, {Component} from 'react';
import {I18n} from '../i18n/I18n';
import moment from 'moment';
import {DATE_FORMAT} from '../../../config/const';

export class AttentionBanner extends Component {
  firstMoment = 'First moment discount';
  lastMoment = 'Last moment discount';

  render() {
    const {discountPercents, from, to, stay, pay, discountType, daysToArrival} = this.props.discount;

    return (
      <div className='banner-attention-container'>
        <span className='banner-attention'>
          {stay && pay && (
            <span className='percents-discount'>
              {stay}={pay} <I18n t='SONDERANGEBOT!' />
            </span>
          )}
          {/*{daysToArrival && (discountType === this.firstMoment || discountType === this.lastMoment) && (*/}
          {/*  <span className='percents-discount'>*/}
          {/*    <I18n t='Bis' /> {daysToArrival} <I18n t='Tage vor Reisebeginn - ' />*/}
          {/*  </span>*/}
          {/*)}*/}
          {discountPercents && (
            <span className='percents-discount'>
              -{discountPercents}% {''}
              {discountType === this.firstMoment ? <I18n class='discount-type' t='FRÜHBUCHER-RABATT!' /> : <I18n class='discount-type' t={`discount.General discount`} />}
            </span>
          )}

          <span className='banner-attention-period'>
            <I18n t='discount.period' />: <I18n t={`${moment(from, DATE_FORMAT).format('DD.MM')} - ${moment(to, DATE_FORMAT).format('DD.MM.YY')}`} />
          </span>
        </span>
      </div>
    );
  }
}
