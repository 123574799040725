import React, {Component, Fragment} from 'react';

import {I18n} from '../../helpers/i18n/I18n';
import {InputTxt} from '../../helpers/inputTxt/InputTxt';
import {InputPopover} from '../../helpers/inputTxt/InputPopover';
import {Price} from '../../helpers/price/Price';
import {changeTransfer} from '../../../utils/Fetcher';

export class AddressFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: false,
      phone: '',
      buildingNumber: '',
      street: '',
      postcode: '',
      city: '',
      transferIndex: '',
      errors: {},
    };
  }

  onChange = (propName, newValue) => {
    if (this.props.onChange) {
      this.props.onChange(propName, newValue);
    } else {
      this.setState({[propName]: newValue, errors: {...this.state.errors, [propName]: false}});
    }
  };

  checkInputs = () => {
    const {city, street, buildingNumber, phone, transferIndex, requestError, wrongIndex, fetching} = this.state;
    const data = {transferIndex, city, street, buildingNumber, phone};
    const errors = {};
    Object.keys(data).forEach(key => {
      if (!data[key] || (key === 'transferIndex' && transferIndex.length < 5)) {
        errors[key] = true;
      }
    });
    if (Object.keys(errors).length || requestError || wrongIndex || fetching) {
      this.setState({errors});
      return false;
    }
    return true;
  };

  returnNewIndexFrom = () => {
    const {transferIndex, city, street, buildingNumber, phone} = this.state;
    return {transferIndex, city, street, buildingNumber, phone};
  };

  reculcTransferIndex = val => {
    if (!this.state.fetching) {
      const newState = {fetching: true, requestError: false, newTransfer: null};
      changeTransfer(this.props.searchResultId, val).then(r => {
        const newState = {fetching: false};
        if (r.isError) {
          newState.requestError = true;
        } else {
          newState.newTransfer = r.data;
          this.props.updateRoomInfo(r.data);
        }
        this.setState(newState);
      });
      this.setState(newState);
    }
  };

  onSearch = (name, val) => {
    const newState = {transferIndex: val, errors: {...this.state.errors, transferIndex: false}};
    let exists = true;
    if (val.length >= 2) {
      exists = (this.props.transferOptions || []).some(index => index.toString() === `${val[0]}${val[1]}`);
    }
    if (val.length < 5 || !exists) {
      newState.newTransfer = null;
    }
    newState.wrongIndex = val ? !exists : false;
    this.setState(newState);
    if (exists && val.length >= 5 && val !== this.state.transferIndex) {
      this.reculcTransferIndex(val);
    }
  };

  render() {
    const {postcode, city, street, email, phone, buildingNumber, inputError, prevRoomPrice} = this.props;
    const {errors, wrongIndex, requestError, newTransfer} = this.state;
    const looksAtOwnState = this.props.transferIndex;
    return (
      <Fragment>
        <div className='grid-form address-fields'>
          <I18n class='input-title' t='PLZ, Ort' />
          <div className='city-inputs'>
            <div className='input-container sp-btw'>
              <InputTxt
                placeholder='PLZ'
                onChange={looksAtOwnState ? this.onSearch : this.onChange}
                wrappersClass='inp-sm'
                onlyNumbers={true}
                maxLength={5}
                name={looksAtOwnState ? 'transferIndex' : 'postcode'}
                inputError={looksAtOwnState ? wrongIndex || errors.transferIndex : inputError.postcode}
                value={looksAtOwnState ? this.state.transferIndex : postcode}
              />
              <InputTxt
                onlyLetters
                placeholder='Ort'
                onChange={this.onChange}
                wrappersClass='inp-ex-md'
                name='city'
                inputError={looksAtOwnState ? errors.city : inputError.city}
                value={looksAtOwnState ? this.state.city : city}
              />
            </div>
          </div>
          <I18n class='input-title' t='Straße, Hausnummer' />
          <div>
            <div className='input-container sp-btw'>
              <InputTxt
                onlyLetters
                placeholder='Straße'
                wrappersClass='inp-ex-md'
                onChange={this.onChange}
                name='street'
                inputError={looksAtOwnState ? errors.street : inputError.street}
                value={looksAtOwnState ? this.state.street : street}
              />
              <InputPopover
                popoverContent={this.props.contentStore.returnContent('checkout_street_tooltip', true)}
                popoverTitle='Title example'
                overlayClassName='payments md'
                placeholder='Hausnummer'
                wrappersClass='inp-sm'
                onChange={this.onChange}
                name='buildingNumber'
                inputError={looksAtOwnState ? errors.buildingNumber : inputError.buildingNumber}
                value={looksAtOwnState ? this.state.buildingNumber : buildingNumber}
              />
            </div>
          </div>
          <I18n class='input-title' t='E-Mail' />
          <div>
            <div className='input-container'>
              <InputPopover
                popoverContent={this.props.contentStore.returnContent('checkout_email_tooltip', true)}
                popoverTitle='Title example'
                overlayClassName='payments md'
                placeholder='E-mail'
                wrappersClass='inp-lg'
                inputError={inputError?.email}
                onChange={this.onChange}
                name='email'
                focusOnStart
                value={email}
              />
            </div>
          </div>
          <div className='title-wrapper'>
            <I18n class='input-title' t='Telefon ' />
            <I18n class='input-title' t='(bevorzugt mobil)' />
          </div>
          <div>
            <div className='input-container'>
              <InputTxt
                placeholder='Telefon (bevorzugt mobil)'
                onChange={this.onChange}
                wrappersClass='inp-lg'
                name='phone'
                onlyNumbers={true}
                maxLength={15}
                inputError={looksAtOwnState ? errors.phone : inputError.phone}
                value={looksAtOwnState ? this.state.phone : phone}
              />
            </div>
            <p className='extra-sm-txt'>
              <I18n t='bei Rückfragen benötigt' />
            </p>
          </div>
        </div>
        {this.state.transferIndex.length >= 5 && newTransfer && (
          <div className='changed-index-msg'>
            <I18n t='Die Änderung der Abholadresse hat Auswirkungen auf den Preis.' />
            <div>
              <I18n t='Ihr Gesamtpreis hat sich von' /> <Price className='price-block' price={prevRoomPrice} /> <I18n t='auf' /> <Price className='price-block' price={newTransfer.price} />{' '}
              <I18n t='geändert.' />
            </div>
          </div>
        )}
        {this.state.transferIndex && (requestError || wrongIndex) && (
          <div className='changed-index-msg err'>
            <I18n t='Diese Postleitzahl existiert nicht. Bitte korrigieren Sie Ihre Angaben.' />
          </div>
        )}
      </Fragment>
    );
  }
}
