import {withRouter} from 'react-router';
import React, {Component} from 'react';
import {isMobile} from '../../../utils/Utils';
import {hideWidget, showWidget} from '../../../utils/Widget';

@withRouter
class ScrollToTop extends Component {
  componentDidMount() {
    if (isMobile() && this.props.location.pathname !== '/') {
      //check current route if isn't home page, hide widget
      hideWidget();
    } else {
      //check current route if payment page, hide widget
      if (/\/payment/.test(this.props.location.pathname)) {
        hideWidget();
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname && this.props.history.action !== 'REPLACE') {
      window.scrollTo(0, 0);
    }

    if (isMobile()) {
      //check current route if is home page, show widget
      if (this.props.location.pathname === '/') {
        showWidget();
      } else {
        hideWidget();
      }
    } else {
      //check current route if isn't payment page, show widget
      if (!/\/payment/.test(this.props.location.pathname)) {
        showWidget();
      } else {
        hideWidget();
      }
    }
  }

  render() {
    return this.props.children;
  }
}

export default ScrollToTop;
