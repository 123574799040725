import React, {Component} from 'react';
import Modal from 'antd/lib/modal';
import {ReactComponent as Close} from '../../../images/icons/cancel.svg';

const mapPointer = ({name, address, stars}) => {
  const count = [...Array(Number(stars || 0))];

  const st =
    count.length > 0
      ? `<div class="stars-block" itemProp="starRating" itemScope itemType="http://schema.org/Rating">
    <meta itemProp="ratingValue" content=${count.length}/>
    ${count.map((elem, i) => '<i class="custom-icon-star"></i>').join('')}
  </div>`
      : '';
  const html = `<div class="modal-map-cursor">
  <div>
    <p>${name}</p>
    <p>${address}</p>
    ${st} 
  </div>
</div>`;

  //console.log(html);
  return html;
};

class Map extends Component {
  componentDidMount() {
    const params = this.props.params;
    setTimeout(() => {
      const map = new window.google.maps.Map(document.getElementById('map'), {
        center: {lat: Number(params.lat), lng: Number(params.lng)},
        zoom: 17,
      });

      const marker = new window.google.maps.Marker({
        position: {lat: Number(params.lat), lng: Number(params.lng)},
        map: map,
      });

      const infowindow = new window.google.maps.InfoWindow({
        content: mapPointer(params),
      });

      window.google.maps.event.addListener(marker, 'click', function () {
        infowindow.open(map, marker);
      });
    }, 0);
  }

  render() {
    const {modalOpen, closeModal} = this.props;
    return (
      <div>
        <Modal wrapClassName='vertical-center-modal' className='custom-modal txt-box-modal' closeIcon={<Close />} visible={modalOpen} onOk={closeModal} onCancel={closeModal} footer={null}>
          <div id='map' style={{width: '100%', height: '600px'}} className='modal-body' />
        </Modal>
      </div>
    );
  }
}

export {Map};
