import React, {Component} from 'react';

export class Preloader extends Component {
  render() {
    const {showSpinner = true} = this.props;
    return (
      <div id='app-preloader' className='preloader-bg'>
        {showSpinner && <div className='app-preloader'>Loading...</div>}
      </div>
    );
  }
}
