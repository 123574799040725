import React, {Component} from 'react';
import {Route, Router} from 'react-router';
import {createBrowserHistory} from 'history';
import {syncHistoryWithStore} from 'mobx-react-router';
import {Provider} from 'mobx-react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'react-image-lightbox/style.css';

import './App.scss';
import ModalContainer from './components/modals/ModalContainer';
import {Home} from './components/mainPages/home/Home';
import {Header} from './components/helpers/header/Header';
import {Footer} from './components/helpers/footer/Footer';
import HotelSuggestion, {FILTER_TYPE} from './components/mainPages/hotelSuggestion/HotelSuggestion';
import {CancelReservation} from './components/mainPages/cancelReservation/CancelReservation';
import {InfoPage} from './components/mainPages/infoPage/InfoPage';
import HotelRooms from './components/mainPages/hotelRooms/HotelRooms';
import ScrollToTop from './components/helpers/scrollToTop/ScrollToTop';
import {OrderSuccess} from './components/mainPages/payment/OrderSuccess';
import {LOW_PRICE, RECOMMENDATION, STATIC_PAGE_URL} from './config/const';
import {OrderFailed} from './components/mainPages/payment/OrderFailed';
import {PromoCodeBlock} from './components/mainPages/promoCodeBlock/PromoCodeBlock';
import {Logger} from './components/mainPages/logger/Logger';
import PaymentWrapper from './components/mainPages/payment/PaymentWrapper';
import HotelOffer from './components/mainPages/hotelRooms/HotelOffer';

const history = createBrowserHistory();
history.listen(location => {
  if (window.ga) {
    const tracker = window.ga.getAll()[0];
    if (tracker) {
      tracker.set('page', location.pathname + location.search);
      tracker.send('pageview');
    }
  }
});

class App extends Component {
  render() {
    return (
      <Provider {...this.props.stores}>
        <Router history={syncHistoryWithStore(history, this.props.stores.routing)}>
          <ScrollToTop>
            {/*<Route path='/:country?/:region?/:hotelName?/:hotelId?' component={PromoCodeBlock}/>*/}
            <Route path='/:hotelName/payment/:searchResultId' component={PromoCodeBlock} />
            <Header />
            <ModalContainer />
            <main className='main-content'>
              <Route exact path='/' component={Home} />
              <Route exact path='/region/:name' component={() => <Home showTopTab={false} savedSearch={true} />} />
              <Route exact path='/search-results' component={() => <HotelSuggestion hideElevatorInput showTopImg={false} showFlexGallery={true} />} />
              <Route exact path='/recommendations' component={() => <HotelSuggestion hideElevatorInput headerKey='recomendation_headline' isFeatured={true} showTopImg={false} />} />
              <Route
                exact
                path='/sonderangebote'
                component={() => <HotelSuggestion hideElevatorInput hasDiscount={true} headerKey='sonderangebote_headline' className='no-bg' showTopImg={false} showFlexGallery={false} />}
              />
              <Route
                exact
                path={`/sonderangebote/${FILTER_TYPE.firstMoment}`}
                component={() => (
                  <HotelSuggestion
                    filter={FILTER_TYPE.firstMoment}
                    hideElevatorInput
                    hasDiscount={true}
                    headerKey='sonderangebote_headline'
                    className='no-bg'
                    showTopImg={false}
                    showFlexGallery={false}
                  />
                )}
              />
              <Route
                exact
                path={`/sonderangebote/${FILTER_TYPE.general}`}
                component={() => (
                  <HotelSuggestion filter={FILTER_TYPE.general} hideElevatorInput hasDiscount={true} headerKey='sonderangebote_headline' className='no-bg' showTopImg={false} showFlexGallery={false} />
                )}
              />
              <Route
                exact
                path={`/sonderangebote/${FILTER_TYPE.lastMoment}`}
                component={() => (
                  <HotelSuggestion
                    filter={FILTER_TYPE.lastMoment}
                    hideElevatorInput
                    hasDiscount={true}
                    headerKey='sonderangebote_headline'
                    className='no-bg'
                    showTopImg={false}
                    showFlexGallery={false}
                  />
                )}
              />
              {/*<Route exact path="/hotels-list" component={() => <HotelSuggestion className="no-bg" showTopImg={false} showFooterTxt={false} showFlexGallery={true}/>}/>*/}
              <Route exact path='/:country/:region/:hotelName/:hotelId/:searchRequestId?' component={HotelRooms} />
              <Route exact path='/:country/:region/:hotelName/:hotelId/offer/:searchRequestId?' component={HotelOffer} />
              <Route exact path='/:hotelName/payment/:searchResultId' component={PaymentWrapper} />
              <Route exact path='/order-success' component={OrderSuccess} />
              <Route exact path='/order-failed' component={OrderFailed} />
              <Route exact path='/booking/cancel' component={CancelReservation} />
              <Route exact path={`${STATIC_PAGE_URL}/:type`} component={InfoPage} />
              <Logger />
            </main>
            <Footer />
          </ScrollToTop>
        </Router>
      </Provider>
    );
  }
}

export default App;
