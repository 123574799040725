import {action, observable} from 'mobx';

class SearchFiltersStore {
  @observable selectedFilters = [];

  @action
  resetFilters = callback => {
    this.selectedFilters = [];
    callback && callback();
  };
  @action
  selectFilter = (ev, value, callback) => {
    if (this.selectedFilters.includes(value)) {
      this.selectedFilters = this.selectedFilters.filter(item => item !== value);
    } else {
      this.selectedFilters.push(value);
    }
    callback && callback(undefined, this.selectedFilters);
  };
}

export default SearchFiltersStore;
