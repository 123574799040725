import React from 'react';
import {I18n} from '../i18n/I18n';
import {LOW_PRICE, RECOMMENDATION} from '../../../config/const';
import {AttentionBanner} from './AttentionBanner';

export const OfferCard = ({discount = {}, featured}) => {
  if (featured) {
    return (
      <span className='banner-profit'>
        <i className='custom-icon-finger-up' />
        <I18n t='Empfohlen' />
      </span>
    );
  } else if (discount) {
    return discount.discountType ? <AttentionBanner discount={discount} /> : null;
  } else {
    return null;
  }
};
