import React, {Component, Fragment} from 'react';
import {InputsRow} from './InputsRow';
import {I18n} from '../../helpers/i18n/I18n';
import {doSmoothScroll} from '../../../utils/Utils';
import moment from 'moment';

export class GuestInfoInputs extends Component {
  state = {
    adults: this.createPersonState(this.props.adults || 0, true),
    children: this.createPersonState(this.props.children || 0, false),
    childrenErrors: {},
    adultsErrors: {},
    firstAdultChanged: false, // flag to detect what value has to be shown (own or from props)
  };

  createPersonState(personsCount, isAdult) {
    const {isNewPage} = this.props;
    personsCount = Array.isArray(personsCount) ? personsCount.length : Number(personsCount);
    return [...Array(personsCount)].map(elem => ({
      adult: isAdult,
      firstName: '',
      lastName: '',
      birthDay: isNewPage ? '02' : '',
      birthMonth: isNewPage ? '02' : '',
      birthYear: isNewPage ? '1900' : '',
      email: 'test@gmail.com',
      phone: '375443555510',
      salutation: 'guest',
      title: '',
    }));
  }

  returnGuestsInfo = () => {
    const {lastName, firstName, birthDay, gender, birthMonth, birthYear} = this.props;
    const adultsCopy = this.state.adults.map((person, i) => {
      if (i === 0 && !this.state.firstAdultChanged) {
        //The fist person in adults arr, has the same input data like in the personal information form,return it if the first person wasn't changed else if return own state
        return {
          ...person,
          firstName: firstName,
          lastName: lastName,
          birthDay: birthDay,
          birthMonth: birthMonth,
          birthYear: birthYear,
          title: gender,
          //todo other information
        };
      }
      return person;
    });

    let childrenErrors = {};
    let adultsErrors = {};

    adultsCopy.forEach((guest, index) => {
      if (index === 0 && guest.adult && !this.state.firstAdultChanged) {
        //ignore first adult if it has the same values like in main top form
        return false;
      }
      Object.keys(guest).forEach(inputKey => {
        if (!guest[inputKey]) {
          adultsErrors[inputKey + index] = true;
        } else if (inputKey === 'birthYear' && guest[inputKey].length < 4) {
          adultsErrors[inputKey + index] = true;
        }
      });
    });

    this.state.children.forEach((guest, index) => {
      Object.keys(guest).forEach(inputKey => {
        if (!guest[inputKey] && inputKey !== 'adult') {
          childrenErrors[inputKey + index] = true;
        } else if (inputKey === 'birthYear' && guest[inputKey].length < 4) {
          childrenErrors[inputKey + index] = true;
        }
      });
    });

    if (Object.keys(childrenErrors).length || Object.keys(adultsErrors).length) {
      this.setState({childrenErrors, adultsErrors});
      return false;
    } else {
      return adultsCopy.concat(this.state.children).map(person => ({
        ...person,
        birthday: moment(`${person.birthYear}-${person.birthMonth}-${person.birthDay}`, 'YYYY-MM-DD').format('YYYY-MM-DD'),
      }));
    }
  };

  onChange = (elemNumber, objName, propName, newValue) => {
    const stateCopy = this.state[objName];
    const newState = {};
    const errorsObj = objName === 'adults' ? 'adultsErrors' : 'childrenErrors';
    if (objName === 'adults' && elemNumber === 0 && !this.state.firstAdultChanged) {
      //save old values which came from props into own state.
      stateCopy[0].firstName = this.props.firstName;
      stateCopy[0].lastName = this.props.lastName;
      stateCopy[0].birthDay = this.props.birthDay;
      stateCopy[0].birthMonth = this.props.birthMonth;
      stateCopy[0].birthYear = this.props.birthYear;
      stateCopy[0].title = this.props.gender;
      newState.firstAdultChanged = true;
    }
    if (this.state[errorsObj][propName + elemNumber]) {
      newState[errorsObj] = {...this.state[errorsObj], [propName + elemNumber]: false};
    }
    stateCopy[elemNumber][propName] = newValue;
    this.setState(newState);
  };

  changeRadio = (elemNumber, objName, ev) => {
    this.onChange(elemNumber, objName, ev.target.name, ev.target.value);
  };

  render() {
    const {children, adults, adultsErrors, childrenErrors, firstAdultChanged} = this.state;
    const {firstName, lastName, birthDay, birthMonth, birthYear, gender, isNewPage} = this.props;
    return (
      <article className='members-count border-form' id='guests-info'>
        <h3 className='form-header'>
          <I18n t='Reisende Personen' />
        </h3>
        {adults.map((person, i) => {
          const showPropState = i === 0 && !firstAdultChanged; // flag to detect what value has to be shown (own or from props)
          return (
            <InputsRow
              personType='adults'
              header='Erwachsenen'
              onChange={this.onChange}
              changeRadio={this.changeRadio}
              firstName={showPropState ? firstName : person.firstName}
              lastName={showPropState ? lastName : person.lastName}
              gender={showPropState ? gender : person.title}
              birthDay={showPropState ? birthDay : person.birthDay}
              birthMonth={showPropState ? birthMonth : person.birthMonth}
              birthYear={showPropState ? birthYear : person.birthYear}
              errors={adultsErrors}
              i={i}
              key={i}
              isNewPage={isNewPage}
            />
          );
        })}
        {children.map((person, i) => (
          <InputsRow
            personType='children'
            header='Kinder'
            onChange={this.onChange}
            changeRadio={this.changeRadio}
            firstName={person.firstName}
            gender={person.title}
            lastName={person.lastName}
            birthYear={person.birthYear}
            birthMonth={person.birthMonth}
            birthDay={person.birthDay}
            errors={childrenErrors}
            i={i}
            key={i}
            isNewPage={isNewPage}
          />
        ))}
      </article>
    );
  }
}
