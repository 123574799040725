import React, {PureComponent} from 'react';
import Select from 'antd/lib/select';
import {I18n} from '../i18n/I18n';
import arrow from '../../../images/icons/download.png';

const {Option} = Select;

export class SelectList extends PureComponent {
  state = {
    isActive: false,
  };

  onChange = newValue => {
    const {selectListName, onChange, resetSearch} = this.props;
    if (resetSearch) {
      !newValue.length && resetSearch();
    }
    onChange && onChange(newValue, selectListName);
  };

  renderSimple = (options = this.props.options) => {
    return options.map((option, index) => (
      <Option key={index} value={option.value}>
        <I18n t={option.label} />
      </Option>
    ));
  };

  togglePlaceholder = () => {
    this.setState({isActive: !this.state.isActive});
  };

  render() {
    const {
      wrapperClass,
      dropdownClassName,
      loading,
      disabled,
      onSelect,
      onDeselect,
      placeholder,
      onSearch,
      options,
      allowClear = false,
      defaultValue,
      optionLabelProp,
      itemsRender = this.renderSimple,
      selectTitle,
      className,
      mode = 'default',
      selectedItems,
      dropdownRender,
      maxTagCount,
      maxTagPlaceholder,
      onDropdownVisibleChange,
      dropdownStyle,
    } = this.props;

    return (
      <div className={`default-select-list ${wrapperClass || ''}`}>
        {selectTitle && <I18n class='custom-select-header' t={selectTitle} />}
        <Select
          maxTagCount={maxTagCount}
          maxTagPlaceholder={maxTagPlaceholder}
          dropdownStyle={dropdownStyle}
          onDropdownVisibleChange={onDropdownVisibleChange}
          className={`custom-select ${className || ''}`}
          placeholder={!this.state.isActive ? placeholder : ''}
          allowClear={allowClear}
          dropdownClassName={dropdownClassName}
          defaultValue={defaultValue}
          value={selectedItems}
          mode={mode}
          onSelect={onSelect}
          disabled={disabled}
          onDeselect={onDeselect}
          onFocus={this.togglePlaceholder}
          onBlur={this.togglePlaceholder}
          optionLabelProp={optionLabelProp}
          onSearch={onSearch}
          onChange={this.onChange}
          dropdownRender={dropdownRender}
          loading={loading}
          showArrow={true}
          suffixIcon={<img src={arrow} />}
        >
          {itemsRender(options, this.onChange)}
        </Select>
      </div>
    );
  }
}
