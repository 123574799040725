import React, {Component} from 'react';
import {I18n} from '../../helpers/i18n/I18n';

const MealDescription = ({type, select, from, to, addSeparator}) => (
  <span>
    <I18n class='meal-type' t={type} /> {from && to && `(ab ${from} bis ${to} Uhr)`}
    {/*<I18n class='meal-txt' t={`common${select === 1 ? '.buffet' : '.menu'}`} />*/} {addSeparator && '/ '}
  </span>
);

export class HotelFood extends Component {
  render() {
    const {breakfast, lunch, dinner} = this.props;
    return (
      <span>
        {!!breakfast.select && <MealDescription type='common.breakfast' select={breakfast.select} from={breakfast.from} to={breakfast.to} addSeparator={!!lunch.select || !!dinner.select} />}
        {!!lunch.select && <MealDescription type='common.lunch' select={lunch.select} from={lunch.from} to={lunch.to} addSeparator={!!dinner.select} />}
        {!!dinner.select && <MealDescription type='common.dinner' select={dinner.select} from={dinner.from} to={dinner.to} />}
      </span>
    );
  }
}
