import {LoadingOutlined, ExclamationCircleOutlined, QuestionCircleOutlined} from '@ant-design/icons';
import {isMobile, isTablet, isMobileOnly} from 'react-device-detect';
import React, {Component, Fragment} from 'react';
import ReactHtmlParser from 'react-html-parser';
import {inject, observer} from 'mobx-react';
import Collapse from 'antd/lib/collapse';
import Popover from 'antd/lib/popover';
import {Link} from 'react-router-dom';
import Radio from 'antd/lib/radio';
import Input from 'antd/lib/input';
import Affix from 'antd/lib/affix';
import classNames from 'classnames';
import moment from 'moment';

import {ReactComponent as ZahlungIcon} from '../../../images/payment/Rechnung.svg';
import {ReactComponent as Ergo} from '../../../images/icons/checkout_Ergo.svg';
import {ReactComponent as SepaIcon} from '../../../images/payment/sepa.svg';
import {ReactComponent as CodeOk} from '../../../images/icons/tick.svg';
import {ReactComponent as CodeErr} from '../../../images/icons/quit.svg';
import {ReactComponent as PresentIcon} from '../../../images/icons/present.svg';
import {ReactComponent as FoodIcon} from '../../../images/icons/food.svg';
import {ReactComponent as MoonIcon} from '../../../images/icons/moon-fill.svg';
import {ReactComponent as CalendarIcon} from '../../../images/icons/calendar-event-fill.svg';
import {ReactComponent as IconInfo} from '../../../images/icons/iconInfo-blue.svg';
import {ReactComponent as PersonIcon} from '../../../images/icons/user-fill.svg';

import {concatDateStrings, doSmoothScroll, isIncludedInStayPeriod, removeMetaData, returnObligatoryArr, returnOptionsArray, returnPicSize, setMetaData, isDesktop} from '../../../utils/Utils';
import {changeTransfer, checkPromoCode, confirmPayment, returnPaymentRoom, setupIntent} from '../../../utils/Fetcher';
import {returnPaymentAddress, SelectedHotelOption, TransferOption} from '../../../utils/Models';
import {HotelAddress, RENDER_TYPES} from '../../helpers/hotelAddress/HotelAddress';
import {TaxIncluded} from '../../helpers/taxIncluded/taxIncluded';
import {InputPopover} from '../../helpers/inputTxt/InputPopover';
import {MEALS_TYPES} from '../../helpers/searchForm/SearchForm';
import {SelectList} from '../../helpers/selectList/SelectList';
import {HotelName} from '../../helpers/hotelName/HotelName';
import {Preloader} from '../../helpers/preloader/Preloader';
import {InputTxt} from '../../helpers/inputTxt/InputTxt';
import {CheckBox} from '../../helpers/checkBox/CheckBox';
import {TaxBill} from '../../modals/taxBill/TaxBill';
import {translate} from '../../../utils/Translater';
import StripeIbanFields from './StripeIbanFields';
import StripeCardFields from './StripeCardFields';
import {GuestInfoInputs} from './GuestInfoInputs';
import {DATE_FORMAT} from '../../../config/const';
import {OptionsViewer} from './OptionsViewer';
import {AddressFields} from './AddressFields';
import {I18n} from '../../helpers/i18n/I18n';
import {BirthInputs} from './BirthInputs';
import {GuestsCount} from './GuestsCount';
import TabsNavigation from './TabsNavigation';
import {ReviewsWidget} from './ReviewsWidget';
import RoomFees from './RoomFees';
import {ReactComponent as Triangle} from '../../../images/icons/triangle.svg';
import {RoomNotice} from '../../helpers/roomNotice/RoomNotice';
import {AddedInfo} from '../../helpers/addedInfo/AddedInfo';
import {ProsList} from '../../helpers/prosList/ProsList';

const {TextArea} = Input;
const {Panel} = Collapse;

const options = [
  {value: 1, label: 'Deutschland'},
  {value: 2, label: 'Österreich '},
  {value: 3, label: 'Schweiz'},
];

const titleOptions = [
  {value: 'Dr.', label: 'Dr.'},
  {value: 'Dr. Dr.', label: 'Dr. Dr.'},
  {value: 'Prof.', label: 'Prof.'},
  {value: 'Prof. Dr.', label: 'Prof. Dr.'},
];

export const prosOptions = [
  {title: 'Mit Bestpreis Garantie', isBold: false},
  {title: '100% risikofrei: 48 Stunden Bedenkzeit und kostenfrei stornierbar', isBold: false},
  {title: 'Deutscher Reiseveranstalter', isBold: false},
];

const paymentResultDelay = 2000;

const PAYMENT_TYPES = {
  CARD: 'card',
  BILL: 'bill',
  SEPA: 'sepa_debit',
};
const settings = {
  arrows: false,
  // infinite: false,
  slidesToShow: 1,
  variableWidth: true,
};
const STRIPE_ID = process.env.REACT_APP_STRIPE;

const CollapseHeader = ({treatmentName}) => (
  <div className='collapse-header-form'>
    <I18n t='common.specifications' />
  </div>
);

@inject('popup', 'loggerStore', 'hotelRooms', 'searchStore', 'orderStore', 'contentStore')
@observer
export class Payment extends Component {
  state = {
    paymentType: PAYMENT_TYPES.CARD,
    codeActivated: null,
    discount: null,
    //paymentType: "sepa_debit",
    email: '',
    comment: '',
    title: '',
    firstName: '',
    lastName: '',
    birthDay: '',
    birthMonth: '',
    birthYear: '',
    phone: '',
    postcode: '',
    city: '',
    couponCode: '',
    country: 'Deutschland',
    gender: '',
    personTitle: '',
    street: '',
    buildingNumber: '',
    cardholderName: '',
    paymentError: '',
    bookingError: '',
    fetching: false,
    ibanAgree: false,
    promoIsFetching: false,
    useAnotherIndex: false,
    orderIsFetching: false,
    conditionsIsRead: true,
    franchiseIsActive: false,
    insuranceIsActive: false,
    insuranceIndicator: false,
    booksanaPolicyIsRead: false,
    transferCount: [],
    selectedOptions: {},
    transferAddons: {},
    inputError: {},
    policyError: {},
    orderInfo: {},
  };

  guestInputs = React.createRef();
  stripeCardForm = React.createRef();
  newIndexForm = React.createRef();
  stripeSepaForm = React.createRef();

  componentWillMount() {
    const searchResultId = this.props.match.params.searchResultId;
    this.setState({orderIsFetching: true});
    returnPaymentRoom(searchResultId).then(resp => {
      let orderInfo = {};
      let paymentType = PAYMENT_TYPES.CARD;
      let daysBeforeArrival = 0;
      let transferCount = [];
      let postcode = '';
      if (!resp.isError) {
        if (resp.data.request.transfer) {
          transferCount = resp.data.content[0].perGuestPrice.map(guest => ({
            isActive: true,
            transferPrice: guest.transferPrice,
          }));
          postcode = resp.data.content[0].transferIndex;
        }
        orderInfo = {
          room: resp.data.content[0],
          hotel: resp.data.hotel,
          request: resp.data.request,
          initialRoomCopy: resp.data.content[0],
        };
        daysBeforeArrival = this.returnDays(moment.now(), resp.data.request.arrivalDate);
        paymentType = daysBeforeArrival <= 31 ? PAYMENT_TYPES.CARD : PAYMENT_TYPES.BILL;
        setMetaData(`Checkout ${resp.data.hotel.name} | Booksana.com`);

        //console.log(resp)
        if (gtag) {
          try {
            gtag('event', 'begin_checkout', {
              // currency: 'EUR',
              // value: resp.data.content[0].price,
              items: [
                {
                  item_id: orderInfo.hotel.id,
                  item_name: orderInfo.hotel.name,
                  item_category: 'Hotel',
                  //price: Math.round(resp.data.content[0].price / resp.data.content[0].days),
                  //quantity: resp.data.content[0].days
                  price: resp.data.content[0].price,
                  quantity: 1,
                },
              ],
            });
          } catch (e) {
            console.error(e);
          }
        }
      }
      this.setState({
        orderIsFetching: false,
        orderInfo: orderInfo,
        paymentType,
        daysBeforeArrival,
        transferCount,
        postcode,
      });
    });
  }

  componentWillUnmount() {
    removeMetaData();
  }

  toggleBoolState = propName => {
    if (propName === 'useAnotherIndex' && this.state[propName]) {
      if (this.state.orderInfo.room.transferIndex !== this.state.orderInfo.initialRoomCopy.transferIndex) {
        changeTransfer(this.props.match.params.searchResultId, this.state.orderInfo.initialRoomCopy.transferIndex).then(resp => {
          if (resp.isError) {
            const msg = {
              type: 'ERROR',
              msgTxt: 'Bei der Neuberechnung des Index ist ein Fehler aufgetreten. Eine weitere Zahlung ist nicht möglich',
            };
            this.props.loggerStore.addItem(msg);
            this.setState({recalcError: true});
          } else {
            this.updateRoomInfo(resp.data);
          }
        });
      }
    }
    this.setState({[propName]: !this.state[propName]});
  };

  changeRadio = e => {
    this.onChange(e.target.name, e.target.value);
  };

  toggleInsurance = e => {
    if (e.target.value) {
      const linkA = document.createElement('a');
      linkA.href = 'https://www.reiseversicherung.de/baRuntime/start?ba=standard&agency=029955000000&confirmationtarget=info@spabooking24.com&confirmationtype=emailplaintext';
      linkA.setAttribute('target', '_blank');
      linkA.setAttribute('rel', 'noreferrer noopener');
      document.body.appendChild(linkA);
      linkA.click();
      document.body.removeChild(linkA);
    }

    this.onChange(e.target.name, e.target.value);
  };

  changeSelection = (newValue, propName) => {
    this.onChange(propName, newValue);
  };

  openRoomModal = (modalCmp, txt) => {
    this.props.popup.open(modalCmp, null, null, txt);
  };

  openObligatoryModal = (modalCmp, params) => {
    if (!params.description) {
      return;
    }

    this.props.popup.open(modalCmp, null, null, params);
  };

  updateRoomInfo = data => {
    this.setState({
      prevRoomPrice: this.state.orderInfo.room.price,
      orderInfo: {...this.state.orderInfo, room: data},
      transferCount: data.perGuestPrice.map(guest => ({isActive: true, transferPrice: guest.transferPrice})),
    });
  };

  setPaymentType = type => {
    this.setState({
      paymentType: type,
      ibanAgree: false,
      inputError: {...this.state.inputError, cardholderName: false},
      cardholderName: '',
    });
  };

  checkCode = () => {
    this.setState({promoIsFetching: true});
    checkPromoCode(this.state.couponCode).then(r => {
      const newState = {codeActivated: false, promoIsFetching: false, discount: null};
      if (!r.isError && r.data.discount) {
        newState.discount = r.data.discount;
        newState.codeActivated = true;
      }
      this.setState(newState);
    });

    // this.state.couponCode === code && this.setState({codeActivated: true})
  };

  changeTransferCount = (index, selectAll) => {
    const newOptions = [...this.state.transferCount];
    if (selectAll) {
      const isActive = newOptions.some(option => option.isActive);
      newOptions.forEach(option => {
        option.isActive = !isActive;
      });
    } else {
      const curElem = newOptions[index];
      newOptions[index] = {...curElem, isActive: !curElem.isActive};
    }
    const transferIsActive = newOptions.some(option => option.isActive);
    if (!transferIsActive) {
      Object.keys(this.state.transferAddons).forEach(key => {
        this.state.transferAddons[key].options.forEach(elem => {
          elem.isActive = false;
        });
        this.state.transferAddons[key].calcPrice();
      });
    }
    this.setState({transferCount: newOptions});
  };

  changeOptionActivity = (name, index, isTransferOption, selectAll) => {
    if (isTransferOption) {
      // if we change transfer option < we have to be sure that transfer is active, it means that at least one option is active in transfer count
      const transferIsActive = this.state.transferCount.some(guest => guest.isActive);
      if (!transferIsActive) {
        return;
      }
    }
    const newOptions = isTransferOption ? {...this.state.transferAddons} : {...this.state.selectedOptions}; //we change only first lvl of property, other class elems exist with prev variable links;
    if (selectAll) {
      const isActive = newOptions[name].options.some(option => option.isActive);
      newOptions[name].options.forEach(option => {
        option.isActive = !isActive;
      });
    } else {
      const curElem = newOptions[name].options[index];
      // newOptions[name].options[index] = {...curElem, isActive: !curElem.isActive};
      newOptions[name].options[index].isActive = !curElem.isActive;
    }
    newOptions[name].calcPrice();
    this.setState({[isTransferOption ? 'transferAddons' : 'selectedOptions']: newOptions});
  };

  onChange = (propName, newValue) => {
    const {inputError, policyError} = this.state;
    const newState = {[propName]: newValue};
    if (propName === 'cardholderName') {
      newState[propName] = newValue.toUpperCase();
    }
    if (propName === 'couponCode' && !newValue) {
      newState.codeActivated = null;
    }
    if (inputError[propName] && newValue) {
      newState.inputError = {...inputError, [propName]: false}; //remove input error state
    }
    if (policyError[propName] && newValue) {
      newState.policyError = {...policyError, [propName]: false};
    }
    this.setState(newState);
  };

  checkOwnerData = () => {
    const {
      phone,
      lastName,
      buildingNumber,
      ibanAgree,
      street,
      postcode,
      gender,
      city,
      title,
      country,
      firstName,
      email,
      birthDay,
      birthMonth,
      birthYear,
      cardholderName,
      // conditionsIsRead,
      // booksanaPolicyIsRead,
    } = this.state;
    let isCorrect = true;
    const data = {
      email: email,
      buildingNumber: buildingNumber,
      street: street,
      firstName: firstName,
      lastName: lastName,
      gender: gender,
      phone: phone,
      birthDay: birthDay,
      birthMonth: birthMonth,
      birthYear: birthYear,
      postcode: postcode,
      city: city,
      country: country,
      // conditionsIsRead: conditionsIsRead,
      // booksanaPolicyIsRead: booksanaPolicyIsRead,
      ...(this.state.paymentType !== PAYMENT_TYPES.BILL && {cardholderName: cardholderName}),
      ...(this.state.paymentType !== PAYMENT_TYPES.CARD && this.state.paymentType !== PAYMENT_TYPES.BILL && {ibanAgree: ibanAgree}),
    };
    let errors = {};
    Object.keys(data).forEach(key => {
      if (!data[key]) {
        errors[key] = true;
      } else if (key === 'birthYear' && String(data[key]).length < 4) {
        errors[key] = true;
      } else if (key === 'email' && !data[key].match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i)) {
        errors[key] = true;
      }
    });
    if (Object.keys(errors).length) {
      this.setState({inputError: errors});
      isCorrect = false;
      // if (errors.conditionsIsRead || errors.booksanaPolicyIsRead) {
      //   return isCorrect;
      // }
      errors.cardholderName || errors.ibanAgree ? doSmoothScroll('#cardHolder') : doSmoothScroll('#consumer-info');
    }
    return isCorrect;
  };

  checkPolicyPolicyIsRead = () => {
    const {conditionsIsRead, booksanaPolicyIsRead} = this.state;
    const data = {
      conditionsIsRead,
      booksanaPolicyIsRead,
    };
    let isCorrect = true;
    let errors = {};
    Object.keys(data).forEach(key => {
      if (!data[key]) {
        errors[key] = true;
      }
    });
    if (Object.keys(errors).length) {
      this.setState({policyError: errors});
      isCorrect = false;
    }
    return isCorrect;
  };

  bookRoom = () => {
    const {
      phone,
      lastName,
      couponCode,
      gender,
      codeActivated,
      street,
      postcode,
      buildingNumber,
      birthDay,
      birthMonth,
      birthYear,
      title,
      city,
      country,
      firstName,
      email,
      orderInfo,
      comment,
      selectedOptions,
      transferAddons,
      useAnotherIndex,
      transferCount,
      // insuranceIsActive, //not implemented on the backend
    } = this.state;

    const {room, request} = orderInfo;
    const guestsInfo = this.guestInputs.current.returnGuestsInfo();
    if (!this.checkOwnerData()) {
      doSmoothScroll('#consumer-info');
      return;
    } else if (!guestsInfo) {
      doSmoothScroll('#guests-info');
      return;
    } else if (!this.checkPolicyPolicyIsRead()) {
      doSmoothScroll('#policy-info', 50);
      return;
    } else if (useAnotherIndex && !this.newIndexForm.current.checkInputs()) {
      doSmoothScroll('#newIndexAddress');
      return;
    }
    let transferAddress = returnPaymentAddress(city, country, postcode, 'string', street, buildingNumber, phone);
    const transferIsActive = transferCount.some(guest => guest.isActive);
    if (useAnotherIndex) {
      const newIndexForm = this.newIndexForm.current.returnNewIndexFrom();
      transferAddress = returnPaymentAddress(newIndexForm.city, country, postcode, 'string', newIndexForm.street, newIndexForm.buildingNumber, newIndexForm.phone);
    }
    const data = {
      arrivalDate: request.arrivalDate ? concatDateStrings(request.arrivalDate).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD'),
      departureDate: request.departureDate ? concatDateStrings(request.departureDate).format('YYYY-MM-DD') : moment(new Date()).add(1, 'days').format('YYYY-MM-DD'),
      client: {
        birthday: moment(`${birthYear}-${birthMonth}-${birthDay}`, 'YYYY-MM-DD').format('YYYY-MM-DD'),
        email: email,
        firstName: firstName,
        invoiceAddress: returnPaymentAddress(city, country, postcode, 'string', street, buildingNumber),
        lastName: lastName,
        livingAddress: returnPaymentAddress(city, country, postcode, 'string', street, buildingNumber),
        phone: phone,
        salutation: title,
        title: gender,
      },
      // insuranceIsActive, //not implemented on the backend
      comment: comment,
      guests: guestsInfo,
      kindOfCatering: request.kindOfCatering,
      compulsories: returnOptionsArray(selectedOptions),
      price: 0,
      searchResultId: room.resultId,
      promoCode: codeActivated ? couponCode : null,
      ...(transferIsActive && {
        transfer: {
          count: transferCount.filter(option => option.isActive).length,
          transferAddons: returnOptionsArray(transferAddons),
          transferAddress,
          transferId: room.transferId,
        },
      }),
    };
    this.makePayment(data);
  };

  analyzePaymentResult = (resp, createdAt) => {
    if (!resp.isError) {
      if (resp.data.requiresAction === true) {
        let stripe;
        //console.log("stripeAccount", resp.data.stripeAccount, !!resp.data.stripeAccount);
        if (resp.data.stripeAccount && resp.data.stripeAccount !== 'booksana') {
          stripe = new Stripe(STRIPE_ID, {stripeAccount: resp.data.stripeAccount});
        } else {
          stripe = new Stripe(STRIPE_ID);
        }

        stripe.handleCardAction(resp.data.paymentIntentClientSecret).then(result => {
          //console.log("handleCardAction", result);

          if (result.error) {
            // Show error in payment form
            setTimeout(() => {
              let errorTxt = resp.data?.message || 'booking ServerError';
              this.setState({fetching: false, paymentError: '', bookingError: errorTxt});
            }, paymentResultDelay);
          } else {
            // The card action has been handled
            // The PaymentIntent can be confirmed again on the server
            if (result.paymentIntent.status === 'requires_confirmation') {
              confirmPayment({
                payment_type: this.state.paymentType,
                payment_intent_id: result.paymentIntent.id,
              }).then(resp => this.analyzePaymentResult(resp, createdAt));
            } else {
              setTimeout(() => {
                this.setState({fetching: false, paymentError: '', bookingError: ''});
                this.props.history.push('/order-success');
              }, paymentResultDelay);
            }
          }
        });
      } else {
        setTimeout(() => {
          this.setState({fetching: false, paymentError: '', bookingError: ''});
          const {request} = this.state.orderInfo;
          this.props.orderStore.saveOrder({
            ...resp.data.searchResult,
            payment_type: this.state.paymentType,
            arrivalDate: concatDateStrings(request.arrivalDate).format(DATE_FORMAT),
            departureDate: concatDateStrings(request.departureDate).format(DATE_FORMAT),
            createdAt: this.state.paymentType === PAYMENT_TYPES.BILL ? moment(createdAt).format(DATE_FORMAT) : moment(createdAt * 1000).format(DATE_FORMAT),
          });
          this.props.history.push('/order-success');
        }, paymentResultDelay);
      }
    } else {
      setTimeout(() => {
        let errorTxt = resp.data?.message || 'booking ServerError';
        this.setState({fetching: false, paymentError: '', bookingError: errorTxt});
      }, paymentResultDelay);
    }
  };

  returnDays = (paymentTime, arrivalTime) => {
    const from = moment(paymentTime).format(DATE_FORMAT);
    const to = concatDateStrings(arrivalTime).format(DATE_FORMAT);
    return moment(to, DATE_FORMAT).diff(moment(from, DATE_FORMAT), 'days');
  };

  makePayment = async dataFrom => {
    this.setState({fetching: true, paymentError: '', bookingError: ''});
    if (this.state.paymentType !== PAYMENT_TYPES.BILL) {
      let resp;
      let paymentMethodId;
      try {
        if (this.state.paymentType === PAYMENT_TYPES.CARD) {
          resp = await this.stripeCardForm.current.state.stripe.createPaymentMethod(this.state.paymentType, this.stripeCardForm.current.state.cardNumber, {
            billing_details: {
              name: this.state.cardholderName,
              email: this.state.email,
            },
          });
          paymentMethodId = resp.paymentMethod.id;
        } else if (this.state.paymentType === PAYMENT_TYPES.SEPA) {
          // resp = await this.stripeSepaForm.current.state.stripe.createPaymentMethod(this.state.paymentType, this.stripeSepaForm.current.state.sepa, {
          //   billing_details: {
          //     name: this.state.cardholderName,
          //     email: this.state.email,
          //   }
          // });
          //console.log(1, resp);
          await setupIntent(this.state.email).then(async data => {
            //console.log(2, data);
            resp = await this.stripeSepaForm.current.state.stripe.confirmSepaDebitSetup(data.data.clientSecret, {
              payment_method: {
                sepa_debit: this.stripeSepaForm.current.state.sepa,
                billing_details: {
                  name: this.state.cardholderName,
                  email: this.state.email,
                },
              },
            });
            paymentMethodId = resp.setupIntent.payment_method;
            //console.log(3, resp);
          });
        }
      } catch (e) {
        setTimeout(() => {
          console.log(e);
          doSmoothScroll('#paymentError');
          this.setState({fetching: false, paymentError: (resp || {error: {message: 'Error'}}).error.message});
        }, paymentResultDelay);
        return;
      }
      const {error} = resp;
      const room = this.state.orderInfo.room;
      //console.log(resp);
      if (error) {
        setTimeout(() => {
          this.setState({fetching: false, paymentError: error.message});
          doSmoothScroll('#paymentError');
        }, paymentResultDelay);
      } else {
        const createdAt = resp.paymentMethod?.created || resp.paymentSetup?.created || new Date().getTime();
        const data = {
          email: this.state.email,
          payment_type: this.state.paymentType,
          payment_method_id: paymentMethodId,
          amount: room.price,
          ...dataFrom,
        };
        confirmPayment(data).then(resp => this.analyzePaymentResult(resp, createdAt));
      }
    } else {
      const data = {
        email: this.state.email,
        payment_method_id: null,
        payment_type: this.state.paymentType,
        amount: this.state.orderInfo.room.price,
        ...dataFrom,
      };
      confirmPayment(data).then(resp => this.analyzePaymentResult(resp, moment.now()));
    }
  };

  createOptionObj(option) {
    const {request, hotel} = this.state.orderInfo;
    return option.hasOwnProperty('addonId') ? new TransferOption(option, hotel.provision || 0) : new SelectedHotelOption(option, request.departureDate, request.arrivalDate, hotel.provision || 0);
  }

  changeOptionsCount = (isPlus, option) => {
    const isTransferOption = option.hasOwnProperty('addonId');
    const newOptions = isTransferOption ? {...this.state.transferAddons} : {...this.state.selectedOptions}; //we change only first lvl of property, other class elems exist with prev variable links;
    const id = isTransferOption ? option.addonId : option.id;
    if (isPlus) {
      newOptions[id] ? newOptions[id].increaseCount() : (newOptions[id] = this.createOptionObj(option));
      this.setState({[isTransferOption ? 'transferAddons' : 'selectedOptions']: newOptions});
    } else {
      if (!newOptions[id]) {
        return;
      } else {
        newOptions[id].decreaseCount();
      }
      this.setState({[isTransferOption ? 'transferAddons' : 'selectedOptions']: newOptions});
    }
  };

  render() {
    if (!Object.keys(this.state.orderInfo).length) {
      return this.state.orderIsFetching ? (
        <Preloader />
      ) : (
        <div className='payment-page'>
          <p className='empty-order'>
            <I18n t="Order wasn't found" />
          </p>
        </div>
      );
    }

    const {room, hotel, request} = this.state.orderInfo;

    const {
      email,
      cardholderName,
      country,
      title,
      inputError,
      policyError,
      gender,
      firstName,
      lastName,
      postcode,
      city,
      street,
      buildingNumber,
      phone,
      fetching,
      birthDay,
      birthMonth,
      birthYear,
      conditionsIsRead,
      booksanaPolicyIsRead,
      ibanAgree,
      daysBeforeArrival,
      useAnotherIndex,
      insuranceIsActive,
      franchiseIsActive,
      insuranceIndicator,
      transferCount,
      recalcError,
    } = this.state;

    const {kurFeeExists, kurFeeFreeAdults, kurFeeFreeAdultsFrom, kurFeeFreeChildren, kurFeeFreeChildrenTill, kurFeePrice, kurFeeType} = hotel;
    const fees = {
      kurFeeExists,
      kurFeeFreeAdults,
      kurFeeFreeAdultsFrom,
      kurFeeFreeChildren,
      kurFeeFreeChildrenTill,
      kurFeePrice,
      kurFeeType,
    };
    let couponStateIcon = null;

    if (this.state.codeActivated === false) {
      couponStateIcon = CodeErr;
    } else if (this.state.codeActivated === true) {
      couponStateIcon = CodeOk;
    }

    const querySize = returnPicSize('checkout', window.innerWidth);
    const transferIsActive = transferCount.some(guest => guest.isActive);
    const minBillDays = 3;
    const maxBillDays = 31;
    const maxCommentLength = 500;
    const additionalOptions = hotel.compulsories.filter(elem => elem.optional && elem.regulation === 'ONCE' && isIncludedInStayPeriod(elem.start, elem.end, request));
    const transferAddons = room.transferInfo ? room.transferInfo.addons || [] : [];
    const obligatoryArray = returnObligatoryArr(hotel.compulsories || [], request);

    return (
      <section className='payment-page'>
        {isMobileOnly && (
          <div className='selected-apartments'>
            {/*<div className='center-container'>*/}
            {/*  <ReviewsWidget scriptUrl='https://www.provenexpert.com/widget/richsnippet.js?u=18zA0Vwol82ZjRmA1HGplOKB48TA1LQB&v=3' />*/}
            {/*</div>*/}

            <div className='name-container'>
              <div className='hotel-name'>
                <HotelName name={hotel.name} stars={hotel.stars || 0} />
                <HotelAddress hotel={hotel} renderType={RENDER_TYPES.FULL} />
              </div>
              <div className='hotel-image'>{(hotel.media || []).length > 0 ? <img alt='hotel-img' src={`${hotel.media[0]}${querySize}`} /> : <div className='empty-room-pic' />}</div>
            </div>
            <Popover
              placement='right'
              overlayClassName='custom-popover lg'
              trigger={isMobile || isTablet ? 'click' : 'hover'}
              content={
                <div className='hotel-options'>
                  {obligatoryArray.map((elem, i) => (
                    <div
                      key={i}
                      className='present-row'
                      onClick={this.openObligatoryModal.bind(this, TaxBill, {
                        description: elem.description,
                        name: elem.name,
                      })}
                    >
                      <PresentIcon />
                      <I18n t={elem.name} className={classNames('tooltip-title', {hoverable: !!elem.description})} />
                    </div>
                  ))}
                  {room.treatmentDescription ? ReactHtmlParser(room.treatmentDescription) : ''}
                </div>
              }
            >
              <div className='banner-profit-wrapper'>
                <span className='banner-profit spa-package'>{room.treatmentName}</span>
                <IconInfo />
              </div>
            </Popover>
            {/*<div className='hotel-details'>*/}
            {/*  <div className='banner-profit-wrapper'>*/}
            {/*    <span className='banner-profit spa-package'>{room.treatmentName}</span>*/}
            {/*  </div>*/}
            {/*  <div className='hotel-options'>*/}
            {/*    {obligatoryArray.map((elem, i) => (*/}
            {/*      <div*/}
            {/*        key={i}*/}
            {/*        className='present-row'*/}
            {/*        onClick={this.openObligatoryModal.bind(this, TaxBill, {*/}
            {/*          description: elem.description,*/}
            {/*          name: elem.name,*/}
            {/*        })}*/}
            {/*      >*/}
            {/*        <PresentIcon />*/}
            {/*        <I18n t={elem.name} className={classNames('tooltip-title', {hoverable: !!elem.description})} />*/}
            {/*      </div>*/}
            {/*    ))}*/}
            {/*    {room.treatmentDescription ? ReactHtmlParser(room.treatmentDescription.slice(0, room.treatmentDescription.indexOf('</ul>'))) : ''}*/}
            {/*  </div>*/}
            {/*  <AddedInfo info={room.treatmentDescription.slice(room.treatmentDescription.indexOf('<p>'))} />*/}
            {/*</div>*/}
            {request && (
              <div className='header-txt sm'>
                <div className='dates-block'>
                  <CalendarIcon />
                  {request.arrivalDate ? concatDateStrings(request.arrivalDate).format(DATE_FORMAT) : ''} &#8212;{' '}
                  {request.departureDate ? concatDateStrings(request.departureDate).format(DATE_FORMAT) : ''}
                </div>
                <span className='nights-count'>
                  <span className='count-num'>{room.nights}</span>
                  <I18n t='common.night' />
                </span>
              </div>
            )}
            <div className='catering'>
              <div className='label'>
                <FoodIcon />
                <I18n t='Verpflegung:' />
              </div>
              {MEALS_TYPES[room.kindOfCatering]}
            </div>
            <div className='guestCount'>
              <div className='label'>
                <PersonIcon />
                <I18n t='Für Person:' />
              </div>
              {room.guestCount}
            </div>
          </div>
        )}

        <Affix className='fixedNav'>
          <TabsNavigation isTransfer options={additionalOptions} transferIsActive={transferIsActive} isNewPage={false} />
        </Affix>

        <div className='payment-block-wrapper'>
          <section className='payment-block'>
            <div className='left'>
              <article className='border-form'>
                {isMobileOnly && <h2 className='payment-summary'>Zusammenfassung</h2>}
                <HotelName name={hotel.name} stars={hotel.stars || 0} />
                <HotelAddress hotel={hotel} renderType={RENDER_TYPES.FULL} />
                <div className='pic-wrapper'>{(hotel.media || []).length > 0 ? <img alt='hotel-img' src={`${hotel.media[0]}${querySize}`} /> : <div className='empty-room-pic' />}</div>
                {/*<Popover*/}
                {/*  placement='right'*/}
                {/*  overlayClassName='custom-popover lg'*/}
                {/*  trigger={isMobile || isTablet ? 'click' : 'hover'}*/}
                {/*  content={*/}
                {/*    <div className='hotel-options'>*/}
                {/*      {obligatoryArray.map((elem, i) => (*/}
                {/*        <div*/}
                {/*          key={i}*/}
                {/*          className='present-row'*/}
                {/*          onClick={this.openObligatoryModal.bind(this, TaxBill, {*/}
                {/*            description: elem.description,*/}
                {/*            name: elem.name,*/}
                {/*          })}*/}
                {/*        >*/}
                {/*          <PresentIcon />*/}
                {/*          <I18n t={elem.name} className={classNames('tooltip-title', {hoverable: !!elem.description})} />*/}
                {/*        </div>*/}
                {/*      ))}*/}
                {/*      {room.treatmentDescription ? ReactHtmlParser(room.treatmentDescription) : ''}*/}
                {/*    </div>*/}
                {/*  }*/}
                {/*>*/}
                {/*  <div className='banner-profit-wrapper'>*/}
                {/*    <span className='banner-profit spa-package'>{room.treatmentName}</span>*/}
                {/*    <IconInfo />*/}
                {/*  </div>*/}
                {/*</Popover>*/}
                <div className='hotel-details'>
                  <div className='banner-profit-wrapper'>
                    <span className='banner-profit spa-package'>{room.treatmentName}</span>
                  </div>
                  <div className='hotel-options'>
                    {obligatoryArray.map((elem, i) => (
                      <div
                        key={i}
                        className='present-row'
                        onClick={this.openObligatoryModal.bind(this, TaxBill, {
                          description: elem.description,
                          name: elem.name,
                        })}
                      >
                        <PresentIcon />
                        <I18n t={elem.name} className={classNames('tooltip-title', {hoverable: !!elem.description})} />
                      </div>
                    ))}
                    {room.treatmentDescription ? ReactHtmlParser(room.treatmentDescription.slice(0, room.treatmentDescription.indexOf('</ul>'))) : ''}
                  </div>
                  <AddedInfo info={room.treatmentDescription.slice(room.treatmentDescription.indexOf('<p>'))} />
                </div>

                {request && (
                  <div className='header-txt sm'>
                    <div className='dates-block'>
                      <CalendarIcon />
                      {request.arrivalDate ? concatDateStrings(request.arrivalDate).format(DATE_FORMAT) : ''} &#8212;{' '}
                      {request.departureDate ? concatDateStrings(request.departureDate).format(DATE_FORMAT) : ''}
                    </div>
                    <span className='nights-count'>
                      <MoonIcon />
                      <span className='count-num'>{room.nights}</span>
                      <I18n t='common.night' />
                    </span>
                  </div>
                )}
                <div className='catering'>
                  <FoodIcon />
                  <I18n t='Verpflegung:' /> {MEALS_TYPES[room.kindOfCatering]}
                </div>
                {/* <Collapse className='custom-collapse' expandIcon={({isActive}) => <CaretRightOutlined rotate={isActive ? -90 : 90} />}>
                  <Panel key={1} header={<CollapseHeader />}>
                    <p className='content-txt'>
                      <h3>{room.treatmentName}</h3>
                      {obligatoryArray.map((elem, i) => (
                        <div
                          key={i}
                          className='present-row'
                          onClick={this.openRoomModal.bind(this, TaxBill, {
                            description: elem.description,
                            name: elem.name,
                          })}
                        >
                          <PresentIcon />
                          <I18n t={elem.name} className='tooltip-title' />
                        </div>
                      ))}
                      <span>{room.treatmentDescription ? ReactHtmlParser(room.treatmentDescription) : 'Description is empty'}</span>
                    </p>
                  </Panel>
                </Collapse> */}
                <GuestsCount
                  minusTransferPrice={request.transfer && !transferIsActive}
                  changeOptionActivity={this.changeOptionActivity}
                  changeTransferCount={this.changeTransferCount}
                  transferCount={transferCount}
                  transferIsActive={transferIsActive}
                  transferAddons={this.state.transferAddons}
                  hotelOptions={this.state.selectedOptions}
                  promoDiscount={this.state.discount}
                  promoCode={this.state.couponCode}
                  transferExists={request.transfer}
                  room={room}
                  showOldPrice
                />
                <div className='hotel-taxes'>
                  <Popover
                    placement='right'
                    overlayClassName='custom-popover lg'
                    trigger={isMobile || isTablet ? 'click' : 'hover'}
                    content={<RoomFees vendorName={room.vendor.name} price={room.price} arrivalDate={request.arrivalDate} cancellationOptions={hotel.cancellationFees} />}
                  >
                    <div className='room-fees-popover'>
                      {translate('common.cancellation_policy')} <IconInfo />
                    </div>
                  </Popover>
                  <TaxIncluded openModal={this.openRoomModal.bind(this, TaxBill, fees)} />
                </div>
              </article>

              {isMobileOnly && (
                <>
                  {/*<div className='payment-types'>*/}
                  {/*  <SepaIcon />*/}
                  {/*  <ZahlungIcon />*/}
                  {/*  <i className='custom-icon master-card' />*/}
                  {/*  <i className='custom-icon visa' />*/}
                  {/*  <i className='custom-icon express' />*/}
                  {/*</div>*/}

                  <article className='conclusion-form border-form' id='policy-info'>
                    <p className='content-txt'>
                      {/*<span>{this.props.contentStore.returnContent("checkout_before_checkbox_text")}</span>*/}
                      <span>
                        <I18n t='Hiermit bestätige ich mein Einverständnis mit:' />
                      </span>
                    </p>
                    <div className='check-box-container'>
                      <div>
                        <CheckBox
                          className='sm hide_txt'
                          inputError={policyError.booksanaPolicyIsRead}
                          checked={booksanaPolicyIsRead}
                          onChange={this.onChange.bind(this, 'booksanaPolicyIsRead', !booksanaPolicyIsRead)}
                        />
                        <span>
                          den{' '}
                          <Link to='/information/agb' target='_blank'>
                            <I18n t='AGB' />
                          </Link>{' '}
                          und den{' '}
                          <Link to='/information/privacy' target='_blank'>
                            <I18n t='Datenschutzbestimmungen' />
                          </Link>{' '}
                          von der booksana, sowie die Kenntnisnahme der{' '}
                          <a href={room.vendor.conditionsUrl} target='_blank'>
                            <I18n t='AGB' />
                          </a>{' '}
                          und den Stornobedingungen des Reiseveranstalters {room.vendor.name}.
                          <I18n t=' Ja, ich habe die vorvertraglichen Reise-Informationen und die wichtigsten ' />
                          <a href={room.vendor.guidelinesUrl} target='_blank'>
                            <I18n t='Informationen und Rechte der EU-Pauschalreise-Richtlinie EU 2015/2032' />
                          </a>
                          <I18n t=' zur Kenntnis genommen.' />
                        </span>
                      </div>
                      {/*<div>*/}
                      {/*  <CheckBox*/}
                      {/*    className='sm hide_txt'*/}
                      {/*    inputError={policyError.conditionsIsRead}*/}
                      {/*    checked={conditionsIsRead}*/}
                      {/*    onChange={this.onChange.bind(this, 'conditionsIsRead', !conditionsIsRead)}*/}
                      {/*  />*/}
                      {/*  <span>*/}
                      {/*    <I18n t='Ja, ich habe die vorvertraglichen Reise-Informationen und die wichtigsten ' />*/}
                      {/*    <a href={room.vendor.guidelinesUrl} target='_blank'>*/}
                      {/*      <I18n t='Informationen und Rechte der EU-Pauschalreise-Richtlinie EU 2015/2032' />*/}
                      {/*    </a>*/}
                      {/*    <I18n t=' zur Kenntnis genommen.' />*/}
                      {/*  </span>*/}
                      {/*</div>*/}
                    </div>
                    {/*<p className='check-data-msg'>*/}
                    {/*  <I18n t='Bitte prüfen Sie Ihre Angaben noch einmal, bevor Sie eine verbindliche Buchungserklärung abgeben!' />*/}
                    {/*</p>*/}
                    <div className='footer-controls'>
                      {/* <a className='btn-back' onClick={this.props.history.goBack}>
                      <i className='custom-icon arrow-back' />
                      <I18n t='zurück' />
                    </a> */}
                      <button disabled={fetching || recalcError} onClick={this.bookRoom} className='custom-btn booking-btn'>
                        {fetching ? (
                          <>
                            <I18n t='Bitte warten...' />
                            <span className='booking-btn-preloader'>
                              <LoadingOutlined className='btn-spinner' />
                            </span>
                          </>
                        ) : (
                          <I18n t='Verbindlich buchen' />
                        )}
                      </button>
                    </div>
                    <div className='conclusion-pros'>
                      <ProsList list={prosOptions} />
                    </div>
                  </article>
                </>
              )}
            </div>
            <div className='right'>
              <div className='payment-info'>
                <h2 className='payment-title'>Buchung</h2>
                <ProsList list={prosOptions.filter((item, index) => index <= 1)} />
              </div>
              <article className='personal-info border-form' id='consumer-info'>
                <h3 className='form-header'>
                  <I18n t='Ihre persönlichen Daten' />
                </h3>
                {/*<h3 className='form-header sm'>*/}
                {/*  <I18n t='Reiseanmelder' />*/}
                {/*</h3>*/}
                <div className='grid-form'>
                  {!isMobileOnly && <I18n class='input-title' t='Anrede' />}
                  <div>
                    <div className='input-container flex-col'>
                      <Radio.Group className={classNames(inputError.gender ? 'error' : '', 'new-page')} name='gender' onChange={this.changeRadio} value={this.state.gender}>
                        <div className='inp-md radio-box'>
                          <Radio value='Herr' className='custom-radio sm-md gray'>
                            <I18n t='common.man' />
                          </Radio>
                        </div>
                        <div className='inp-md radio-box'>
                          <Radio value='Frau' className='custom-radio sm-md gray'>
                            <I18n t='common.woman' />
                          </Radio>
                        </div>
                      </Radio.Group>
                      {/*<SelectList*/}
                      {/*    onChange={this.changeSelection}*/}
                      {/*    selectedItems={title || undefined}*/}
                      {/*    wrapperClass={`${inputError.title ? 'error' : ''} form-select inp-md`}*/}
                      {/*    placeholder={translate('common.choose')}*/}
                      {/*    selectListName='title'*/}
                      {/*    options={titleOptions}*/}
                      {/*/>*/}
                    </div>
                  </div>
                  <I18n class='input-title' t='Vorname, Nachname' />
                  <div>
                    <div className='input-container sp-btw'>
                      <InputTxt onlyLetters placeholder='Vorname' onChange={this.onChange} wrappersClass='inp-md' name='firstName' inputError={inputError.firstName} value={firstName} />
                      <InputPopover
                        onlyLetters
                        popoverContent={this.props.contentStore.returnContent('checkout_name_tooltip', true)}
                        popoverTitle='Title example'
                        overlayClassName='payments md'
                        placeholder='Nachname'
                        wrappersClass='inp-md'
                        onChange={this.onChange}
                        inputError={inputError.lastName}
                        name='lastName'
                        value={lastName}
                      />
                    </div>
                  </div>
                  <I18n class='input-title' t='Geburtsdatum' />
                  <div>
                    <BirthInputs
                      wrapperClass='input-container birthday sp-btw'
                      onChange={this.onChange}
                      birthDay={birthDay}
                      birthMonth={birthMonth}
                      birthYear={birthYear}
                      birthDayName='birthDay'
                      birthMonthName='birthMonth'
                      birthYearName='birthYear'
                      birthDayClass='inp-sm'
                      birthMonthClass='inp-sm'
                      birthYearClass='inp-sm'
                      birthDayError={inputError.birthDay}
                      birthMonthError={inputError.birthMonth}
                      birthYearError={inputError.birthYear}
                    />
                  </div>
                  <I18n class='input-title' t='Land' />
                  <div>
                    <div className='input-container'>
                      <SelectList selectListName='country' selectedItems={country} wrapperClass='form-select inp-lg' onChange={this.changeSelection} options={options} />
                    </div>
                  </div>
                  <AddressFields
                    inputError={inputError}
                    contentStore={this.props.contentStore}
                    postcode={postcode}
                    city={city}
                    street={street}
                    email={email}
                    buildingNumber={buildingNumber}
                    phone={phone}
                    onChange={this.onChange}
                  />
                </div>
              </article>
              <GuestInfoInputs
                firstName={firstName}
                lastName={lastName}
                birthDay={birthDay}
                gender={gender}
                birthMonth={birthMonth}
                birthYear={birthYear}
                ref={this.guestInputs}
                adults={request.numberAdults}
                children={request.children}
                isNewPage={false}
              />
              <OptionsViewer
                options={additionalOptions}
                guestsCount={request.numberAdults + request.children.length}
                changeOptionsCount={this.changeOptionsCount}
                selectedOptions={this.state.selectedOptions}
              />
              <article className='border-form'>
                <h3 className='form-header'>
                  <I18n t='Hinweise an das Hotel' />
                </h3>
                <div>
                  <div className='input-container relative'>
                    <div className='txt-area-state'>
                      {(this.state.comment || '').length} / {maxCommentLength}
                    </div>
                    <TextArea
                      autoSize
                      name='comment'
                      placeholder='Ihre Nachricht an das Hotel:'
                      value={this.state.comment}
                      maxLength={maxCommentLength}
                      onChange={({target}) => this.onChange(target.name, target.value)}
                    />
                  </div>
                </div>
              </article>
              {request.transfer && (
                <article id='newIndexAddress' className={classNames('receiving-address border-form', {mask: !transferIsActive})}>
                  <h3 className='form-header'>
                    <I18n t='Transfer-Abholadresse' />
                  </h3>
                  <div className='input-container address-checkboxes'>
                    <div className='radio-box'>
                      <Radio onChange={this.toggleBoolState.bind(this, 'useAnotherIndex')} checked={!useAnotherIndex} className='custom-radio sm-md gray'>
                        <I18n t='Anschrift des Reiseanmelders' />
                      </Radio>
                    </div>
                    <div className='radio-box'>
                      <Radio onChange={this.toggleBoolState.bind(this, 'useAnotherIndex')} checked={useAnotherIndex} className='custom-radio sm-md gray'>
                        <I18n t='Andere Abholadresse' />
                      </Radio>
                    </div>
                  </div>
                  {useAnotherIndex && (
                    <AddressFields
                      updateRoomInfo={this.updateRoomInfo}
                      prevRoomPrice={this.state.prevRoomPrice}
                      searchResultId={this.props.match.params.searchResultId}
                      transferOptions={this.props.searchStore.transferIndexes}
                      transferIndex={room.transferIndex}
                      ref={this.newIndexForm}
                      contentStore={this.props.contentStore}
                      postcode={postcode}
                      city={city}
                      street={street}
                      email={email}
                      buildingNumber={buildingNumber}
                      phone={phone}
                    />
                  )}
                </article>
              )}
              <OptionsViewer
                isTransfer
                transferIsActive={transferIsActive}
                guestsCount={request.numberAdults + request.children.length}
                options={transferAddons}
                changeOptionsCount={this.changeOptionsCount}
                selectedOptions={this.state.transferAddons}
              />
              <article className='border-form with-ergo' id='insurance'>
                <div className='with-ergo-head'>
                  <h3 className='form-header'>
                    <I18n t='Reiseschutz' />
                  </h3>
                  <Ergo />
                </div>

                <Radio.Group name='insuranceIsActive' onChange={this.toggleInsurance} value={insuranceIsActive}>
                  <div className='inp-ex-sm radio-box'>
                    <Radio value={false} className='custom-radio sm-md gray'>
                      <I18n t='Nein, ich wünsche keine Versicherung' className='lbl' />
                    </Radio>
                  </div>
                  <div className='inp-ex-sm radio-box no-b'>
                    <Radio value={true} className='custom-radio sm-md gray'>
                      <I18n t='Ja, ich möchte abgesichert werden (Reiserücktritts-Versicherung, RundumSorglos-Schutz oder Jahresversicherung)' className='lbl' />
                    </Radio>
                  </div>
                </Radio.Group>

                {/* <div>
                  <div>
                    <I18n class='bold-inscription' t='Für Ihre Reise ist es wichtig auch an den nötigen Reiseschutz zu denken.' />
                    <br />
                    <a
                      className='underlined-link md'
                      target='_blank'
                      href='https://www.reiseversicherung.de/baRuntime/start?ba=standard&agency=029955000000&confirmationtarget=info@spabooking24.com&confirmationtype=emailplaintext'
                    >
                      <I18n t='Hier' />
                    </a>
                    <I18n t=' können Sie eine passende Reiseversicherung abschließen.' />
                  </div>
                  <a
                    className='underlined-link md'
                    target='_blank'
                    href='https://www.reiseversicherung.de/baRuntime/start?ba=standard&agency=029955000000&confirmationtarget=info@spabooking24.com&confirmationtype=emailplaintext'
                  >

                  </a>
                </div> */}
              </article>
              {/*<InsuranceBlock insuranceIndicator={insuranceIndicator} toggleBoolState={this.toggleBoolState} franchiseIsActive={franchiseIsActive} insuranceIsActive={insuranceIsActive} />*/}
              <article className='card-info border-form' id='paymentDetails'>
                <h3 className='form-header'>
                  <I18n t='Zahlungsdaten' />
                </h3>
                {daysBeforeArrival > maxBillDays ? (
                  <Fragment>
                    <div className='grid-form'>
                      <I18n class='input-title' t='Wie mochten Sie Ihre Reise bezahlen?' />
                      <div>
                        <p className='custom-input-title mobile-view'>
                          <I18n t='Wie  möchten Sie Ihre Reise bezahlen?' />
                        </p>
                        <div className='input-container'>
                          <div className='radio-box'>
                            <Radio checked={true} className='custom-radio sm-md gray'>
                              <I18n t='Rechnung' />
                            </Radio>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h3 className='form-header sm pad'>
                      <I18n t='Wie funktioniert die Zahlung auf Rechnung?' />
                    </h3>
                    <div className='payment-description'>
                      <i className='custom-icon sm check' />
                      <I18n t='Erst nach Eingang der Buchungsbestätigung erhalten Sie von uns eine Rechnung mit den Zahlungsinformationen.' />
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>
                    <div className='grid-form'>
                      <I18n class='input-title' t='Wie mochten Sie Ihre Reise bezahlen?' />
                      <div>
                        <p className='custom-input-title mobile-view'>
                          <I18n t='Wie  möchten Sie Ihre Reise bezahlen?' />
                        </p>
                        <div className='input-container flex-col'>
                          <div className='radio-box'>
                            <Radio onChange={this.setPaymentType.bind(this, PAYMENT_TYPES.CARD)} checked={this.state.paymentType === PAYMENT_TYPES.CARD} className='custom-radio sm-md gray'>
                              <I18n t='Kreditkarte' />
                            </Radio>
                          </div>
                          <div className='radio-box'>
                            <Radio onChange={this.setPaymentType.bind(this, PAYMENT_TYPES.SEPA)} checked={this.state.paymentType === PAYMENT_TYPES.SEPA} className='custom-radio sm-md gray'>
                              <I18n t='Lastschrift' />
                            </Radio>
                          </div>
                          {daysBeforeArrival >= minBillDays && daysBeforeArrival <= maxBillDays && (
                            <div className='radio-box'>
                              <Radio onChange={this.setPaymentType.bind(this, PAYMENT_TYPES.BILL)} checked={this.state.paymentType === PAYMENT_TYPES.BILL} className='custom-radio sm-md gray'>
                                <I18n t='Rechnung' />
                              </Radio>
                            </div>
                          )}
                        </div>
                      </div>
                      {this.state.paymentType === PAYMENT_TYPES.CARD && (
                        <Fragment>
                          <I18n class='input-title' t='Akzeptierte Kreditkarten' />
                          <div>
                            <p className='custom-input-title mobile-view'>
                              <I18n t='Akzeptierte Kreditkarten' />
                            </p>
                            <div className='input-container'>
                              <i className='custom-icon master-card' />
                              <i className='custom-icon visa' />
                              <i className='custom-icon express' />
                            </div>
                          </div>
                          <I18n class='input-title' t='Karteninhaber' />
                          <div>
                            <div className='input-container' id='cardHolder'>
                              <InputTxt
                                spaceAndLetters
                                placeholder='KARTENINHABER'
                                wrappersClass='inp-lg'
                                onChange={this.onChange}
                                name='cardholderName'
                                inputError={inputError.cardholderName}
                                value={cardholderName}
                              />
                            </div>
                          </div>
                          <StripeCardFields ref={this.stripeCardForm} />
                        </Fragment>
                      )}
                      {this.state.paymentType === PAYMENT_TYPES.SEPA && (
                        <Fragment>
                          <I18n class='input-title' t='Kontoinhaber' />
                          <div>
                            <div className='input-container' id='cardHolder'>
                              <InputTxt
                                placeholder='Kontoinhaber'
                                wrappersClass='inp-lg'
                                onChange={this.onChange}
                                name='cardholderName'
                                inputError={inputError.cardholderName}
                                value={cardholderName}
                              />
                            </div>
                          </div>
                          <StripeIbanFields tooltip={this.props.contentStore.returnContent('checkout_sepa_tooltip', true)} ref={this.stripeSepaForm} />
                          <I18n class='input-title' t='' />
                          <div>
                            <div className='iban-wrapper'>
                              <CheckBox className='sm hide_txt' inputError={inputError.ibanAgree} checked={ibanAgree} onChange={this.onChange.bind(this, 'ibanAgree', !ibanAgree)} />
                              <I18n
                                t={`Ja, bitte buchen Sie den fälligen Betrag vom angegebenen Konto ab.\nMit Klick auf "Jetzt kostenpflichtig buchen" akzeptiere ich das SEPA-Lastschrift-Mandat.`}
                              />
                            </div>
                          </div>
                        </Fragment>
                      )}
                    </div>
                    {this.state.paymentType === PAYMENT_TYPES.BILL && (
                      <div className='block-line'>
                        <h3 className='form-header sm pad'>
                          <I18n t='Wie funktioniert die Zahlung auf Rechnung?' />
                        </h3>
                        <div className='payment-description'>
                          <i className='custom-icon sm check' />
                          <I18n t='Erst nach Eingang der Buchungsbestätigung erhalten Sie von uns eine Rechnung mit den Zahlungsinformationen.' />
                        </div>
                      </div>
                    )}
                  </Fragment>
                )}
              </article>
              <article className='coupon-form border-form radius-border'>
                <h3 className='form-header'>
                  <I18n t='Gutschein' />
                </h3>
                <div className='grid-form'>
                  <div className='input-title with-popover'>
                    <I18n t='Gutscheincode' />
                    <Popover
                      overlayClassName='custom-popover md'
                      content={this.props.contentStore.returnContent('checkout_gutschein_tooltip', true)}
                      placement='right'
                      trigger={isMobile || isTablet ? 'click' : 'hover'}
                    >
                      <QuestionCircleOutlined className='hint-icon' />
                    </Popover>
                  </div>
                  <div>
                    <div className='input-container sp-btw'>
                      <InputTxt wrappersClass='inp-ex-md md-font' Icon={couponStateIcon} onChange={this.onChange} name='couponCode' value={this.state.couponCode} />
                      <button disabled={this.state.promoIsFetching} className='coupon-btn custom-empty-btn' onClick={this.checkCode}>
                        {this.state.promoIsFetching ? <LoadingOutlined className='btn-spinner' /> : <I18n t='Gutscheincode lösen' />}
                      </button>
                    </div>
                  </div>
                </div>
              </article>
              <p id='paymentError' className='warning-msg'>
                {this.state.paymentError && (
                  <Fragment>
                    <ExclamationCircleOutlined className='warn-icon' />
                    {this.state.paymentError}
                  </Fragment>
                )}
              </p>
              {!isMobileOnly && (
                <article className='conclusion-form border-form' id='policy-info'>
                  <p className='content-txt'>
                    {/*<span>{this.props.contentStore.returnContent("checkout_before_checkbox_text")}</span>*/}
                    <span>
                      <I18n t='Hiermit bestätige ich mein Einverständnis mit:' />
                    </span>
                  </p>
                  <div className='check-box-container'>
                    <div>
                      <CheckBox
                        className='sm hide_txt'
                        inputError={policyError.booksanaPolicyIsRead}
                        checked={booksanaPolicyIsRead}
                        onChange={this.onChange.bind(this, 'booksanaPolicyIsRead', !booksanaPolicyIsRead)}
                      />
                      <span>
                        den{' '}
                        <Link to='/information/agb' target='_blank'>
                          <I18n t='AGB' />
                        </Link>{' '}
                        und den{' '}
                        <Link to='/information/privacy' target='_blank'>
                          <I18n t='Datenschutzbestimmungen' />
                        </Link>{' '}
                        von der booksana, sowie die Kenntnisnahme der{' '}
                        <a href={room.vendor.conditionsUrl} target='_blank'>
                          <I18n t='AGB' />
                        </a>{' '}
                        und den Stornobedingungen des Reiseveranstalters {room.vendor.name}.
                        <I18n t=' Ja, ich habe die vorvertraglichen Reise-Informationen und die wichtigsten ' />
                        <a href={room.vendor.guidelinesUrl} target='_blank'>
                          <I18n t='Informationen und Rechte der EU-Pauschalreise-Richtlinie EU 2015/2032' />
                        </a>
                        <I18n t=' zur Kenntnis genommen.' />
                      </span>
                    </div>
                    {/*  <div>*/}
                    {/*    <CheckBox*/}
                    {/*        className='sm hide_txt'*/}
                    {/*        inputError={policyError.conditionsIsRead}*/}
                    {/*        checked={conditionsIsRead}*/}
                    {/*        onChange={this.onChange.bind(this, 'conditionsIsRead', !conditionsIsRead)}*/}
                    {/*    />*/}
                    {/*    <span>*/}
                    {/*  <I18n t='Ja, ich habe die vorvertraglichen Reise-Informationen und die wichtigsten ' />*/}
                    {/*  <a href={room.vendor.guidelinesUrl} target='_blank'>*/}
                    {/*    <I18n t='Informationen und Rechte der EU-Pauschalreise-Richtlinie EU 2015/2032' />*/}
                    {/*  </a>*/}
                    {/*  <I18n t=' zur Kenntnis genommen.' />*/}
                    {/*</span>*/}
                    {/*  </div>*/}
                  </div>
                  {/*<p className='check-data-msg'>*/}
                  {/*  <I18n t='Bitte prüfen Sie Ihre Angaben noch einmal, bevor Sie eine verbindliche Buchungserklärung abgeben!' />*/}
                  {/*</p>*/}
                  <div className='footer-controls'>
                    <a className='btn-back' onClick={this.props.history.goBack}>
                      <i className='custom-icon arrow-back' />
                      <I18n t='zurück' />
                    </a>
                    <button disabled={fetching || recalcError} onClick={this.bookRoom} className='custom-btn booking-btn'>
                      {fetching ? (
                        <>
                          <I18n t='Bitte warten...' />
                          <span className='booking-btn-preloader'>
                            <LoadingOutlined className='btn-spinner' />
                          </span>
                        </>
                      ) : (
                        <I18n t='Verbindlich buchen' />
                      )}
                    </button>
                  </div>
                  <div className='conclusion-pros'>
                    <ProsList list={[prosOptions[1], prosOptions[0], prosOptions[2]]} />
                  </div>
                </article>
              )}
              {this.state.bookingError && <div className='warning-msg bordered'>{ReactHtmlParser(this.state.bookingError)}</div>}
            </div>
          </section>
        </div>
        <div className='server-transaction-msg'>
          <I18n t='Übertragung über Sicherheitsserver' />
          <i className='custom-icon lock' />
        </div>
      </section>
    );
  }
}
