import React, {Component} from 'react';
import Carousel from 'antd/lib/carousel';
import classNames from 'classnames';

export class Slider extends Component {
  next = () => {
    this.carousel.next();
  };

  previous = () => {
    this.carousel.prev();
  };

  carousel = React.createRef();
  wrapper = React.createRef();

  render() {
    const {renderSlides, wrapperClass, settings = {}, featured, showControlls = true} = this.props;
    return (
      <div ref={this.wrapper} className={`slider-wrapper ${wrapperClass || ''}`}>
        {this.props.children &&
          React.cloneElement(this.props.children, {
            featured: featured,
            prev: this.previous,
            next: this.next,
          })}
        {showControlls && (
          <div className={classNames('slider-controllers', {['outer']: this.props.useAccent})}>
            <i onClick={this.previous} className={classNames('slider-controller prev custom-icon-slider-arrow', {['accent']: this.props.useAccent})} />
            <i onClick={this.next} className={classNames('slider-controller next custom-icon-slider-arrow', {['accent']: this.props.useAccent})} />
          </div>
        )}
        <Carousel {...settings} dots={false} ref={node => (this.carousel = node)}>
          {renderSlides()}
        </Carousel>
      </div>
    );
  }
}
