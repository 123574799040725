import React, {Component} from 'react';
import {I18n} from '../i18n/I18n';
import {ReactComponent as Triangle} from '../../../images/icons/triangle.svg';
import {RoomNotice} from '../roomNotice/RoomNotice';
import ReactHtmlParser from 'react-html-parser';

export class AddedInfo extends Component {
  state = {
    isOpen: false,
  };

  toggleExpander = () => {
    this.setState({isOpen: !this.state.isOpen});
  };

  render() {
    const {info} = this.props;

    return this.state.isOpen ? (
      <div className='opened-description'>
        <RoomNotice>{ReactHtmlParser(info)}</RoomNotice>
        <div className='expander active'>
          <I18n onClick={this.toggleExpander} t='Schließen' /> <Triangle onClick={this.toggleExpander} />
        </div>
      </div>
    ) : (
      <div className='expander'>
        <I18n onClick={this.toggleExpander} t='Weiteres' /> <Triangle onClick={this.toggleExpander} />
      </div>
    );
  }
}
