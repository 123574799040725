import React, {Component, Fragment} from 'react';
import {I18n} from '../../helpers/i18n/I18n';

export class OrderFailed extends Component {
  render() {
    return (
      <section className='payment-result-page'>
        <div className='result-txt'>
          <I18n t='operation has ended with error' />
        </div>
      </section>
    );
  }
}
