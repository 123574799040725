import React, {Fragment} from 'react';
import Radio from 'antd/lib/radio';
import classNames from 'classnames';
import {inject, observer} from 'mobx-react';

import {I18n} from '../i18n/I18n';
import {useEffect} from 'preact/hooks';
import {InputTxt} from '../inputTxt/InputTxt';
import {doSmoothScroll, isMobile} from '../../../utils/Utils';
import {isMobileOnly} from 'react-device-detect';

import {ReactComponent as Arrow} from '../../../images/icons/redArrow.svg';

export const CalendarElevator = inject('contentStore')(
  observer(
    ({
      onChange,
      transferIsActive,
      inputChange,
      transferIndexes,
      // openModal,
      transferIndex,
      // indexReadOnly,
      contentStore,
      toggleTransferModal,
      favLink,
      callLink,
      isHotel,
    }) => {
      useEffect(() => {
        const datesContainer = document.getElementsByClassName('CalendarMonthGrid')[0];

        if (datesContainer) {
          if (transferIsActive) {
            datesContainer.classList.add('transfer_offset');
          } else {
            datesContainer.classList.remove('transfer_offset');
          }
        }
      }, [transferIsActive]);

      useEffect(() => {
        doSmoothScroll('#calendar-elevator-id', 300);
        const root = document.getElementById('root');
        const cookiesWidget = document.getElementsByClassName('pe-pro-seal')[0];

        if (isMobile()) {
          root.classList.add('mobile_calendar_open');

          if (cookiesWidget) {
            cookiesWidget.style.display = 'none';
          }
        }

        return () => {
          if (isMobile()) {
            root.classList.remove('mobile_calendar_open');

            if (cookiesWidget) {
              cookiesWidget.style.display = 'block';
            }
          }
        };
      }, []);

      const thereIsntTransfer = transferIndex.length >= 2 && !transferIndexes.some(index => index.toString() === `${transferIndex[0]}${transferIndex[1]}`);

      return (
        <div className='transfer-options  in-datepicker' id='calendar-elevator-id'>
          {/*<div>*/}
          {/*  <Radio className='custom-radio' value={false} onChange={onChange.bind(this, false, 'transferIsActive')} checked={!transferIsActive}>*/}
          {/*    <I18n t='Eigene Anreise' />*/}
          {/*  </Radio>*/}
          {/*  <Radio className='custom-radio' value={true} onChange={onChange.bind(this, true, 'transferIsActive')} checked={transferIsActive}>*/}
          {/*    <I18n t='Haustürtransfer' />*/}
          {/*  </Radio>*/}
          {/*</div>*/}
          {transferIsActive && (
            <div>
              <h3 className='calendar-elevator-title'>
                <I18n t='Haustürtransfer' />
              </h3>
              <div className='calendar-elevator-input'>
                <InputTxt
                  onChange={inputChange}
                  onlyNumbers={true}
                  name='transferIndex'
                  maxLength={5}
                  placeholder='PLZ'
                  value={transferIndex}
                  wrappersClass={classNames('transfer-input ', {
                    'must-be-filled': thereIsntTransfer,
                    'focus-transfer': transferIndex.length < 1,
                  })}
                  id='calendar-transfer-input'
                />
                {thereIsntTransfer ? (
                  <I18n t='Diese Postleitzahl existiern nicht. Bitte korrigieren Sie Ihre Angaben' />
                ) : (
                  <Fragment>
                    <Arrow className={classNames('calendar-elevator-icon', {'calendar-elevator-icon-red': transferIndex.length < 1})} />
                    <div>
                      <p className={classNames('calendar-elevator-descr', {'calendar-elevator-red': transferIndex.length < 1})}>Geben Sie hier die PLZ Ihrer Abholadresse ein</p>
                      <p className='calendar-elevator-descr'>Danach können sie Ihr Anreise- und Abreisedatum wählen.</p>
                      <p className='calendar-elevator-descr mb'>Eine Hinfahrt ist nur am Sonntag und Rückfahrt nur am Samstag möglich</p>
                      {!isMobileOnly &&
                        (isHotel ? (
                          <p className='calendar-elevator-descr'>
                            Wenn Sie dazu Fragen haben oder einen Transfer an anderen Tagen benötigen, können Sie hier ein{' '}
                            <a className='calendar-elevator-link' href={favLink} target='_blank'>
                              <I18n t='individuelles Angebot' />
                            </a>{' '}
                            bzw. einen{' '}
                            <a className='calendar-elevator-link' href={callLink} target='_blank'>
                              <I18n t='Rückruf anfordern' />
                            </a>
                          </p>
                        ) : (
                          <p className='calendar-elevator-descr'>
                            Wenn Sie dazu Fragen haben oder einen Transfer an anderen Tagen benötigen, können Sie einen{' '}
                            <a className='calendar-elevator-link' href={callLink} target='_blank'>
                              <I18n t='Rückruf anfordern' />
                            </a>
                          </p>
                        ))}
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
            // ) : (
            // <div className='calendar-transfer-txt'>
            //   {contentStore.returnContent('transfer search result')}{' '}
            //   <button type='button' onClick={toggleTransferModal}>
            //     mehr
            //   </button>
            // </div>
          )}
        </div>
      );
    }
  )
);
