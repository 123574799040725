import React, {Component} from 'react';
import {BigNumber} from 'bignumber.js';

export class Price extends Component {
  ROUNDING_ACCURACY = 0;
  replaceDot = () => {
    const {transferPrice, minusTransferPrice} = this.props;
    try {
      if (transferPrice !== undefined && minusTransferPrice) {
        return String(new BigNumber(this.props.price).minus(transferPrice).toFixed(this.props.digits || this.ROUNDING_ACCURACY)).replace('.', ',');
      }
      return String(new BigNumber(this.props.price).toFixed(this.props.digits || this.ROUNDING_ACCURACY)).replace('.', ',');
    } catch (e) {
      //console.log(e);
      return this.props.price;
    }
  };

  render() {
    const className = `${this.props.className || ''} ${this.props.isDiscount ? 'discount-price-color' : ''} ${this.props.withDiscount ? 'with-discount-price-color' : ''}`;
    return (
      <span className={className}>
        {this.replaceDot()} <span>€</span>
      </span>
    );
  }
}
