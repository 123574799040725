import {BigNumber} from 'bignumber.js';
import moment from 'moment';
import {DATE_FORMAT, OPTION_DATE_FORMAT} from '../config/const';
import {translate} from './Translater';
import {getSundayByOrderNumber} from './Utils';

class Option {
  constructor(id, price, name, commission, isTransferOption) {
    this.transferOption = isTransferOption;
    this.commission = commission;
    this.name = name;
    this.id = id;
    this.price = +price;
    this.options = [{index: 1, isActive: true, commissionPrice: this.addCommission(+price)}];
  }

  increaseCount(calcFinalSumm) {
    this.options.push({index: this.options.length + 1, isActive: true, commissionPrice: this.addCommission(this.price)});
    calcFinalSumm();
  }

  decreaseCount(calcFinalSumm) {
    this.options.splice(-1, 1);
    calcFinalSumm();
  }

  addCommission(price) {
    if (+price === 0) {
      return 0;
    }
    return Number(new BigNumber(price).multipliedBy(new BigNumber(100).div(new BigNumber(100).minus(this.commission).toFixed(2))).toFixed(0, BigNumber.ROUND_UP));
  }
}

export class TransferOption extends Option {
  constructor(option, commission) {
    super(option.addonId, option.price, option.name, commission, true);
    this.calcPrice();
  }

  increaseCount() {
    super.increaseCount(this.calcPrice);
  }

  decreaseCount() {
    super.decreaseCount(this.calcPrice);
  }

  calcPrice = () => {
    const activeOptions = this.options.filter(option => option.isActive).length;
    this.priceByActiveElems = super.addCommission(activeOptions * this.price); // it is used to calc booking price with selected and active options
    this.finalPrice = super.addCommission(this.options.length * this.price); // stores price with selected options in start form, isnt used in final calculation
  };
}

export class SelectedHotelOption extends Option {
  constructor(option, departureDate, arrivalDate, commission) {
    const {id, price, regulation, start, name, end} = option;
    super(id, price, name, commission, false);
    this.finalPrice = 0;
    this.optionStarts = moment(`${start.dayOfMonth}-${start.monthValue}-${start.year}`, OPTION_DATE_FORMAT);
    this.optionEnds = moment(`${end.dayOfMonth}-${end.monthValue}-${end.year}`, OPTION_DATE_FORMAT);
    this.departureDate = departureDate;
    this.arrivalDate = arrivalDate;
    this.optionType = regulation;
    this.calcPrice();
  }

  increaseCount() {
    super.increaseCount(this.calcPrice);
  }

  decreaseCount() {
    super.decreaseCount(this.calcPrice);
  }

  returnDate = date => moment(date, DATE_FORMAT);

  calcPrice = () => {
    const activeOptions = this.options.filter(option => option.isActive).length;
    if (this.optionType !== 'ONCE') {
      const arrivalDate = this.returnDate(this.arrivalDate);
      const departureDate = this.returnDate(this.departureDate);
      const daysInPeriod = departureDate.diff(arrivalDate, 'days');
      let stayingDaysInOptionPeriod = 0;
      for (let i = 0; i <= daysInPeriod; i++) {
        //check option period
        this.returnDate(this.arrivalDate).add(i, 'days').isBetween(this.optionStarts, this.optionEnds) && stayingDaysInOptionPeriod++;
      }
      this.priceByActiveElems = super.addCommission(activeOptions * stayingDaysInOptionPeriod * this.price);
      this.finalPrice = super.addCommission(this.options.length * stayingDaysInOptionPeriod * this.price);
    } else {
      this.priceByActiveElems = super.addCommission(activeOptions * this.price);
      this.finalPrice = super.addCommission(this.options.length * this.price);
    }
  };
}

export const returnPaymentAddress = (city, country, postcode, state = 'string', street1, street2, phone) => ({
  city,
  country,
  postcode,
  state,
  street1,
  street2,
  ...(phone && {phone}),
});

export const returnDefaultSearchForm = hotelId => {
  return {
    arrivalDate: getSundayByOrderNumber(2).format(DATE_FORMAT),
    attributes: [],
    bestPrice: 0,
    checkAvailability: false,
    children: [],
    country: null,
    departureDate: getSundayByOrderNumber(2).add(6, 'days').format(DATE_FORMAT),
    dictionaries: [],
    hasDiscount: null,
    discountTypeId: null,
    hotelId: null,
    indicationId: null,
    isFeatured: null,
    kindOfCatering: 'HB',
    numberAdults: 2,
    regionId: null,
    roomId: null,
    searchString: '',
    showTreatments: false,
    transfer: false,
    transferIndex: null,
  };
};
