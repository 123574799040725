import React, {Component, Fragment} from 'react';
import {I18n} from '../../helpers/i18n/I18n';
import Radio from 'antd/lib/radio';
import {InputTxt} from '../../helpers/inputTxt/InputTxt';
import {BirthInputs} from './BirthInputs';
import classNames from 'classnames';

export class InputsRow extends Component {
  render() {
    const {personType, header, onChange, changeRadio, firstName, lastName, birthYear, birthMonth, birthDay, i, gender, errors, isNewPage} = this.props;
    return (
      <Fragment>
        <h3 className={`form-header sm ${i > 0 ? 'mobile-view' : ''}`}>
          {/*<I18n t="Reisende Personen"/>*/}
          <span>{header}</span>
        </h3>
        <div className={`inputs-row ${i > 0 ? 'hide-title' : ''}`}>
          <div>
            <p className={`custom-input-title ${i > 0 ? 'mobile-view' : ''}`}>
              <I18n t='Anrede, Titel' />
            </p>
            <div className='members-gender inputs-wrapper'>
              <Radio.Group className={`${errors[`title${i}`] ? 'error' : ''}`} name='title' onChange={changeRadio.bind(this, i, personType)} value={gender}>
                <div className='radio-box'>
                  <Radio value='Herr' className='custom-radio sm-md gray'>
                    <I18n t='common.man' />
                  </Radio>
                </div>
                <div className='radio-box'>
                  <Radio value='Frau' className='custom-radio sm-md gray'>
                    <I18n t='common.woman' />
                  </Radio>
                </div>
              </Radio.Group>
            </div>
          </div>
          <div className={classNames('members-name inputs-wrapper', isNewPage ? '' : 'm-r')}>
            <InputTxt inputError={errors[`firstName${i}`]} placeholder='Vorname' onChange={onChange.bind(this, i, personType)} title='Vorname' name='firstName' value={firstName} />
            <InputTxt inputError={errors[`lastName${i}`]} placeholder='Nachname' onChange={onChange.bind(this, i, personType)} title='Nachname' name='lastName' value={lastName} />
          </div>
          {!isNewPage && (
            <Fragment>
              <I18n class='custom-input-title mobile-view' t='Geburtsdatum' />
              <div className='members-birth inputs-wrapper'>
                <BirthInputs
                  wrapperClass='members-birth inputs-wrapper'
                  onChange={onChange.bind(this, i, personType)}
                  birthDay={birthDay}
                  birthMonth={birthMonth}
                  birthYear={birthYear}
                  birthDayName='birthDay'
                  birthMonthName='birthMonth'
                  birthYearName='birthYear'
                  birthDayTitle='Geburtsdatum'
                  birthDayError={errors[`birthDay${i}`]}
                  birthMonthError={errors[`birthMonth${i}`]}
                  birthYearError={errors[`birthYear${i}`]}
                />
              </div>
            </Fragment>
          )}
        </div>
      </Fragment>
    );
  }
}
