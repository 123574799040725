import {action, computed, observable} from 'mobx';
import {getIndicationOptions, getPageContent, getSavedSearches, regionsAndHotels, getCountriesAndRegions} from '../utils/Fetcher';

class SearchStore {
  @observable active = null;
  @observable formData = null;
  @observable allRegions = [];
  @observable indications = [];
  @observable savedSearches = [];
  @observable hotelsAndRegions = [];
  @observable countriesAndRegions = [];
  @observable transferIndexes = [];
  @observable activeFilterTabs = ['1', '4'];
  @observable kindOfCatering = 'FB';
  @observable savedSearchesFetching = false;

  constructor(callBack) {
    const obj = sessionStorage.getItem('active');
    if (obj) {
      this.setActive(JSON.parse(obj));
    }
    Promise.all([getCountriesAndRegions(), getSavedSearches()]).then(resp => {
      if (!resp[0].isError) {
        this.transferIndexes = resp[0].data.reduce((acc, country) => {
          country.regions.forEach(region => {
            if ((region.indexes || []).length) {
              acc = [...acc, ...region.indexes];
            }
          });
          return acc;
        }, []);
        const regions = resp[0].data.filter(elem => !!elem.cms);
        regions.forEach(countries => {
          countries.regions = countries.regions.filter(region => region.cms);
        });

        this.allRegions = regions.reduce((acc, cur) => {
          acc = [...acc, ...cur.regions];
          return acc;
        }, []);

        this.countriesAndRegions = regions.map(country => ({
          ...country,
          hotelsCount: country.regions.reduce((acc, curVal) => {
            acc += curVal.hotelsCount;
            return acc;
          }, 0),
        }));
      }
      if (!resp[1].isError) {
        this.savedSearches = resp[1].data;
      }
      const isError = resp.some(r => r.isError);
      callBack(!isError);
    });
  }

  certainSearch = key =>
    computed(() => {
      //i know that is bull shit, but i didnt have time =)
      const result1 = (this.allRegions.find(elem => (elem.cms || {}).slug === key) || {}).cms;
      const result2 = this.savedSearches.find(elem => elem.slug === key);
      const result3 = (this.countriesAndRegions.find(elem => (elem.cms || {}).slug === key) || {}).cms;
      return result1 || result2 || result3;
    }).get();

  removeFormParams = () => {
    this.formData = undefined;
  };

  @action
  getSavedSearches = () => {
    this.savedSearchesFetching = true;
    const prom = getSavedSearches();
    return prom.then(resp => {
      if (!resp.isError) {
        this.savedSearches = resp.data;
      }
      this.savedSearchesFetching = false;
      return resp;
    });
  };

  @action
  setFormData(fromData) {
    this.formData = {...fromData, hideInputsMobile: !!(fromData.departureDate && fromData.arrivalDate)};
  }

  @action
  getRegionsAndHotels = (query = '', saveResult = true, country) => {
    const prom = regionsAndHotels(query, country);
    prom.then(resp => {
      if (!resp.isError) {
        saveResult && (this.hotelsAndRegions = {items: resp.data.map(dic => ({...dic, label: dic.value}))});
      }
      return resp;
    });
    return prom;
  };

  @action
  getIndicationOptions = (query, saveResult = true) => {
    const prom = getIndicationOptions(query);
    prom.then(resp => {
      if (!resp.isError) {
        saveResult && (this.indications = {items: resp.data.map(dic => ({...dic, label: dic.value}))});
      }
      return resp;
    });
    return prom;
  };

  @action
  setActive(active) {
    sessionStorage.setItem('active', JSON.stringify(active));
    this.active = active;
  }
}

export default SearchStore;
