export const hideWidget = () => {
  if (!document.body.classList.contains('widget-hidden')) {
    document.body.classList.add('widget-hidden');
  }
};

export const showWidget = () => {
  if (document.body.classList.contains('widget-hidden')) {
    document.body.classList.remove('widget-hidden');
  }
};
