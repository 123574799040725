import {action, observable} from 'mobx';

class OrderStore {
  @observable order = {};

  @action
  saveOrder(orderInfo) {
    this.order = orderInfo;
  }

  @action
  deleteOrder() {
    this.order = {};
  }
}

export default OrderStore;
