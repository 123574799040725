import React, {Component} from 'react';

export class StarsBlock extends Component {
  render() {
    const {count = []} = this.props;
    return count.length > 0 ? (
      <div className={`stars-block ${this.props.className ? this.props.className : ''}`} itemProp='starRating' itemScope itemType='http://schema.org/Rating'>
        <meta itemProp='ratingValue' content={count.length} />
        {count.map((elem, i) => (
          <i key={i} className='custom-icon-star' />
        ))}
      </div>
    ) : null;
  }
}
