import React, {Component} from 'react';
import classNames from 'classnames';
import {ReactComponent as Ok} from '../../../images/toasterItem/ok.svg';
import {ReactComponent as Info} from '../../../images/toasterItem/info.svg';
import {ReactComponent as Error} from '../../../images/toasterItem/error.svg';
import {ReactComponent as Warning} from '../../../images/toasterItem/warning.svg';
import cross from '../../../images/toasterItem/cross.png';
import {MSG_TYPES} from '../../../store/LoggerStore';

const infoIcons = {
  OK: Ok,
  ERROR: Error,
  WARNING: Warning,
  INFO: Info,
};

export class ToasterItem extends Component {
  timerLink = null;

  componentDidMount() {
    this.timerLink = setTimeout(this.props.destroyCb, 4000);
  }

  removeItem = () => {
    clearTimeout(this.timerLink);
    this.props.destroyCb();
    this.timerLink = null;
  };

  render() {
    const {type, txt} = this.props;
    const Icon = infoIcons[type] || null;
    const className = classNames('logger-item', {
      info: type === MSG_TYPES[3],
      ok: type === MSG_TYPES[0],
      warn: type === MSG_TYPES[2],
      err: type === MSG_TYPES[1],
    });
    return (
      <div className={className}>
        <Icon />
        <span>{txt}</span>
        <img onClick={this.removeItem} alt='cross ic' src={cross} />
      </div>
    );
  }
}
