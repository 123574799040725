import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';

@inject('popup')
@observer
class ModalContainer extends Component {
  state = {
    modalOpen: true,
  };

  toggleModalState = () => {
    this.setState({modalOpen: !this.state.modalOpen});
  };

  closeModal = () => {
    this.props.popup.close();
    // if (this.state.modalOpen) {
    //   this.toggleModalState();
    //   setTimeout(() => {
    //     this.props.popup.close();
    //     this.toggleModalState();
    //   }, 300);
    // }
  };

  render() {
    const {modals} = this.props.popup;
    const Cmp = this.props.popup.activeModal;
    return Cmp ? <Cmp params={modals.data} onOk={modals.onOk} modalOpen={this.state.modalOpen} closeModal={this.closeModal} /> : null;
  }
}

export default ModalContainer;
