import React, {Component} from 'react';
import Employee from '../../../images/employee.jpg';
import {I18n} from '../i18n/I18n';
import {inject, observer} from 'mobx-react';

@inject('contentStore')
@observer
export class EmployeeCard extends Component {
  render() {
    const {showEmail = true, showHeader = true, imgToRight = false} = this.props;
    return (
      <div className='employee-card'>
        <div className={`card-header ${imgToRight ? 'right-side' : ''}`}>
          <img alt='employee img' src={Employee} />
          <h5>
            <I18n t='Kundenservice' />
          </h5>
          <p className='employee-name'>{this.props.contentStore.returnContent('service_man_name')}</p>
          <p className='employee-post'>{this.props.contentStore.returnContent('service_man_name_title')}</p>
        </div>
        <div className='card-footer'>
          <div className='phone-contact'>
            {showHeader ? (
              <h6>
                <I18n t='Wir beraten Sie gerne persönlich:' />
              </h6>
            ) : (
              <p className='content-txt'>
                <I18n t='Ich unterstütze Sie gern bei Ihrer Reisebuchung und danach' />
              </p>
            )}
            <a href={`tel:${this.props.contentStore.returnContent('phone_number')}`} className='phone-number'>
              <i className='custom-icon-phone bl' />
              {this.props.contentStore.returnContent('phone_number')}
            </a>
            <p className='working-time'>{this.props.contentStore.returnContent('working_time')}</p>
          </div>
          {showEmail && (
            <div className='email-contact'>
              <h6>
                <I18n t='Oder kontaktieren Sie uns:' />
              </h6>
              <p>info@booksana.com</p>
            </div>
          )}
        </div>
      </div>
    );
  }
}
