import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import {ReactComponent as ArrowIcon} from '../../../images/icons/arrow-right-line.svg';
import {FILTER_TYPE} from '../../mainPages/hotelSuggestion/HotelSuggestion';

export class DiscountDropDown extends Component {
  render() {
    return (
      <div className='discount-menu'>
        {/*<Link target='_blank' to={`/sonderangebote/${FILTER_TYPE.lastMoment}`}>*/}
        {/*  Last-Minute-Reisen <ArrowIcon />*/}
        {/*</Link>*/}
        <Link target='_blank' to={`/sonderangebote/${FILTER_TYPE.general}`}>
          Angebot der Woche <ArrowIcon />
        </Link>
        <Link target='_blank' to={`/sonderangebote/${FILTER_TYPE.firstMoment}`}>
          Frühbucher-Aktion <ArrowIcon />
        </Link>
      </div>
    );
  }
}
