import XRegExp from 'xregexp';
import React, {Component} from 'react';

import Input from 'antd/lib/input';
import {I18n} from '../i18n/I18n';
import {translate} from '../../../utils/Translater';

const DAYS_COUNT = 31;
const MONTH_COUNT = 12;

export class InputTxt extends Component {
  input = React.createRef();

  componentDidMount() {
    this.props.focusOnStart && this.focus();
  }

  setNewValue(inputName, value) {
    this.props.onChange && this.props.onChange(inputName, value);
  }

  inputChange = ev => {
    const {onlyNumbers = false, spaceAndLetters = false, onlyLetters = false, maxLength, dayCheck, monthCheck, yearCheck} = this.props;
    let canSetNewValue = true;
    if (onlyNumbers) {
      canSetNewValue = /^\d+$/.test(ev.target.value) || !ev.target.value.length;
    }
    if (onlyLetters) {
      // canSetNewValue = XRegExp('^\\p{L}*$').test(ev.target.value) || !ev.target.value.length;
      canSetNewValue = RegExp(/^\D+$/).test(ev.target.value) || !ev.target.value.length;
    }
    if (spaceAndLetters) {
      canSetNewValue = RegExp(/^[a-zA-Z\s]+$/).test(ev.target.value) || !ev.target.value.length;
    }
    if (maxLength) {
      ev.target.value.length > maxLength && (canSetNewValue = false);
    }
    if (dayCheck) {
      (Number(ev.target.value) < 0 || Number(ev.target.value) > DAYS_COUNT || ev.target.value == '00') && (canSetNewValue = false);
    }
    if (monthCheck) {
      (Number(ev.target.value) < 0 || Number(ev.target.value) > MONTH_COUNT || ev.target.value == '00') && (canSetNewValue = false);
    }
    if (yearCheck) {
      const firstFigure = ev.target.value[0];
      const secondFigure = ev.target.value[1];
      (firstFigure < 1 || firstFigure > 2) && (canSetNewValue = false);
      if (secondFigure) {
        ev.target.value < 19 && (canSetNewValue = false);
      }
    }
    this.setNewValue(ev.target.name, !canSetNewValue ? this.props.value : ev.target.value);
  };

  focus = () => {
    this.input.current && this.input.current.focus();
  };

  render() {
    const {placeholder, onClick, Icon, value, name, wrappersClass, title, inputError, disabled, description, readOnly, id} = this.props;
    return (
      <div onClick={onClick} className={`custom-input ${wrappersClass || ''} ${inputError ? 'error-border' : ''}`}>
        {title && (
          <p className='custom-input-title'>
            <I18n t={title} />
          </p>
        )}
        <Input ref={this.input} name={name} disabled={disabled} placeholder={translate(placeholder)} value={value} onChange={this.inputChange} readOnly={readOnly} {...(id && {id})} />
        {Icon && <Icon className='custom-input-icon' />}
        {description && <p className='custom-input-description'>{description}</p>}
      </div>
    );
  }
}
