import React, {Component, Fragment} from 'react';

export const RENDER_TYPES = {
  FULL: 'FULL',
  HALF: 'HALF',
};

export class HotelAddress extends Component {
  renderAddress = () => {
    const {hotel, separator, renderType, reduceForMobile} = this.props;
    switch (renderType) {
      case RENDER_TYPES.FULL:
        return (
          <Fragment>
            <span className={`${reduceForMobile ? 'hide-for-mobile' : ''}`} itemProp='streetAddress'>
              {hotel.street + ' ' + hotel.number + ', '}
            </span>
            <span itemProp='addressRegion'>
              <span className={`${reduceForMobile ? 'hide-for-mobile' : ''}`}>{hotel.postcode + ' '}</span>
              {hotel.city}
            </span>
            <span className={`${reduceForMobile ? 'hide-for-mobile' : ''}`} itemProp='addressCountry'>
              {', ' + hotel.country}
            </span>
          </Fragment>
        );
      case RENDER_TYPES.HALF:
        return (
          <Fragment>
            <span itemProp='addressCountry'>{hotel.city}</span>
            {separator}
            <span itemProp='addressCountry'>{hotel.country}</span>
          </Fragment>
        );
      default:
        return null;
    }
  };

  render() {
    return (
      <span className={`address-wrapper ${this.props.className || ''}`} itemProp='address' itemScope itemType='http://schema.org/PostalAddress'>
        {this.renderAddress()}
      </span>
    );
  }
}
