import React, {Fragment} from 'react';

import {HotelLink} from '../HotelLink';
import {I18n} from '../i18n/I18n';
import {isTablet, returnPicSize} from '../../../utils/Utils';
import {Slider} from '../slider/Slider';
import {returnSizeString} from '../../../config/const';

const PC_MIN_HEIGHT = 232;
const PC_MAX_HEIGHT = 400;
const TABLET_MIN_HEIGHT = 232;
const TABLET_MAX_HEIGHT = 400;

const returnSliderSizes = () => {
  if (isTablet()) {
    return [TABLET_MIN_HEIGHT, TABLET_MAX_HEIGHT];
  }

  return [PC_MIN_HEIGHT, PC_MAX_HEIGHT];
};

export class FlexibleSlider extends React.Component {
  state = {
    activeSlide: 0,
    slideHeight: returnSliderSizes()[0],
  };

  sliderWrapper = React.createRef();
  slider = React.createRef();

  componentDidMount() {
    this.setState({slideHeight: this.calcSliderHeight()});
    // console.log(this.sliderWrapper.current.children)
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.slider) {
      setTimeout(() => {
        if (this.slider.current.wrapper.current) {
          this.slider.current.wrapper.current.style.display = 'none';
          const height = this.calcSliderHeight();
          this.slider.current.wrapper.current.style.display = 'block';
          this.setState({slideHeight: height});
        }
      }, 50);
    }
  }

  calcSliderHeight = () => {
    if (this.sliderWrapper.current) {
      const [minHeight, maxHeight] = returnSliderSizes();
      const wrapperHeight = this.sliderWrapper.current.clientHeight;

      if (wrapperHeight < minHeight) {
        return minHeight;
      }

      if (wrapperHeight > maxHeight) {
        return maxHeight;
      }
      return wrapperHeight;
    }

    return minHeight;
  };

  render() {
    const {hotel, searchRequestId} = this.props;
    const media = hotel.media || [];

    const slider = () => {
      return media.map((url, i) => (
        <HotelLink target='_blank' key={i} className='slider-item' hotel={hotel} searchRequestId={searchRequestId}>
          <img style={{height: this.state.slideHeight}} itemProp='photo' className='main-img' alt='hotelImg' src={`${url}?${returnSizeString(isTablet() ? 400 : 442, this.state.slideHeight)}`} />
        </HotelLink>
      ));
    };

    const sliderSettings = {
      beforeChange: (current, next) => this.setState({activeSlide: next}),
      lazyLoad: 'ondemand',
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      centerMode: isTablet() ? false : true,
    };

    return (
      <div ref={this.sliderWrapper} className='img-container'>
        {media.length < 1 ? (
          <HotelLink target='_blank' className='slider-item empty-slide' hotel={hotel} searchRequestId={searchRequestId}>
            <div className='main-img'>
              <I18n t='Hotel image' />
            </div>
          </HotelLink>
        ) : (
          <Fragment>
            <div className='slide-counter'>{`${this.state.activeSlide + 1} von ${media.length}`}</div>
            <Slider ref={this.slider} settings={sliderSettings} renderSlides={slider} />
          </Fragment>
        )}
      </div>
    );
  }
}
