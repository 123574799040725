import React from 'react';
import {I18n} from '../../helpers/i18n/I18n';

export const BookingBtn = ({extraDays, bookRoom, scrollToForm, className, titleBtn}) => (
  <div className={`footer-btn-wrapper ${className ? className : ''}`}>
    <button style={{width: '100%'}} className='custom-empty-btn' onClick={extraDays ? scrollToForm : bookRoom}>
      <I18n t={titleBtn} />
    </button>
  </div>
);
