import React, {Component} from 'react';
import classNames from 'classnames';

import {CopyField} from '../../helpers/copyField/CopyField';
import {ReactComponent as Arrow} from '../../../images/icons/promo-arrow.svg';
import {withRouter} from 'react-router';
import {PaymentHeader} from '../../helpers/paymentHeader/PaymentHeader';
import {Fragment} from 'preact';
import {isMobile} from '../../../utils/Utils';

@withRouter
export class PromoCodeBlock extends Component {
  // constructor(props) {
  //   super(props);
  //   const urlParams = props.match.params;
  //   const isHotelPage = urlParams.country && urlParams.region && urlParams.hotelName && urlParams.hotelId;
  //   this.state = {
  //     hiddenPromo: !!isHotelPage,
  //   };
  // }
  //
  // componentWillReceiveProps(nextProps) {
  //   const urlParams = nextProps.match.params;
  //   const isHotelPage = urlParams.country && urlParams.region && urlParams.hotelName && urlParams.hotelId;
  //   !!isHotelPage !== this.state.hiddenPromo && this.setState({hiddenPromo: !!isHotelPage});
  // }

  render() {
    const {hiddenPromo} = this.state;
    return (
      // <div className={classNames('fixed-promo-block', {'hide-promo': hiddenPromo})}>
      <Fragment>
        {/*<div className={classNames('promo-block', {'hide-promo': hiddenPromo})}>*/}
        {/*  <div>*/}
        {/*    <Arrow className='promo-arrow' />*/}
        {/*    <span>*/}
        {/*      <span className='md-font'> 20€ RABATT </span>-CODE:*/}
        {/*    </span>*/}
        {/*    <CopyField copyTxtValue='202020EUR' cdTxt='Kopiert' txt='kopieren'>*/}
        {/*      <div className='copy-value'>*/}
        {/*        <span> 202020 EUR </span>*/}
        {/*      </div>*/}
        {/*    </CopyField>*/}
        {/*    /!*<span>läuft in 24 Stunden ab</span>*!/*/}
        {/*    <Arrow className='promo-arrow rotate' />*/}
        {/*  </div>*/}
        {/*</div>*/}
        {!isMobile() && <PaymentHeader />}
      </Fragment>
    );
  }
}
