import React from 'react';
import {MEALS_TYPES} from '../../helpers/searchForm/SearchForm';
import {Slider} from '../../helpers/slider/Slider';
import {RoomDescriptionLink} from '../../helpers/roomDescriptionLink/RoomDescriptionLink';
import {RoomsFeeLink} from '../../helpers/roomsFeeLink/RoomsFeeLink';
import {RoomOptions} from '../../modals/roomOptions/RoomOptions';

const settings = {
  arrows: false,
  // infinite: false,
  slidesToShow: 1,
  variableWidth: true,
};
export const MobileSlider = ({room, request, hideRoomDescription, showFees, hotel, openModal, renderRoomSlides}) => (
  <div className='mobile-view room-slides-wrapper'>
    <div className='hotel-name' itemProp='name'>
      {room.roomName}
    </div>
    <div className='hotel-catering' itemProp='name'>
      {MEALS_TYPES[room.kindOfCatering]}
    </div>
    <div className='cards-gallery mobile-view'>
      <Slider settings={settings} wrapperClass='mobile-gallery hotels' renderSlides={renderRoomSlides.bind(this, room.media || [])} />
    </div>
    <div className='actions-popup-wrapper'>
      {!hideRoomDescription && (
        <RoomDescriptionLink
          hotel={hotel}
          room={room}
          openRoomModal={openModal}
          modal={RoomOptions}
          roomAmenities={room.roomAmenities}
          classModal='room-modal'
          roomService
          classIcon='custom-icon sm-md check green'
        />
      )}
      {showFees && <RoomsFeeLink price={room.price} openModal={openModal} arrivalDate={request.arrivalDate} cancellationOptions={hotel.cancellationFees} />}
    </div>
  </div>
);
