import React, {Component, Fragment} from 'react';
import Lightbox from 'react-image-lightbox';
import Affix from 'antd/lib/affix';
import {isMobile} from 'react-device-detect';
import * as Scroll from 'react-scroll/modules';

import {Slider} from '../../helpers/slider/Slider';
import {I18n} from '../../helpers/i18n/I18n';
import {BreadCrumbs} from '../../helpers/breadCrumbs/BreadCrumbs';
import {StarsBlock} from '../../helpers/starsBlock/StarsBlock';
import {EmployeeLgBoard} from '../../helpers/employeeLgBoard/EmployeeLgBoard';
import {TimeLine} from './TimeLine';
import {isMobileOnly} from 'react-device-detect';
import {inject, observer} from 'mobx-react';
import Room from '../../helpers/room/Room';
import {Preloader} from '../../helpers/preloader/Preloader';
import {OfferCard} from '../../helpers/gridSuggestions/OfferCard';
import {concatDateStrings, convertToMoney, doSmoothScroll, getSearchFormOffsetTop, isDesktop, isTablet, removeMetaData, returnPicSize, setMetaData} from '../../../utils/Utils';
import {MEALS_TYPES, SearchForm} from '../../helpers/searchForm/SearchForm';
import {translate} from '../../../utils/Translater';
import ReactHtmlParser from 'react-html-parser';
import {HotelFood} from './HotelFood';
import {CancelationOption} from '../../helpers/CancelationOption';
import {AgeDiscounts} from '../../helpers/AgeDiscounts';
import {EmptyResult} from '../../helpers/emptyResult/EmptyResult';
import {Map} from '../../modals/map/Map';
import {HotelAddress, RENDER_TYPES} from '../../helpers/hotelAddress/HotelAddress';
import {callLinkDestroy, callLinkDestroyV2, search as searchHotels} from '../../../utils/Fetcher';
import {DATE_FORMAT} from '../../../config/const';
import {RoomNotice} from '../../helpers/roomNotice/RoomNotice';
import {Navigation} from './Navigation';
import {HotelName} from '../../helpers/hotelName/HotelName';
import {ReactComponent as Pointer} from '../../../images/icons/map-pointer.svg';
import {SwimmingBtn} from '../../helpers/swimmingBtn/SwimmingBtn';
import {ServiceIcons} from '../../helpers/serviceIcons/ServiceIcons';
import {ReactComponent as MastercardLogo} from '../../../images/icons/Mastercard-logo.svg';
import {ReactComponent as Visa} from '../../../images/icons/Visa.svg';
import {ReactComponent as VisaElectron} from '../../../images/icons/Visa_Electron.svg';
import {ReactComponent as Cash} from '../../../images/icons/Cash.svg';

import {returnDefaultSearchForm} from '../../../utils/Models';
import {ReactComponent as Heart} from '../../../images/icons/heart.svg';
import {RoomDescriptionLink} from '../../helpers/roomDescriptionLink/RoomDescriptionLink';
import {RoomOptions} from '../../modals/roomOptions/RoomOptions';
import {RoomsFeeLink} from '../../helpers/roomsFeeLink/RoomsFeeLink';
import {MobileSlider} from './MobileSlider';
import {GuestsCount} from './GuestsCount';
import {PriceView} from '../../helpers/priceView/PriceView';
import {TaxIncluded} from '../../helpers/taxIncluded/taxIncluded';
import {TaxBill} from '../../modals/taxBill/TaxBill';
import OfferRoom from '../../helpers/offerRoom/OfferRoom';

const MAX_DESCRIPTION_LENGTH = 380;
const PERCENT = 100;
const DAYS_HOURS = 24;
const SpyLink = Scroll.Link;

const paymentImgs = {
  Mastercard: <MastercardLogo />,
  VISA: <Visa />,
  'VISA Electron': <VisaElectron class='visa-electron' />,
  Cash: <Cash />,
};

@inject('hotelRooms', 'popup', 'loggerStore')
@observer
class HotelOffer extends Component {
  state = {
    hotelFetching: false,
    roomsFetching: false,
    photoIndex: 0,
    isOpen: false,
    showOtherRooms: false,
    hotelRooms: this.props.hotelRooms,
  };
  formRef = React.createRef();
  copyField = React.createRef();

  componentDidMount() {
    this.setState({hotelFetching: true, roomsFetching: true});
    this.makeRequest().then(resp => {
      if (!resp.isError) {
        setMetaData(`${resp.data.hotel?.name} in ${resp.data.hotel?.city} ${resp.data.hotel?.region ? `/${resp.data.hotel?.region}` : ''} | ${resp.data.hotel?.country}`);
      }
      this.setState({hotelFetching: false, roomsFetching: false});
      const scrollPoint = this.props.location.hash;
      scrollPoint && window.scroll(0, getSearchFormOffsetTop(scrollPoint));

      if (gtag) {
        try {
          gtag('event', 'view_item', {
            items: [
              {
                item_id: resp.data.hotel?.id,
                item_name: resp.data.hotel?.name,
                item_category: 'Hotel',
              },
            ],
          });
        } catch (e) {
          console.error(e);
        }
      }
    });
  }

  makeRequest = (pagination = {size: 100}) => {
    const urlParams = this.props.match.params;
    const resultId = window.location.search ? window.location.search.split('?resultId=')[1] : null;
    const params = {
      ...pagination,
      ...(resultId && {resultId}),
    };
    if (urlParams.searchRequestId) {
      return this.state.hotelRooms.selectHotel(urlParams.hotelId, urlParams.searchRequestId, params);
    } else {
      const defaultParams = returnDefaultSearchForm(urlParams.hotelId);
      return searchHotels(defaultParams, {size: 20})
        .then(r => {
          return r;
        })
        .then(r => {
          if (r.isError) {
            return r;
          }
          return this.state.hotelRooms.selectHotel(urlParams.hotelId, r.data?.form?.id, {size: 100});
        });
    }
  };

  componentWillUnmount() {
    removeMetaData();
  }

  openModal = (modalCmp, txt) => {
    this.props.popup.open(modalCmp, null, null, txt);
  };

  scrollToBlock = id => {
    doSmoothScroll(id, 165);
  };

  onSearchError = () => {
    this.setState({roomsFetching: false});
  };

  onSearchStart = () => {
    this.setState({roomsFetching: true});
  };

  onSearchFinish = resp => {
    const urlParams = this.props.match.params;
    const requestId = resp.content[0]?.searchRequestId;
    if (resp.content.length) {
      const newUrl = `/${urlParams.country}/${urlParams.region}/${urlParams.hotelName}/${urlParams.hotelId}${requestId ? `/${requestId}` : ''}`;
      this.state.hotelRooms.selectHotel(urlParams.hotelId, requestId || null, {}).then(resp => {
        this.setState({roomsFetching: false});
        this.props.history.replace(newUrl);
      });
    } else {
      this.state.hotelRooms.setEmptyHotel();
    }
  };

  checkDateInputs = () => {
    this.formRef.current && this.formRef.current.checkDateInputs();
  };

  copyTxt = (txt, hotelId, resId) => {
    const urlParams = this.props.match.params;
    const searchRequestId = urlParams.searchRequestId;
    // console.log(hotelId, resId, searchRequestId)
    // hotel request id hotel id
    // room resultId
    let prom;
    if (hotelId && searchRequestId && !resId) {
      prom = callLinkDestroy(hotelId, searchRequestId);
    } else if (resId) {
      prom = callLinkDestroyV2(resId);
    }
    prom.then(res => {
      if (res.isError) {
        const msg = {type: 'ERROR', msgTxt: 'Link nicht kopiert, bitte wenden Sie sich an den technischen Support'};
        this.props.loggerStore.addItem(msg);
      } else {
        this.copyField.current.value = '';
        this.copyField.current.value = txt;
        this.copyField.current.select();
        this.copyField.current.setSelectionRange(0, 99999);
        document.execCommand('copy');
      }
    });
  };

  toggleBoolState = (prop, val) => {
    this.setState({[prop]: val});
  };

  returnHostAddress = () => {
    const protocol = location.protocol;
    const hostName = window.location.hostname === 'localhost' ? `${window.location.hostname}:3000` : window.location.hostname;
    const slashes = protocol.concat('//');
    return slashes.concat(hostName);
  };

  returnCopyLink = (url, resultId) => {
    const urlParams = this.props.match.params;
    const searchRequestId = urlParams.searchRequestId;
    if (!resultId) {
      return this.returnHostAddress() + url + `${searchRequestId ? `/${searchRequestId}` : ''}`;
    }
    return this.returnHostAddress() + url + `${searchRequestId ? `/${searchRequestId}` : ''}` + `?resultId=${resultId}`;
  };

  openLink = (url, resId, isEmail, isPrint) => {
    const resultUrl = this.returnCopyLink(url);
    let link;
    if (isEmail) {
      if (resId) {
        link = `https://betterbusiness.typeform.com/to/Xdz0137Y#angebot=${resId}&url=${resultUrl}`;
      } else {
        link = `https://betterbusiness.typeform.com/to/KthB21Bx#url=${resultUrl}`;
      }
    } else if (isPrint) {
      if (resId) {
        link = `https://betterbusiness.typeform.com/to/PkMMG1FI#url=${resultUrl}&angebot=${resId}`;
      } else {
        link = `https://betterbusiness.typeform.com/to/U7oTR7zU#url=${resultUrl}`;
      }
    } else {
      if (resId) {
        link = `https://betterbusiness.typeform.com/to/ogU99k7W#url=${resultUrl}&angebot=${resId}`;
      } else {
        link = `https://betterbusiness.typeform.com/to/e9Vkf84a#url=${resultUrl}`;
      }
    }
    return link;
  };

  favLink = (hotel, room, request) => {
    return `https://betterbusiness.typeform.com/to/JiFI8oH7#url=${hotel.url}&angebot=${room.resultId}&hotel=${hotel.name}&anreise=${request.arrivalDate}&abreise=${request.departureDate}&anzahl=${request.numberAdults}&verpflegung=${room.kindOfCatering}&transfer=${request.transfer}&plz=${request.transferIndex}`;
  };

  static calcScrollOffset = () => {
    if (isDesktop()) {
      return 0;
    } else if (isTablet()) {
      return 0;
    } else {
      return 0;
    }
  };

  openMap = fn => {
    if (!window.google || !window.google.maps || !window.google.maps.Map) {
      const script = document.createElement('script');
      script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBP2Wg_2o0FbZe2n5YZ_TT4W6Y9GpjYwmU&callback=initMap';
      script.async = true;
      document.body.appendChild(script);
      script.addEventListener('load', ev => {
        fn();
      });
    } else {
      fn();
    }
  };

  showTransfer = () => {
    this.setState(prev => (prev.hotelRooms.selectedHotel.request.transfer = true));
  };

  render() {
    const {selectedHotel} = this.state.hotelRooms;
    if (!Object.keys(selectedHotel).length) {
      return this.state.hotelFetching ? (
        <Preloader />
      ) : (
        <p className='empty-search'>
          <I18n t='Bad request' />
        </p>
      );
    }

    const renderSlides = () => {
      const media = hotel.media || [];
      const querySize = returnPicSize('hotelMainSlider', window.innerWidth);
      return media.map((url, i) => (
        <div key={i} className='slider-item'>
          <img className='main-img' alt='hotelImg' src={`${url}${querySize}`} />
        </div>
      ));
    };

    const {content = [], hotel, request} = selectedHotel;
    const {hotelFetching, roomsFetching, photoIndex, isOpen} = this.state;
    const settings = {
      infinite: true,
      lazyLoad: 'ondemand',
      slidesToShow: 1,
    };
    const mainFields = hotel.mainFields || {};
    const hotelAttributes = hotel.attributes || [];
    const hotelIndications = hotel.indications || [];
    const images = hotel.media || [];
    const locationDescription = content[0]?.locationDescription;
    const {
      checkIn,
      checkInFrom,
      checkOut,
      checkOutTill,
      parkingExists,
      parkingFee,
      parkingPrice,
      kurFeeExists,
      kurFeeFreeAdults,
      kurFeeFreeAdultsFrom,
      kurFeeFreeChildren,
      kurFeeFreeChildrenTill,
      kurFeePrice,
      kurFeeType,
    } = hotel;
    const hotelLink = this.returnCopyLink(hotel.url);
    const scrollOffset = 180;
    const affixOffset = 36;
    const rooms = [...content];

    const payments = hotelAttributes.map(elem => (elem.area === 'payments' ? elem.label : null)).filter(elem => elem);

    return (
      <section className='hotel-rooms offer' itemScope itemType='http://schema.org/Hotel'>
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({isOpen: false})}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
        {hotelFetching ? (
          <Preloader />
        ) : (
          <Fragment>
            <Affix className='fixedNav' offsetTop={0}>
              <Navigation isDesktop={isDesktop()} isOffer={true} />
            </Affix>
            <div className='offer-wrapper' id='offer-wrapper'>
              <div className='offer-left'>
                <div className='slider-container'>
                  <OfferCard featured={hotel.featured} />
                  <Slider settings={settings} wrapperClass='mobile-gallery room-view' renderSlides={renderSlides} />
                  <div className='images-count' onClick={() => this.setState({isOpen: true})}>
                    <i className='custom-icon gallery' />
                    Alle {images.length} Fotos ansehen
                  </div>
                </div>
                {!isMobileOnly && (
                  <div className='offer-hotel-name'>
                    <a href={hotelLink} target='_blank'>
                      <HotelName name={hotel.name} stars={hotel.stars} />
                    </a>
                    <p className='description-address'>
                      <HotelAddress reduceForMobile hotel={hotel} renderType={RENDER_TYPES.FULL} />
                      <span
                        className='location-wrapper'
                        onClick={this.openMap.bind(
                          this,
                          this.openModal.bind(this, Map, {
                            lat: hotel.lat,
                            lng: hotel.lng,
                            name: hotel.name,
                            stars: hotel.stars,
                            address: hotel.street + ' ' + hotel.number + ', ' + hotel.city + ', ' + hotel.country,
                          })
                        )}
                      >
                        <Pointer className='map-pointer' />
                        <I18n class='card-pointer-txt' style={{cursor: 'pointer'}} t='Auf der Karte anzeigen' />
                      </span>
                    </p>
                    <div className='offer-hotel-services'>
                      <ServiceIcons service={hotel.mainFields || {}} />
                    </div>
                  </div>
                )}
                {!isMobileOnly && (
                  <div className='offer-room-amenities-total'>
                    <div className='offer-room-title'>
                      <I18n t={rooms[0].roomName} className='offer-room-amenities-title' />
                      <span>
                        <RoomDescriptionLink
                          hotel={hotel}
                          room={rooms[0]}
                          openRoomModal={this.openModal}
                          modal={RoomOptions}
                          roomAmenities={rooms[0].roomAmenities}
                          classModal='room-modal'
                          classIcon='custom-icon sm-md check green'
                        />
                      </span>
                    </div>
                    {rooms[0].media.length > 1 ? (
                      <div className='offer-room-preview-images offer-room-preview-images-grid'>
                        {rooms[0].media.map((url, i) => {
                          if (i <= 2) {
                            return (
                              <div key={i} className='offer-room-image'>
                                <img alt='roomImg' src={url} />
                              </div>
                            );
                          }
                          return null;
                        })}
                      </div>
                    ) : (
                      <div className='offer-room-preview-images'>
                        <img alt='roomImg' src={rooms[0].media[0]} />
                      </div>
                    )}
                    <div className='wrapper-txt'>
                      <div className='offer-room-services-block'>
                        <ServiceIcons
                          roomAmenities={rooms[0].roomAmenities}
                          checkOptionsInRoom
                          shownIcName
                          classContainer='offer-service-wrapper'
                          wrapperClass='offer-service'
                          roomSize={rooms[0].roomSize}
                          bedSize={rooms[0].bedSize}
                        />
                      </div>
                      <div className='offer-room-amenities'>
                        <I18n t={'Informationen über Ihre Unterkunft:'} className='offer-room-amenities-title' />
                        <ul className='offer-room-amenities-list'>
                          <li>
                            <SpyLink spy={true} smooth={true} delay={0} duration={800} offset={0} to='descriptionTab'>
                              <I18n t='Hotelbeschreibung' />
                            </SpyLink>
                          </li>
                          <li>
                            <SpyLink spy={true} smooth={true} delay={0} duration={800} offset={0} to='indicationsTab'>
                              <I18n t='Indikationen' />
                            </SpyLink>
                          </li>
                          <li>
                            <SpyLink spy={true} smooth={true} delay={0} duration={800} offset={0} to='treatmentTab'>
                              <I18n t='Behandlungen' />
                            </SpyLink>
                          </li>
                          <li>
                            <SpyLink spy={true} smooth={true} delay={0} duration={800} offset={0} to='rulesTab'>
                              <I18n t='Hotelrichtlinien' />
                            </SpyLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className='offer-right'>
                <div id='hotel-rooms'>
                  {rooms.length > 0 && !roomsFetching && (
                    <OfferRoom
                      room={{...rooms[0]}}
                      copyTxt={this.copyTxt.bind(this, this.returnCopyLink(hotel.url, rooms[0].resultId), hotel.id, rooms[0].resultId)}
                      printLink={this.openLink(hotel.url, rooms[0].resultId, false, true)}
                      emailLink={this.openLink(hotel.url, rooms[0].resultId, true, false)}
                      questionLink={this.openLink(hotel.url, rooms[0].resultId, false, false)}
                      favouriteLink={this.favLink(hotel, rooms[0], request)}
                      request={request}
                      hotel={hotel}
                      checkDateInputs={this.checkDateInputs}
                      activeTransfer={this.showTransfer.bind(this, request)}
                    />
                  )}

                  {!rooms.length && <EmptyResult />}

                  {rooms.length > 0 && roomsFetching && <Preloader showSpinner={false} />}
                </div>
              </div>
            </div>
          </Fragment>
        )}

        <div className='hotels-search-wrapper'>
          <section className='hotels-search-block'>
            <section className='tabs-slider-block'>
              <div className='tabs-slider-content' id='descriptionTab'>
                {locationDescription && (
                  <Fragment>
                    <h3>
                      <I18n t='Allgemeine Beschreibung des Hotels' />
                    </h3>
                    <div className='collapse-box'>
                      <span>{ReactHtmlParser(locationDescription)}</span>
                    </div>
                  </Fragment>
                )}
                <div className='hotel-attributes'>
                  <h3 className='block-header'>
                    <I18n t='Service & Austattung' />
                  </h3>
                  <div className='services-block service-icons'>
                    <ServiceIcons service={hotel.mainFields || {}} shownIcName classContainer='service-row' />
                  </div>
                  <div className='services-block with-description'>
                    <div className='service-row'>
                      {hotelAttributes.map((elem, i) =>
                        elem.area === 'equipment' || elem.area === 'service' ? (
                          <div key={i}>
                            <i className='custom-icon sm-md check green' />
                            <span>{elem.label}</span>
                          </div>
                        ) : null
                      )}
                    </div>
                    <span className='tab-description'>{ReactHtmlParser(hotel.serviceDescription)}</span>
                    <h3 className='hotel-languages'>
                      <I18n t='Personal spricht folgende Sprachen' />
                    </h3>
                    <div className='service-row'>
                      {hotelAttributes.map((elem, i) =>
                        elem.area === 'hotel desk speaking' ? (
                          <div key={i}>
                            <i className='custom-icon sm-md check green' />
                            <span>{elem.label}</span>
                          </div>
                        ) : null
                      )}
                    </div>
                  </div>
                </div>
                <div className='hotel-attributes'>
                  <h3 className='block-header'>
                    <I18n t='service.welness_spa' />
                  </h3>
                  <div className='services-block with-description'>
                    <div className='service-row'>
                      {hotelAttributes.map((elem, i) =>
                        elem.area === 'wellness' ? (
                          <div key={i}>
                            <i className='custom-icon sm-md check green' />
                            <span>{elem.label}</span>
                          </div>
                        ) : null
                      )}
                    </div>
                    <span className='tab-description'>{ReactHtmlParser(hotel.wellnessDescription)}</span>
                  </div>
                </div>
                <div className='hotel-attributes'>
                  <h3 className='block-header'>
                    <I18n t='service.leisure_sports' />
                  </h3>
                  <div className='services-block with-description'>
                    <div className='service-row'>
                      {hotelAttributes.map((elem, i) =>
                        elem.area === 'leisure' ? (
                          <div key={i}>
                            <i className='custom-icon sm-md check green' />
                            <span>{elem.label}</span>
                          </div>
                        ) : null
                      )}
                    </div>
                    <span className='tab-description'>{ReactHtmlParser(hotel.leisureDescription)}</span>
                  </div>
                </div>
                <div className='hotel-attributes'>
                  <h3 className='block-header'>
                    <I18n t='Verpflegung' />
                  </h3>
                  <div className='services-block with-description'>
                    <div className='service-row'>
                      {hotelAttributes.map((elem, i) =>
                        elem.area === 'catering' ? (
                          <div key={i}>
                            <i className='custom-icon sm-md check green' />
                            <span>{elem.label}</span>
                          </div>
                        ) : null
                      )}
                    </div>
                    <span className='tab-description'>
                      <HotelFood
                        breakfast={{
                          select: hotel.breakfastSelect,
                          from: hotel.breakfastFrom,
                          to: hotel.breakfastTill,
                        }}
                        lunch={{
                          select: hotel.lunchSelect,
                          from: hotel.lunchFrom,
                          to: hotel.lunchTill,
                        }}
                        dinner={{
                          select: hotel.mealSelect,
                          from: hotel.mealFrom,
                          to: hotel.mealTill,
                        }}
                      />
                    </span>
                    <span className='tab-description'>{ReactHtmlParser(hotel.cateringDescription)}</span>
                  </div>
                </div>
              </div>
              <div className='tabs-slider-content' id='rulesTab'>
                <h3>
                  <I18n t='Hotelrichtlinien ' />
                </h3>
                <div className='grid-container'>
                  {checkIn && (
                    <Fragment>
                      <div className='right'>
                        <I18n class='title' t='Check-in' />
                      </div>
                      <div className='left time-line'>
                        <TimeLine
                          result={PERCENT - (PERCENT * checkInFrom) / DAYS_HOURS}
                          resultFigure={`ab ${checkInFrom}:00 Uhr`}
                          timeZones={['06.00', '12.00', '18.00', '']}
                          wrapperClass='from-right'
                        />
                      </div>
                    </Fragment>
                  )}
                  {checkOut && (
                    <Fragment>
                      <div className='right'>
                        <I18n class='title' t='Check-out' />
                      </div>
                      <div className='left'>
                        <TimeLine result={(PERCENT * checkOutTill) / DAYS_HOURS} resultFigure={`bis ${checkOutTill}:00 Uhr`} timeZones={['06.00', '12.00', '18.00', '']} wrapperClass='from-left' />
                      </div>
                    </Fragment>
                  )}
                  <div className='right'>
                    <I18n class='title' t='Zahlungsmöglichkeiten vor Ort' />
                  </div>
                  <div className='left payments-block'>
                    Sie können vor Ort in der Unterkunft mit den folgenden Zahlungsmitteln bezahlen:
                    <div className='payments'>
                      {payments.map(payment => (
                        <div className='payment'>
                          {paymentImgs[payment] && paymentImgs[payment]}
                          <span>{payment}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className='right'>
                    <I18n class='title' t='Kurtaxe' />
                  </div>
                  <div className='left'>
                    <p className='tourist-tax'>
                      <I18n
                        t={`Die Kurtaxe (auch als Tourismusabgabe, Bettensteuer, Ortstax, Citytax bekannt) muss direkt vor Ort im Kurhotel
                              (umgerechnet von der Landeswährung ca. ${kurFeeExists ? `${convertToMoney(kurFeePrice)}` : '0'} ${
                          !!kurFeeType ? translate('modal.fee.per_stay') : translate('modal.fee.per_day')
                        })
                               entrichtet werden.`}
                      />
                    </p>
                    {!!kurFeeFreeChildren && (
                      <p>
                        <I18n t='modal.fee.children_free' /> {kurFeeFreeChildrenTill} <I18n t='modal.fee.age' />
                      </p>
                    )}
                    {!!kurFeeFreeAdults && (
                      <p>
                        <I18n t='modal.fee.adults_free' /> {kurFeeFreeAdultsFrom} <I18n t='modal.fee.age' />
                      </p>
                    )}
                  </div>
                  <div className='right'>
                    <I18n class='title' t='common.cancellation_policy' />
                  </div>
                  <div className='left'>{request && <CancelationOption hotelPage arrivalDate={request.arrivalDate} options={hotel.cancellationFees} />}</div>
                  <div className='right'>
                    <I18n class='title' t='Kinderermäßigung' />
                  </div>
                  <div className='left'>
                    <AgeDiscounts ageDiscounts={hotel.ageDiscounts} />
                  </div>
                  <div className='right'>
                    <I18n class='title' t='Haustiere' />
                  </div>
                  <div className='left'>
                    {hotel.mainFields?.noAnimals ? 'Keine Haustiere erlaubt' : ''}
                    {hotel.mainFields?.animals ? 'Kleine Haustiere auf Anfrage und gegen Zuzahlung vor Ort möglich. Gebühr: zwischen 12€ und 35 € (je nach Saison) pro Haustier/Nacht' : ''}
                  </div>
                  <div className='right'>
                    <I18n class='title' t='Parkplatz' />
                  </div>
                  <div className='left'>
                    {parkingExists ? (
                      parkingFee > 0 ? (
                        <I18n t={`Parkplätze vorhanden. Parkgebühr ca. ${parkingPrice}€ pro Tag (je nach Verfügbarkei, Stellplatz-Vorreservierung ist nicht möglich)`} />
                      ) : (
                        <I18n t='Parkplätze vorhanden. (je nach Verfügbarkei, Stellplatz-Vorreservierung ist nicht möglich) ' />
                      )
                    ) : (
                      <I18n t='Keine Parkplätze vorhanden. Informationen über Parkmöglichkeiten erhalten Sie der Hotelrezeption.' />
                    )}
                  </div>
                </div>
              </div>
              <div className='tabs-slider-content' id='treatmentTab'>
                <h3> Folgende Behandlungen werden angeboten:</h3>
                <p className='description-txt' style={{fontSize: '15px'}}>
                  Bitte beachten Sie, dass einige Anwendungen nicht zum Umfang des ausgewählten Kurpaketes gehören können und nur gegen Aufpreis buchbar sind. Mehr Informationen erhalten Sie während
                  der ärztlichen Antrittsuntersuchung oder an der Medical Rezeption bzw. Behandlungszentrum.
                </p>
                <div className='services-block'>
                  <div className='service-row'>
                    {hotelIndications.map((elem, i) =>
                      elem.type === 2 ? (
                        <div key={i}>
                          <i className='custom-icon sm-md check green' />
                          <span>{elem.label}</span>
                        </div>
                      ) : null
                    )}
                  </div>
                  <RoomNotice wrapperClass='no-border' />
                </div>
              </div>
              <div className='tabs-slider-content bordered' id='indicationsTab'>
                <div>
                  <h3>
                    <I18n t='Indikationen' />
                  </h3>
                </div>
                <div className='services-block size-3'>
                  <div className='service-row'>
                    {hotelIndications.map((elem, i) =>
                      elem.type === 0 ? (
                        <div key={i}>
                          <i className='custom-icon sm-md check green' />
                          <span>{elem.label}</span>
                        </div>
                      ) : null
                    )}
                  </div>
                </div>
                <div>
                  <h3>
                    <I18n t='Kontraindikationen' />
                  </h3>
                </div>
                <div className='services-block size-3'>
                  <div className='service-row'>
                    {hotelIndications.map((elem, i) =>
                      elem.type === 1 ? (
                        <div key={i}>
                          <i className='custom-icon sm-md prevent' />
                          <span>{elem.label}</span>
                        </div>
                      ) : null
                    )}
                  </div>
                </div>
              </div>
            </section>
            {/*{(!hotelFetching && content.length > 0) && <PaginationBlock*/}
            {/*  totalPages={selectedHotel.totalPages}*/}
            {/*  // scrollId="#hotel-rooms"*/}
            {/*  page={selectedHotel.number}*/}
            {/*  sort={selectedHotel.sort}*/}
            {/*  // size={selectedHotel.size}*/}
            {/*  size={PAGE_SIZE}*/}
            {/*  changePage={this.changePage}*/}
            {/*  hasNext={!selectedHotel.last}*/}
            {/*  hasPrev={!selectedHotel.first}*/}
            {/*/>}*/}
          </section>
        </div>
        <EmployeeLgBoard />
        {/*  //todo*/}
        {/*<section className="hotels-cards-block">*/}
        {/*  <HomeGallery description={false} headerTitle="Ähnliche Kur-Hotels in dieser Region"/>*/}
        {/*</section>*/}
      </section>
    );
  }
}

export default HotelOffer;
