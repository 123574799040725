import moment from 'moment';
import * as Scroll from 'react-scroll/modules';
import {DATE_FORMAT, OPTION_DATE_FORMAT, PIC_SIZES} from '../config/const';
import {isTablet as deviceIsTablet} from 'react-device-detect';

export const returnPicSize = (block, screenWidth) => {
  const tabletView = 1235;
  const mobileView = 1000;
  let device;
  if (screenWidth > tabletView) {
    device = 'pc';
  } else if (screenWidth <= tabletView && screenWidth > mobileView) {
    device = 'tablet';
  } else {
    //mobile
    device = 'mobile';
  }
  const params = PIC_SIZES[block][device];
  return params ? `?${params}` : '';
};
export const calcNights = (from, to) => moment(to, DATE_FORMAT).diff(moment(from, DATE_FORMAT), 'days');
export const removeMetaData = () => {
  document.title = 'Booksana';
};

export const setMetaData = (seoTitle = 'Booksana') => {
  document.title = seoTitle;
};

export const isDesktop = () => window.innerWidth > 1235;

export const isTablet = () => (window.innerWidth > 1000 && window.innerWidth < 1235) || deviceIsTablet;

export const isMobile = () => window.innerWidth < 768;

export const addDiscount = (discount, price) => {
  return Number(price) - Number(discount);
};

export const capitalize = s => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const removeHtmlTxt = (str = '') => {
  return str.replace(/<[^>]*>/g, '').trim();
};

export const getSearchFormOffsetTop = id => {
  const elem = document.querySelector(id);
  if (elem) {
    const rect = elem.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return rect.top + scrollTop;
  }
};
export const setElemShadow = id => {
  const elem = document.querySelector(id);
  elem && (elem.style.boxShadow = '0 0 0 2px #e93208');
};
export const removeElemShadow = id => {
  const elem = document.querySelector(id);
  elem && (elem.style.boxShadow = 'none');
};
export const doSmoothScroll = (id, offset = 0) => {
  const scrollTo = getSearchFormOffsetTop(id);
  Scroll.animateScroll.scrollTo(scrollTo - offset, {
    duration: 800,
    delay: 0,
  });
};

export const returnOptionsArray = optionsObj =>
  Object.keys(optionsObj).reduce((acc, currKey) => {
    const activeOptionsCount = optionsObj[currKey].options.filter(option => option.isActive).length;
    activeOptionsCount > 0 && acc.push({id: currKey, count: activeOptionsCount});
    return acc;
  }, []);

export const concatDateStrings = str => moment(str, DATE_FORMAT);

export const returnDateFromObj = obj => {
  return moment(`${obj.dayOfMonth}-${obj.monthValue}-${obj.year}`, OPTION_DATE_FORMAT);
};
export const returnObligatoryArr = (arr, request) => {
  return arr.filter(elem => !elem.optional).filter(option => isIncludedInStayPeriod(option.start, option.end, request));
};

export const getSundayByOrderNumber = sundayOrderNumber => {
  const weekDays = 7;
  const daysPeriod = sundayOrderNumber * weekDays;
  const currentDayOrderNumber = moment().isoWeekday();

  if (currentDayOrderNumber === 7) {
    return moment().add(daysPeriod, 'days');
  }

  const daysForSundayOrderNumber = daysPeriod - currentDayOrderNumber;
  return moment().add(daysForSundayOrderNumber, 'days');
};

export const getNearestSeventhSunday = () => {
  const maxPeriodForSecondSunday = 49;
  const daysForSecondSunday = maxPeriodForSecondSunday - moment().isoWeekday();
  if (daysForSecondSunday === 7) {
    return moment().add(maxPeriodForSecondSunday, 'days');
  }
  return moment().add(daysForSecondSunday, 'days');
};

export const isIncludedInStayPeriod = (start, end, request) => {
  if (!request.arrivalDate || !request.departureDate) {
    return false;
  }
  const startDate = returnDateFromObj(start).startOf('day');
  const endDate = returnDateFromObj(end).endOf('day');

  const arrivalDate = moment(request.arrivalDate, DATE_FORMAT).startOf('day').add(1, 'minutes');
  const departureDate = moment(request.departureDate, DATE_FORMAT).endOf('day').subtract(1, 'minutes');

  return arrivalDate.isBetween(startDate, endDate) || departureDate.isBetween(startDate, endDate) || startDate.isBetween(arrivalDate, departureDate) || endDate.isBetween(arrivalDate, departureDate);
};

export const convertToMoney = num => {
  let number = num;
  if (typeof number !== 'number') {
    number = Number(number);
    if (isNaN(number)) {
      return num;
    }
  }
  return number.toLocaleString('de-DE', {style: 'currency', currency: 'EUR'});
};
