import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import PopupStore from './store/PopupStore';
import {RouterStore} from 'mobx-react-router';
import SearchStore from './store/SearchStore';
import HotelRoomsStore from './store/HotelRoomsStore';
import SearchFiltersStore from './store/SearchFiltersStore';
import OrderStore from './store/OrderStore';

import 'moment/locale/de.js';
import ContentStore from './store/ContentStore';
import {EmptyResult} from './components/helpers/emptyResult/EmptyResult';
import {LoggerStore} from './store/LoggerStore';

const loadFlags = {
  contentStore: false,
  searchStore: false,
};

const waitLoader = storeName => {
  return status => {
    if (status) {
      loadFlags[storeName] = true;
      if (Object.values(loadFlags).filter(f => !f).length === 0) {
        startApp();
      }
    } else {
      ReactDOM.render(<EmptyResult header='Server side error' txt='We are trying to solve this problem' />, document.getElementById('root'));
    }
  };
};

const contentStore = new ContentStore(waitLoader('contentStore'));
const searchStore = new SearchStore(waitLoader('searchStore'));
const popupStore = new PopupStore();
const routingStore = new RouterStore();
const hotelRoomsStore = new HotelRoomsStore();
const searchFiltersStore = new SearchFiltersStore();
const orderStore = new OrderStore();
const loggerStore = new LoggerStore();

const stores = {
  popup: popupStore,
  orderStore: orderStore,
  searchFilters: searchFiltersStore,
  routing: routingStore,
  searchStore: searchStore,
  hotelRooms: hotelRoomsStore,
  contentStore: contentStore,
  loggerStore: loggerStore,
};

const startApp = () => {
  ReactDOM.render(<App stores={stores} />, document.getElementById('root'));
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
