import React, {Component, Fragment} from 'react';
import Modal from 'antd/lib/modal';
import {I18n} from '../../helpers/i18n/I18n';
import {ReactComponent as Close} from '../../../images/icons/cross.svg';
import {translate} from '../../../utils/Translater';
import ReactHtmlParser from 'react-html-parser';
import {convertToMoney} from '../../../utils/Utils';

class TaxBill extends Component {
  render() {
    const {modalOpen, closeModal, params = {}} = this.props;
    const {kurFeeExists, kurFeeFreeAdults, kurFeeFreeAdultsFrom, kurFeeFreeChildren, kurFeeFreeChildrenTill, kurFeePrice, kurFeeType, description, name} = params;
    return (
      <div>
        <Modal wrapClassName='vertical-center-modal' className='custom-modal tax-bill-modal' closeIcon={<Close />} visible={modalOpen} onOk={closeModal} onCancel={closeModal} footer={null}>
          {!description ? (
            <Fragment>
              <h3 className='modal-header'>
                <I18n t='modal.fee.tax' />
              </h3>
              <div className='modal-body'>
                <p>
                  <I18n
                    t={`Die Kurtaxe (auch als Tourismusabgabe, Bettensteuer, Ortstax, Citytax bekannt) muss direkt vor Ort im Kurhotel
                 (umgerechnet von der Landeswährung ca. ${kurFeeExists ? `${convertToMoney(kurFeePrice)}` : '0'} ${!!kurFeeType ? translate('modal.fee.per_stay') : translate('modal.fee.per_day')}) 
                 entrichtet werden.`}
                  />
                </p>
                {!!kurFeeFreeChildren && (
                  <p>
                    <I18n t='modal.fee.children_free' /> {kurFeeFreeChildrenTill} <I18n t='modal.fee.age' />
                  </p>
                )}
                {!!kurFeeFreeAdults && (
                  <p>
                    <I18n t='modal.fee.adults_free' /> {kurFeeFreeAdultsFrom} <I18n t='modal.fee.age' />
                  </p>
                )}
              </div>
            </Fragment>
          ) : (
            <div className='present-description'>
              <I18n t='Obligatorische Zusatzleistungen' className='modal-header sm' />
              <I18n t={name} className='name-service' />
              <div className='txt-service'>{ReactHtmlParser(description)}</div>
            </div>
          )}
        </Modal>
      </div>
    );
  }
}

export {TaxBill};
