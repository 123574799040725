import React, {Component} from 'react';
import {isMobile, isTablet} from 'react-device-detect';

import {EmployeeCard} from '../employeeCard/EmployeeCard';
import ReactHtmlParser from 'react-html-parser';
import {inject} from 'mobx-react';
import {Affix} from 'antd';
import {isDesktop} from '../../../utils/Utils';

@inject('contentStore')
export class FooterTxtBlock extends Component {
  renderMenu = () => {
    const {hasOwnMenu, menuContent} = this.props;
    if (hasOwnMenu) {
      return menuContent ? <nav className='footer-menu'>{ReactHtmlParser(menuContent)}</nav> : null;
    } else {
      return <nav className='footer-menu'>{ReactHtmlParser(this.props.contentStore.returnContent('home_menu_content_left', true))}</nav>;
    }
  };

  renderTab = () => {
    const {hasOwnMenu, tabContent} = this.props;
    if (hasOwnMenu) {
      return tabContent ? <div id='content-area'>{ReactHtmlParser(tabContent)}</div> : null;
    } else {
      return <div id='content-area'>{ReactHtmlParser(this.props.contentStore.returnContent('home_content_right', true))}</div>;
    }
  };

  returnEmployeeCard = () => {
    if (isMobile) {
      return <EmployeeCard />;
    }
    const offsetTop = isDesktop() ? 30 : 20;
    const btnAndAndFilterHeight = this.props.savedSearch ? 156 : 0;
    return (
      <Affix offsetTop={offsetTop + btnAndAndFilterHeight}>
        <EmployeeCard />
      </Affix>
    );
  };

  render() {
    return (
      <section className='footer-block'>
        {this.renderMenu()}
        <aside className='left-block'>
          {this.renderMenu()}
          {this.returnEmployeeCard()}
        </aside>
        <section className='right-block'>{this.renderTab()}</section>
      </section>
    );
  }
}
