import React, {Component, Fragment} from 'react';
import moment from 'moment';
import {DATE_FORMAT} from '../../config/const';

const parseDate = date => {
  return moment(date, DATE_FORMAT);
};

export class CancelationOption extends Component {
  calcDate(days) {
    return parseDate(this.props.arrivalDate).subtract(days, 'days');
  }

  showAmount(percent, prefix = '') {
    if (this.props.price) {
      const figure = Math.round((percent / 100) * this.props.price * 100) / 100;
      const result = figure > 1 ? Math.floor(figure) : Math.ceil(figure);
      return prefix + result + '€ ';
    } else {
      return null;
    }
  }

  render() {
    if (!this.props.options || !this.props.options.length) {
      return null;
    }
    if (this.props.arrivalDate) {
      let isHeaderExists = false;
      return (
        <Fragment>
          {this.props.options.map((option, i) => {
            const fromDate = this.calcDate(option.daysBeforeFrom);
            const toDate = this.calcDate(option.daysBeforeTill);
            if (moment().isAfter(toDate)) {
              return null;
            } else if (moment().isAfter(fromDate) && moment().isBefore(toDate)) {
              isHeaderExists = true;
              if (this.props.cancelationPage) {
                return (
                  <div key={i}>
                    <p>Ihre Buchung ist nur kostenpflichtig stornierbar.</p>
                    <p style={{marginBottom: '1rem'}}>
                      Gemäß § 6 unserer Allgemeinen Geschäftsbedingungen (AGB), welche Sie bei Vertragsschluss akzeptiert haben, ist in diesem Falle eine Stornoentschädigung in Höhe von {option.fines}
                      % des Gesamtreisepreises geschuldet.
                    </p>
                    <p>Die Stornoentschädigung beträgt in Ihrem Falle: {this.showAmount(option.fines)}.</p>
                  </div>
                );
              } else if (this.props.hotelPage) {
                return (
                  <div key={i}>
                    <p>Dieses Angebot ist nur kostenpflichtig stornierbar.</p>
                    <p>
                      Im Falle einer Stornierung Sie zahlen eine Stornoentschädigung in Höhe von
                      <strong>{` ${option.fines}`}%</strong> des Gesamtreisepreises.
                    </p>
                    <p> Für spätere Stornierungen gelten folgende Stornobedingungen:</p>
                  </div>
                );
              } else {
                return (
                  <Fragment key={i}>
                    <p className={'cancelInfo cancelInfo-withIcon'}>Dieser Buchung ist innerhalb von 48 Stunden kostenfrei stornierbar.</p>
                    <p key={i}>Anschließend fallen Stornierungskosten in folgender Höhe an: {this.showAmount(option.fines)}.</p>
                  </Fragment>
                );
              }
            } else {
              return (
                <Fragment key={i}>
                  {!isHeaderExists && this.props.cancelationPage && (
                    <div>
                      <p>
                        für diese Buchung fallen keine Stornoentschädigungen bis zu
                        {this.calcDate(this.props.options[0].daysBeforeFrom + 1).format(DATE_FORMAT)} an.
                      </p>
                      <p>Für spätere Stornierungen gelten die in § 6 unserer AGB vereinbarten Stornobedingungen:</p>
                      {(isHeaderExists = true)}
                    </div>
                  )}
                  {i === 0 && !this.props.cancelationPage && (
                    <Fragment>
                      <p className='cancelInfo-bold'>Bis zu {this.calcDate(this.props.options[0].daysBeforeFrom + 1).format(DATE_FORMAT)} fallen keine Stornokosten an.</p>
                      <p>Für spätere Stornierungen gelten folgende Stornobedingungen:</p>
                    </Fragment>
                  )}
                  <p className={this.props.hotelPage ? 'cancelation-fee' : ''}>
                    vom {fromDate.format(DATE_FORMAT)} bis {toDate.format(DATE_FORMAT)}:{i < this.props.options.length ? ` ${option.fines}% ` : `sowie bei Nichtantritt: ${option.fines}%`}
                    des Reisepreises {this.showAmount(option.fines, '= ')}
                  </p>
                </Fragment>
              );
            }
          })}
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <p>Bei einer Stornierung {this.props.options[0].daysBeforeFrom + 1} Tage vor Reisebeginn fallen keine Stornokosten an.</p>
          <p className='cancellation-terms'>Für spätere Stornierungen gelten folgende Stornobedingungen:</p>
          {this.props.options.map((option, i) => {
            return (
              <p className='cancelation-fee' key={i}>
                vom <span className='days-container'>{option.daysBeforeFrom}</span> bis
                <span className='days-container'>{option.daysBeforeTill}</span> Tage vor Reisebeginn:
                <span className='row-with-percent'>
                  <span>{i < this.props.options.length ? `${option.fines}%` : `sowie bei Nichtantritt: ${option.fines}%`}</span>
                  des Reisepreises
                </span>
              </p>
            );
          })}
        </Fragment>
      );
    }
  }
}
