import React, {Component} from 'react';
import {I18n} from '../../helpers/i18n/I18n';
import {inject, observer} from 'mobx-react';
import {Price} from '../../helpers/price/Price';
import {getText} from '../../../utils/Fetcher';
import {Preloader} from '../../helpers/preloader/Preloader';
import ReactHtmlParser from 'react-html-parser';
import {BadRequest} from '../../helpers/badRequest/BadRequest';

@inject('orderStore')
@observer
export class OrderSuccess extends Component {
  state = {
    pageContent: '',
    isFetching: false,
  };

  componentDidMount() {
    const {order} = this.props.orderStore;

    this.setState({isFetching: true});
    getText('thankyou_page').then(resp => {
      let htmlTxt = '';
      if (!resp.isError) {
        htmlTxt = resp.data.value;
      }
      this.setState({isFetching: false, pageContent: htmlTxt});
    });

    if (gtag && order) {
      try {
        gtag('event', 'purchase', {
          transaction_id: order.bookingId,
          value: order.price * 0.15,
          currency: 'EUR',
          // coupon: "SUMMER_SALE",
          items: [
            // If someone purchases more than one item,
            // you can add those items to the items array
            {
              item_id: order.id,
              item_name: order.name,
              // affiliation: "Google Merchandise Store",
              // coupon: "SUMMER_FUN",
              // discount: 2.22,
              // index: 0,
              // item_brand: "Google",
              item_category: 'Hotel',
              // item_category2: "Adult",
              // item_category3: "Shirts",
              // item_category4: "Crew",
              // item_category5: "Short sleeve",
              // item_list_id: "related_products",
              // item_list_name: "Related Products",
              // item_variant: "green",
              // location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
              price: order.price * 0.15,
              quantity: 1,
            },
          ],
        });
        gtag('event', 'begin_checkout', {
          currency: 'EUR',
          value: 5.0,
        });
      } catch (e) {
        console.error(e);
      }
    }
  }

  componentWillUnmount() {
    this.props.orderStore.deleteOrder();
  }

  render() {
    const {isFetching, pageContent} = this.state;
    const {order} = this.props.orderStore;
    return (
      <section className='payment-result-page'>
        <article className='cancel-card order-done'>
          <div className='left-section'>
            <h2>
              <I18n t='Ihre Reservierungsdetails' />
            </h2>
            <h3>
              <I18n t='Reservierungsnummer:' />
            </h3>
            <p>
              <I18n t={`${order.bookingId || 'XXXX'} vom ${order.createdAt || 'xx.xx.xxxx'}`} />
            </p>
            <h3>
              <I18n t='Hotel:' />
            </h3>
            <p>
              <I18n t={`${order.name || '[hotelName]'}`} />
            </p>
            <h3>
              <I18n t='Anreisetermin:' />
            </h3>
            <p>
              <I18n t={`Datum von ${order.arrivalDate || ''} - bis ${order.departureDate || ''} / Übernachtungen: ${order.nights || 'x'}`} />
            </p>
            <h3 className='mr'>
              <I18n t='Gesamtpreis: ' />
              <span>{order.price ? <Price price={order.price} /> : <I18n t='Preis Gesamt' />}</span>
            </h3>
            <h3>
              <I18n t='Zahlungsweise:' />
            </h3>
            {order.payment_type === 'card' && (
              <p>
                <I18n t='Kreditkarte' />
              </p>
            )}
            {order.payment_type === 'sepa_debit' && (
              <p>
                <I18n t='Lastschrift' />
              </p>
            )}
            {order.payment_type === 'bill' && (
              <p>
                <I18n t='Rechnung' />
              </p>
            )}
          </div>
          <div className='right-section details'>
            {isFetching ? <Preloader /> : <div className='sections-txt'>{pageContent ? ReactHtmlParser(pageContent) : <BadRequest />}</div>}
            {/*<h2 className="color"><I18n t="Vielen Dank für Ihre verbindliche Reservierung bei booksana.com."/><I18n t="Wir freuen uns sehr über Ihr Vertrauen!"/></h2>*/}
            {/*<p className="info-text">*/}
            {/*  <I18n t={`Wir haben Ihre Reservierung an das ${order.name} vermittelt. Ihre Reservierungbefindet sich nun in der Bearbeitung`}/>*/}
            {/*</p>*/}
            {/*<p className="info-text">*/}
            {/*  <I18n*/}
            {/*      t="Das Hotelwird umgehend für Sie eine Zimmerverfügbarkeit prüfen,um Ihre Reser-vierung schnellstmöglich zu bestätigen. Nach einer kurzen Prüfung (max. 24 Stunden) wird Ihnen an Ihre E-Maildie verbindliche Buchungsbestätigung zugesandt."/>*/}
            {/*</p>*/}
            {/*<p className="info-text">*/}
            {/*  <I18n*/}
            {/*      t="Sollten Sie innerhalb der nächsten 24 Stunden keine Reservierungsbestätigung erh-alten bzw. finden,schreiben Sie uns bitte eine E-Mail an service@booksana.com oderrufen Sie uns unter 030 - 324 232 707 an"/>*/}
            {/*</p>*/}
          </div>
        </article>
      </section>
    );
  }
}
