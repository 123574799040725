import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {I18n} from '../i18n/I18n';
import classNames from 'classnames';

export class RegionItem extends Component {
  render() {
    const {regionName, hotelsCount, itemClass, path} = this.props;
    return (
      <li className={classNames('region-item', itemClass)}>
        <Link to={`/region/${path}`} target='_blank'>
          <I18n t={regionName} className='title-item' />
          <span className='count-hotels'>{hotelsCount} Hotels</span>
        </Link>
      </li>
    );
  }
}
