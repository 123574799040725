import React, {Component} from 'react';
import {translate} from '../../../utils/Translater';

export class I18n extends Component {
  render() {
    return (
      <span className={this.props.class || ''} onClick={this.props.onClick}>
        {translate(this.props.t)}
      </span>
    );
  }
}
