import React, {Component} from 'react';
import {FooterTxtBlock} from '../../helpers/footerTxtBlock/FooterTxtBlock';
import {I18n} from '../../helpers/i18n/I18n';
import {EmployeeCard} from '../../helpers/employeeCard/EmployeeCard';
import {getText} from '../../../utils/Fetcher';
import {Preloader} from '../../helpers/preloader/Preloader';
import ReactHtmlParser from 'react-html-parser';
import {BadRequest} from '../../helpers/badRequest/BadRequest';
import {STATIC_TXT_KEYS} from '../../../config/const';
import {capitalize, removeMetaData, setMetaData} from '../../../utils/Utils';

export class InfoPage extends Component {
  state = {
    isFetching: false,
    pageContent: '',
  };

  componentDidMount() {
    const urlKey = this.props.match.params.type;
    const seoTitle = capitalize(urlKey);
    setMetaData(seoTitle ? `${seoTitle} | Booksana.com` : '');
    const txtKey = STATIC_TXT_KEYS[urlKey];
    this.getContent(txtKey);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.match.params.type !== nextProps.match.params.type) {
      const seoTitle = capitalize(nextProps.match.params.type);
      setMetaData(seoTitle ? `${seoTitle} | Booksana.com` : '');
      this.getContent(STATIC_TXT_KEYS[nextProps.match.params.type]);
    }
  }

  componentWillUnmount() {
    removeMetaData();
  }

  getContent = txtKey => {
    if (!txtKey) {
      return;
    }
    this.setState({isFetching: true, pageContent: ''});
    getText(txtKey).then(resp => {
      let htmlTxt = '';
      if (!resp.isError) {
        htmlTxt = resp.data.value;
      }
      this.setState({isFetching: false, pageContent: htmlTxt});
    });
  };

  render() {
    const {isFetching, pageContent} = this.state;
    return (
      <section className='contacts-page'>
        <section className='footer-block'>
          <aside className='left-block'>
            <EmployeeCard />
          </aside>
          <section className='right-block'>{isFetching ? <Preloader /> : <div className='sections-txt'>{pageContent ? ReactHtmlParser(pageContent) : <BadRequest />}</div>}</section>
        </section>
      </section>
    );
  }
}
