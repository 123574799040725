import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {inject, observer} from 'mobx-react';

import {ReactComponent as Logos} from '../../../images/logo.svg';
import Employee from '../../../images/employee.jpg';
import {ReviewsWidget} from '../../mainPages/payment/ReviewsWidget';

@withRouter
@inject('contentStore')
@observer
export class PaymentHeader extends Component {
  goHome = () => {
    this.props.history.push('/');
  };

  render() {
    return (
      <header className='payment-header'>
        <div className='inner-container'>
          <div className='left'>
            <div onClick={this.goHome} className='main-logo'>
              <Logos />
            </div>
            <div className='circle-block-wrapper'>
              <div className='circle-block mini ssl' />
            </div>
          </div>

          <ReviewsWidget scriptUrl='https://www.provenexpert.com/widget/richsnippet.js?u=18zA0Vwol82ZjRmA1HGplOKB48TA1LQB&v=2' />

          <div className='right'>
            <div className='employee-img'>
              <img alt='employee' src={Employee} />
            </div>
            <div className='contact-data'>
              <a href={`tel:${this.props.contentStore.returnContent('phone_number')}`} className='phone-number'>
                <i className='custom-icon-phone bl' />
                {this.props.contentStore.returnContent('phone_number')}
              </a>
              <p className='working-time'>{this.props.contentStore.returnContent('working_time')}</p>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
