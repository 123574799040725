import React from 'react';
import {translate} from '../../../utils/Translater';
import {I18n} from '../i18n/I18n';
import {ReactComponent as QuestionIcon} from '../../../images/icons/question.svg';

export const RoomDescriptionLink = ({roomAmenities, openRoomModal, classModal, classIcon, modal, hotel, room, isIcon = false}) =>
  !!(roomAmenities || []).length ? (
    isIcon ? (
      <span
        class='popup-action'
        onClick={openRoomModal.bind(this, modal, {
          header: translate('common.description'),
          room: room,
          hotel: hotel,
          roomAmenities: roomAmenities,
          classModal: classModal,
          classIcon: classIcon,
        })}
      >
        <QuestionIcon />
      </span>
    ) : (
      <I18n
        t='common.description'
        class='popup-action'
        onClick={openRoomModal.bind(this, modal, {
          header: translate('common.description'),
          room: room,
          hotel: hotel,
          roomAmenities: roomAmenities,
          classModal: classModal,
          classIcon: classIcon,
        })}
      />
    )
  ) : null;
