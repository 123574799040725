import React, {Component} from 'react';
import {I18n} from '../i18n/I18n';
import {RegionItem} from './RegionItem';
import {ReactComponent as ArrowIcon} from '../../../images/icons/arrow-right-line.svg';
import {Link} from 'react-router-dom';

export class RegionsList extends Component {
  returnRegionItem = (elem, i) => {
    const regionsLength = elem.regions.length;
    if (regionsLength > 0) {
      const leftMinElemsLength = 10;
      const lastLeftBlockElem = Math.floor(regionsLength / 2) > leftMinElemsLength ? regionsLength - 1 : leftMinElemsLength;
      return (
        <Link key={i} target='_blank' to={`/region/${elem.cms.slug}`} className='dropdown-item'>
          <p className='country-item'>
            <I18n t={elem.name} className='item-title' />
            <span className='count-hotels'>{elem.hotelsCount} Hotels</span>
          </p>
          <div className='regions-list'>
            <ul className='left-col list-col'>
              {elem.regions.slice(0, lastLeftBlockElem).map((region, i) => (
                <RegionItem path={(region.cms || {}).slug} key={i} regionName={region.name} hotelsCount={region.hotelsCount} />
              ))}
            </ul>
            {regionsLength > leftMinElemsLength && (
              <ul className='right-col list-col'>
                {elem.regions.slice(lastLeftBlockElem, regionsLength).map((region, i) => (
                  <RegionItem path={(region.cms || {}).slug} key={i} regionName={region.name} hotelsCount={region.hotelsCount} />
                ))}
              </ul>
            )}
          </div>
          <ArrowIcon />
        </Link>
      );
    } else {
      return null;
    }
  };

  render() {
    const {regions} = this.props;
    return <div className='dropdown-block'>{regions.map(this.returnRegionItem)}</div>;
  }
}
