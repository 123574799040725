import React, {Component} from 'react';
import {TxtBox} from '../../modals/txtBox/TxtBox';
import {translate} from '../../../utils/Translater';
import {I18n} from '../i18n/I18n';

export class RoomsFeeLink extends Component {
  render() {
    const {price, arrivalDate, openModal, cancellationOptions = [], vendorName} = this.props;
    return !!cancellationOptions.length ? (
      <I18n
        t='common.cancellation_policy'
        class='popup-action'
        onClick={this.props.openModal.bind(this, TxtBox, {
          header: `${translate('common.cancellation_policy')} ${vendorName ? `der ${vendorName}` : ''}`,
          price: price,
          arrivalDate: arrivalDate,
          cancellationOptions: cancellationOptions,
        })}
      />
    ) : null;
  }
}
