import React, {Component, Fragment} from 'react';
import classNames from 'classnames';
import {I18n} from '../i18n/I18n';

import {ReactComponent as Pets} from '../../../images/service/pets-allowed.svg';
import {ReactComponent as Beach} from '../../../images/service/beach.svg';
import {ReactComponent as Gym} from '../../../images/service/gym.svg';
import {ReactComponent as Lift} from '../../../images/service/lift.svg';
import {ReactComponent as Parking} from '../../../images/service/parking.svg';
import {ReactComponent as Pool} from '../../../images/service/pool.svg';
import {ReactComponent as Restaurant} from '../../../images/service/restaurant.svg';
import {ReactComponent as Wellness} from '../../../images/service/wellness-spa.svg';
import {ReactComponent as Wifi} from '../../../images/service/wlan.svg';

import {ReactComponent as Sicher} from '../../../images/service/sicher.svg';
import {ReactComponent as Essen} from '../../../images/service/essen.svg';
import {ReactComponent as LedTv} from '../../../images/service/led.svg';
import {ReactComponent as Wash} from '../../../images/service/waschmaschine.svg';
import {ReactComponent as Area} from '../../../images/service/area.svg';

import {ReactComponent as Conditioner} from '../../../images/service/conditioner.svg';
import {ReactComponent as NoSmoking} from '../../../images/service/no-smoking.svg';
import {ReactComponent as BathroomIcon} from '../../../images/service/bathroom.svg';
import {ReactComponent as ShowerIcon} from '../../../images/service/shower.svg';
import {ReactComponent as BedIcon} from '../../../images/service/bed.svg';

const possibleRoomOptions = [
  {key: 'wi-fi (free)', pic: Wifi, label: 'WLAN'},
  {key: 'flat screen tv', pic: LedTv, label: 'LED-Fernseher'},
  {key: 'air conditioner', pic: Conditioner, label: 'Klimaanlage'},
  {key: 'no smoking room', pic: NoSmoking, label: 'Nichtraucherzimmer'},
  {key: 'bath', pic: BathroomIcon, label: 'Badwanne'},
  {key: 'shower', pic: ShowerIcon, label: 'Dusche'},
];

export class ServiceIcons extends Component {
  render() {
    const {shownIcName, wrapperClass, classContainer, roomSize, bedSize, roomAmenities, checkOptionsInRoom} = this.props;
    const icons = this.props.service;
    return (
      <div className={classNames('circle-container', classContainer)}>
        {checkOptionsInRoom ? (
          <Fragment>
            {roomSize && (
              <div className={wrapperClass}>
                <Area className='custom-svg-icon' />
                {shownIcName && <I18n t={`${roomSize} m²`} />}
              </div>
            )}
            {bedSize && (
              <div className={wrapperClass}>
                <BedIcon className='custom-svg-icon' />
                {shownIcName && <I18n t={`Bett ${bedSize}`} />}
              </div>
            )}
            {possibleRoomOptions
              .filter(option => roomAmenities.findIndex(amenities => amenities.key.toLowerCase() === option.key) >= 0)
              .map(elem => {
                const Pic = elem.pic;
                return (
                  <div className={wrapperClass}>
                    <Pic className='custom-svg-icon' />
                    {shownIcName && <I18n t={elem.label} />}
                  </div>
                );
              })}
          </Fragment>
        ) : (
          <Fragment>
            {icons.wifi && (
              <div className={wrapperClass}>
                <Wifi className='custom-svg-icon' />
                {shownIcName && <I18n t='WLAN' />}
              </div>
            )}
            {icons.ledTV && (
              <div className={wrapperClass}>
                <LedTv className='custom-svg-icon' />
                {shownIcName && <I18n t='LED-Fernseher' />}
              </div>
            )}
            {icons.conditioner && (
              <div className={wrapperClass}>
                <Conditioner className='custom-svg-icon' />
                {shownIcName && <I18n t='Klimaanlage' />}
              </div>
            )}
            {icons.nonSmokingRoom && (
              <div className={wrapperClass}>
                <NoSmoking className='custom-svg-icon' />
                {shownIcName && <I18n t='Nichtraucherzimmer' />}
              </div>
            )}
            {icons.bath && (
              <div className={wrapperClass}>
                <BathroomIcon className='custom-svg-icon' />
                {shownIcName && <I18n t='Badwanne' />}
              </div>
            )}
            {icons.shower && (
              <div className={wrapperClass}>
                <ShowerIcon className='custom-svg-icon' />
                {shownIcName && <I18n t='Dusche' />}
              </div>
            )}
            {(icons.safeSmall || icons.safeBig) && (
              <div className={wrapperClass}>
                <Sicher className='custom-svg-icon' />
                {shownIcName && <I18n t='Sicher' />}
              </div>
            )}
            {icons.washer && (
              <div className={wrapperClass}>
                <Wash className='custom-svg-icon' />
                {shownIcName && <I18n t='Waschmaschine' />}
              </div>
            )}
            {icons.foodIn && (
              <div className={wrapperClass}>
                <Essen className='custom-svg-icon' />
                {shownIcName && <I18n t='Essen im Zimmer' />}
              </div>
            )}
            {icons.animals && (
              <div className={wrapperClass}>
                <Pets className='custom-svg-icon' />
                {shownIcName && <I18n t='service.pets' />}
              </div>
            )}
            {icons.beach && (
              <div className={wrapperClass}>
                <Beach className='custom-svg-icon' />
                {shownIcName && <I18n t='service.beach' />}
              </div>
            )}
            {icons.jim && (
              <div className={wrapperClass}>
                <Gym className='custom-svg-icon' />
                {shownIcName && <I18n t='service.gym' />}
              </div>
            )}
            {icons.parking && (
              <div className={wrapperClass}>
                <Parking className='custom-svg-icon' />
                {shownIcName && <I18n t='service.parking' />}
              </div>
            )}
            {icons.pool && (
              <div className={wrapperClass}>
                <Pool className='custom-svg-icon' />
                {shownIcName && <I18n t='service.pool' />}
              </div>
            )}
            {icons.spa && (
              <div className={wrapperClass}>
                <Wellness className='custom-svg-icon' />
                {shownIcName && <I18n t='service.welness_spa' />}
              </div>
            )}
            {icons.lift && (
              <div className={wrapperClass}>
                <Lift className='custom-svg-icon' />
                {shownIcName && <I18n t='service.lift' />}
              </div>
            )}
            {icons.restaurant && (
              <div className={wrapperClass}>
                <Restaurant className='custom-svg-icon' />
                {shownIcName && <I18n t='service.restaurant' />}
              </div>
            )}
          </Fragment>
        )}
      </div>
    );
  }
}
