import React from 'react';
import {Fragment} from 'preact';

export const RoomNotice = ({wrapperClass, children, style = {}}) => (
  <div style={{...style}} className={`room-notice ${wrapperClass ? wrapperClass : ''}`}>
    {children ? (
      children
    ) : (
      <Fragment>
        <span>*Wichtige Hinweise: </span>
        <span>
          Anzahl und Zusammenstellung der Anwendungen sind von dem aktuellen Gesundheitszustand des Gastes und von der Empfehlung des Arztes vor Ort abhängig. Alle Anwendungen werden nur an Werktagen
          (außer Samstagen, Sonntagen, Feiertagen sowie An- und Abreisetagen) durchgeführt. Im Preis sind keine Anwendungen für Kinder (unter 18 J.) berücksichtigt. Zusätzliche Anwendungen sowie
          weitere Zusatzleistungen können vor Ort hinzugebucht und bezahlt werden.
        </span>
      </Fragment>
    )}
  </div>
);
