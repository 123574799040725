import {isMobile, isMobileOnly} from 'react-device-detect';
import React, {Component, Fragment} from 'react';
import {inject, observer} from 'mobx-react';
import {Affix} from 'antd';

import {doSmoothScroll, isDesktop, isTablet, removeMetaData, setMetaData} from '../../../utils/Utils';
import {GridSuggestions, SORTING_OPTIONS} from '../../helpers/gridSuggestions/GridSuggestions';
import {EmployeeLgBoard} from '../../helpers/employeeLgBoard/EmployeeLgBoard';
import {CollapseList} from '../../helpers/gridSuggestions/CollapseList';
import {BreadCrumbs} from '../../helpers/breadCrumbs/BreadCrumbs';
import {HomeGallery} from '../../helpers/homeGallery/HomeGallery';
import {BadRequest} from '../../helpers/badRequest/BadRequest';
import {SearchForm} from '../../helpers/searchForm/SearchForm';
import TableFilter from '../../modals/tableFilter/TableFilter';
import Sorting from '../../modals/sorting/Sorting';
import {PAGE_SIZE} from '../../../config/const';
import {I18n} from '../../helpers/i18n/I18n';
import {FiltersNavTab} from './FiltersNavTab';

export const FILTER_TYPE = {
  firstMoment: 'first-moment',
  lastMoment: 'last-moment',
  general: 'general',
};

export const FILTER = {
  [FILTER_TYPE.firstMoment]: 1,
  [FILTER_TYPE.lastMoment]: 2,
  [FILTER_TYPE.general]: 3,
};

@inject('searchFilters', 'contentStore', 'popup')
@observer
class HotelSuggestion extends Component {
  state = {
    pagination: null,
    results: [],
    filterAttributes: [],
    fetching: false,
    pagesSort: this.props.hasDiscount || this.props.usePriceSorting ? SORTING_OPTIONS[1].value : SORTING_OPTIONS[0].value,
    total: 0,
    regionAndHotel: [],
    renderDateSelectBtn: this.props.renderDateSelectBtn !== undefined ? this.props.renderDateSelectBtn : true,
    resultsError: false,
  };
  mainForm = React.createRef();

  componentDidMount() {
    setMetaData('Hotel search | Booksana.com');
  }

  componentWillUnmount() {
    this.props.searchFilters.resetFilters();
    removeMetaData();
  }

  onSearchError = () => {
    this.setState({fetching: false, resultsError: true});
  };

  onSearchStart = () => {
    this.setState({fetching: true});
  };

  onSearchFinish = (resp, formState) => {
    let renderDateSelectBtn = true;

    if (formState.departureDate && formState.arrivalDate) {
      renderDateSelectBtn = false;
    }

    this.setState({
      pagination: {
        totalPages: resp.totalPages,
        page: resp.number,
        sort: resp.sort,
        size: resp.size,
        hasNext: !resp.last,
        hasPrev: !resp.first,
      },
      total: resp.totalElements,
      results: resp.content,
      filterAttributes: resp.filterAttributes,
      fetching: false,
      resultsError: false,
      renderDateSelectBtn: this.props.renderDateSelectBtn !== undefined ? this.props.renderDateSelectBtn : renderDateSelectBtn,
      regionAndHotel: formState.destination,
    });
  };

  onDateBtnClick = () => {
    let offset = 5;
    // if (isDesktop()) {   // for promo block
    //   offset = 139;
    // }
    if (isTablet() || isDesktop()) {
      offset = 89;
    }
    doSmoothScroll('#search_start_date', offset);
    this.mainForm.current && this.mainForm.current.checkDateInputs();
    //focus datePicker
  };

  // onBtnClick = (formData) => {
  //   // this.props.searchStore.setFormData(formData);
  // };

  renderHeaderTxt = () => {
    if (this.props.headerKey) {
      return <span>{this.props.contentStore.returnContent(this.props.headerKey)}</span>;
    }
    if (this.mainForm.current) {
      const results = this.state.total;
      const filterOptions = this.mainForm.current.state.filter;
      let resultsCount = results > 0 ? results : '';
      let filterParam = filterOptions.length !== 1 ? '' : `in ${filterOptions[0]}`;
      return (
        <span>
          {resultsCount} <I18n t='Kur-Hotels für Ihre Kur-Reise' /> {filterParam}
        </span>
      );
    }
  };

  startSearch = (page = {}, filters) => {
    if (this.mainForm.current && !this.state.fetching) {
      !page.size && (page.size = PAGE_SIZE);
      this.mainForm.current.search({...page, sort: this.state.pagesSort}, filters);
    }
  };

  changeSelection = (value, property) => {
    this.setState({[property]: value}, () => this.startSearch(undefined, this.props.searchFilters.selectedFilters));
  };

  openModal = (modalCmp, onOk, data) => {
    this.props.popup.open(modalCmp, onOk, null, data);
  };

  static calcBtnOffset = addTopMargin => {
    const topMargin = addTopMargin ? 24 : 0;
    // if (isDesktop()) {     // for promo block
    //   return 115 + topMargin;
    // }
    if (isDesktop() || isTablet()) {
      return 65 + topMargin;
    } else {
      return 0;
    }
  };

  setMobileScrollParams = () => {
    const params = {offset: 0, id: '#main-search-form'};
    // if (!this.mainForm.current || !this.props.certainSearch) {
    //   return params;
    // }
    // if (!this.mainForm.current.checkDateInputs()) {
    //   params.offset = 5;
    //   params.id = '#search_start_date';
    // }
    return params;
  };

  render() {
    const {showTopImg = true, hideElevatorInput, certainSearch, colorWhite, showFooterTxt = true, className, cardType, isFeatured, hasDiscount} = this.props;
    const {regionAndHotel, renderDateSelectBtn, resultsError, pagesSort} = this.state;
    const results = this.state.results || [];
    const isEmpty = results.length === 0;
    // const headerFilterOffset = isDesktop() ? 50 : 0;  // for promo block
    const headerFilterOffset = 0;
    const selectedFilters = (this.props.searchFilters.selectedFilters || []).length;
    const mobScrollParams = this.setMobileScrollParams();
    const sundayOrderNumber = this.props.filter === FILTER_TYPE.firstMoment ? 9 : 2;
    const discountTypeId = FILTER[this.props.filter] || null;
    const pageParams = {size: PAGE_SIZE, sort: this.state.pagesSort};

    return (
      <Fragment>
        {!resultsError && !isMobileOnly && certainSearch && (
          <Affix className='fixed-filters-tab' style={{width: '100%'}} offsetTop={headerFilterOffset}>
            <FiltersNavTab
              renderResetBtn
              fetching={this.state.fetching}
              isEmpty={isEmpty}
              total={this.state.total}
              pagesSort={pagesSort}
              selectedFilters={selectedFilters}
              changeSelection={this.changeSelection}
              removeFilters={this.props.searchFilters.resetFilters.bind(this, this.startSearch)}
              openTableFilter={this.openModal.bind(this, TableFilter, this.startSearch, {
                filterAttributes: this.state.filterAttributes,
              })}
              openSorting={this.openModal.bind(this, Sorting, this.startSearch, {
                pagesSort,
                changeSelection: this.changeSelection,
              })}
            />
          </Affix>
        )}
        <section className={`suggestions-page ${className || ''}`}>
          <section className='hotel-search'>
            {/*['Startseite', regionAndHotel[0]]*/}
            {!certainSearch && Boolean(regionAndHotel.length) && regionAndHotel.length === 1 && (
              <BreadCrumbs
                items={[
                  {
                    link: 'Startseite',
                    src: window.location.origin,
                  },
                  {
                    link: regionAndHotel[0],
                    src: '',
                  },
                ]}
              />
            )}
            {showTopImg && <div className='image-bord' />}
            {!resultsError && !isMobileOnly && !certainSearch && (
              <Affix className='fixed-filters-tab' style={{width: '100%'}} offsetTop={headerFilterOffset}>
                <FiltersNavTab
                  renderResetBtn
                  fetching={this.state.fetching}
                  isEmpty={isEmpty}
                  total={this.state.total}
                  pagesSort={pagesSort}
                  selectedFilters={selectedFilters}
                  changeSelection={this.changeSelection}
                  removeFilters={this.props.searchFilters.resetFilters.bind(this, this.startSearch)}
                  openTableFilter={this.openModal.bind(this, TableFilter, this.startSearch, {
                    filterAttributes: this.state.filterAttributes,
                  })}
                  openSorting={this.openModal.bind(this, Sorting, this.startSearch, {
                    pagesSort,
                    changeSelection: this.changeSelection,
                  })}
                />
              </Affix>
            )}
            <div className='search-form'>
              {/*{!certainSearch && <h1 className={`${showTopImg ? "" : "without-bg"} ${colorWhite ? "" : "default-color"}`}>*/}
              {/*{this.renderHeaderTxt()}*/}
              {/*/!*<I18n t="Kur-Hotels für Ihre Kur-Reise"/> {(Boolean(regionAndHotel.length) && regionAndHotel.length === 1) && <span>{regionAndHotel[0]}</span>}*!/*/}
              {/*</h1>}*/}
              {isMobileOnly && (
                <Fragment>
                  <SearchForm
                    discountTypeId={discountTypeId}
                    useSearchQueryDates={this.props.useSearchQueryDates}
                    history={this.props.history}
                    location={this.props.location}
                    sundayOrderNumber={sundayOrderNumber}
                    maxTagCount={1}
                    dropdownClassName='resize-dropdown'
                    ref={this.mainForm}
                    hideElevatorInput={hideElevatorInput}
                    scrollElemId={mobScrollParams.id}
                    scrollOffset={mobScrollParams.offset}
                    // onBtnClick={this.onBtnClick}
                    showTopTab={false}
                    isFeatured={isFeatured}
                    certainSearch={certainSearch}
                    listenCalendarDirection={true}
                    checkDates={!!certainSearch}
                    hasDiscount={hasDiscount}
                    pageParams={pageParams}
                    filterParams={this.props.searchFilters.selectedFilters}
                    onSearchStart={this.onSearchStart}
                    onSearchError={this.onSearchError}
                    onSearchFinish={this.onSearchFinish}
                    removeDateShadow
                    makeBtnFixed={false}
                  />
                  {/*<Affix className='fixed-filters-tab' style={{width: '100%'}} offsetTop={80}>*/}
                  {/*  <FiltersNavTab*/}
                  {/*    fetching={this.state.fetching}*/}
                  {/*    isEmpty={isEmpty}*/}
                  {/*    total={this.state.total}*/}
                  {/*    pagesSort={pagesSort}*/}
                  {/*    selectedFilters={selectedFilters}*/}
                  {/*    changeSelection={this.changeSelection}*/}
                  {/*    removeFilters={this.props.searchFilters.resetFilters.bind(this, this.startSearch)}*/}
                  {/*    openTableFilter={this.openModal.bind(this, TableFilter, this.startSearch, {*/}
                  {/*      filterAttributes: this.state.filterAttributes,*/}
                  {/*    })}*/}
                  {/*    openSorting={this.openModal.bind(this, Sorting, this.startSearch, {*/}
                  {/*      pagesSort,*/}
                  {/*      changeSelection: this.changeSelection,*/}
                  {/*    })}*/}
                  {/*  />*/}
                  {/*</Affix>*/}
                </Fragment>
              )}
              {!resultsError ? (
                <div id='searchResultTable' className='grid-suggestions'>
                  <div className='suggestions-options'>
                    {!isMobileOnly && (
                      <SearchForm
                        discountTypeId={discountTypeId}
                        useSearchQueryDates={this.props.useSearchQueryDates}
                        history={this.props.history}
                        location={this.props.location}
                        sundayOrderNumber={sundayOrderNumber}
                        maxTagCount={1}
                        dropdownClassName='resize-dropdown'
                        ref={this.mainForm}
                        wrapperClass='left-alignment'
                        hideElevatorInput={hideElevatorInput}
                        scrollElemId='#searchResultTable'
                        scrollOffset={HotelSuggestion.calcBtnOffset(true)}
                        // onBtnClick={this.onBtnClick}
                        showTopTab={false}
                        calendarIsRight={false}
                        isFeatured={isFeatured}
                        certainSearch={certainSearch}
                        listenCalendarDirection={true}
                        checkDates={!!certainSearch}
                        hasDiscount={hasDiscount}
                        pageParams={pageParams}
                        filterParams={this.props.searchFilters.selectedFilters}
                        onSearchStart={this.onSearchStart}
                        onSearchError={this.onSearchError}
                        onSearchFinish={this.onSearchFinish}
                        affixOffset={HotelSuggestion.calcBtnOffset()}
                        removeDateShadow
                        makeBtnFixed
                        url={window.location.href}
                      />
                    )}
                    <aside>
                      {this.state.fetching && <div className='options-mask' />}
                      {/*it makes glitches for preloader, i have to figure out ho to improve performance*/}
                      <h4>
                        <I18n t='Filtern nach:' />
                      </h4>
                      <CollapseList applyFilters={this.startSearch} filterAttributes={this.state.filterAttributes} />
                    </aside>
                  </div>
                  <GridSuggestions
                    startSearch={this.startSearch}
                    onDateBtnClick={this.onDateBtnClick}
                    fetching={this.state.fetching}
                    pagination={this.state.pagination}
                    cardType={cardType}
                    isEmpty={isEmpty}
                    results={results}
                    renderDateSelectBtn={renderDateSelectBtn}
                  />
                </div>
              ) : (
                <p className='search-error'>
                  <BadRequest />
                </p>
              )}
            </div>
          </section>
          {!certainSearch && <EmployeeLgBoard />}
          {isMobileOnly && (
            <Fragment>
              <section className='hotels-cards-block'>
                <HomeGallery description={false} headerTitle='Sonderangebote' />
              </section>
            </Fragment>
          )}
        </section>
      </Fragment>
    );
  }
}

export default HotelSuggestion;
