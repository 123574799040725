import React, {Component, Fragment} from 'react';
import {I18n} from '../../helpers/i18n/I18n';
import {QuestionCircleOutlined} from '@ant-design/icons';
import Popover from 'antd/lib/popover';
import {inject, observer} from 'mobx-react';
import {isMobile, isTablet} from 'react-device-detect';
import ReactHtmlParser from 'react-html-parser';

const inputStyle = {
  base: {
    lineHeight: '40px',
    fontSize: '16px',
    padding: '4px 11px',
    '::placeholder': {
      color: '#b3b3b3',
    },
  },
};

@inject('contentStore')
@observer
class StripeCardFields extends Component {
  reset = () => {};

  componentDidMount() {
    const STRIPE_ID = process.env.REACT_APP_STRIPE;
    const stripe = new window.Stripe(STRIPE_ID);
    const elements = stripe.elements();
    const cardNumber = elements.create('cardNumber', {style: inputStyle});
    const cardExpiry = elements.create('cardExpiry', {style: inputStyle});
    const cardCvc = elements.create('cardCvc', {style: inputStyle});

    this.setState({
      stripe,
      elements,
      cardNumber,
      cardExpiry,
      cardCvc,
    });

    cardNumber.mount('#cn');
    cardExpiry.mount('#ce');
    cardCvc.mount('#cvc');
  }

  render() {
    const {contentStore} = this.props;
    return (
      <Fragment>
        <I18n class='input-title' t='Kreditkartennummer' />
        <div>
          <div className='input-container'>
            <div className='stripe-wrapper custom-input inp-lg '>
              <div id='cn' placeholder='Kreditkartennummer' style={inputStyle} />
            </div>
          </div>
        </div>
        <I18n class='input-title' t='Gültig bis' />
        <div>
          <div className='input-container'>
            <div className='stripe-wrapper custom-input inp-lg '>
              <div id='ce' placeholder='Gültig bis (MM/JJ)' style={inputStyle} />
              <Popover
                overlayClassName='custom-popover payments md'
                content={ReactHtmlParser(contentStore.returnContent('checkout_kk_goodtil_tooltip', true))}
                placement='right'
                trigger={isMobile || isTablet ? 'click' : 'hover'}
              >
                <QuestionCircleOutlined className='hint-icon' />
              </Popover>
            </div>
          </div>
        </div>
        <I18n class='input-title' t='Sicherheitscode' />
        <div>
          <div className='input-container'>
            <div className='stripe-wrapper custom-input inp-lg '>
              <div id='cvc' placeholder='Sicherheitscode (CVC)' style={inputStyle} />
              <Popover
                overlayClassName='custom-popover payments md'
                content={ReactHtmlParser(contentStore.returnContent('checkout_kk_securitycode_tooltip', true))}
                placement='right'
                trigger={isMobile || isTablet ? 'click' : 'hover'}
              >
                <QuestionCircleOutlined className='hint-icon' />
              </Popover>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default StripeCardFields;
