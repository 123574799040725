import React, {Component, Fragment, PureComponent} from 'react';
import ResultCard from './ResultCard';
import {inject} from 'mobx-react';
import {Preloader} from '../preloader/Preloader';
import {PaginationBlock} from '../paginationBlock/PaginationBlock';
import {translate} from '../../../utils/Translater';
import {PAGE_SIZE} from '../../../config/const';
import {EmptyResult} from '../emptyResult/EmptyResult';

export const SORTING_OPTIONS = [
  {value: 'recommendations', label: translate('search.table.sorting_options.recommendation')},
  {value: 'price', label: translate('search.table.sorting_options.price')},
];

@inject('popup', 'searchFilters')
export class GridSuggestions extends Component {
  render() {
    const {isEmpty, results, fetching, onDateBtnClick, renderDateSelectBtn, cardType, pagination, startSearch} = this.props;
    return (
      <div className='suggestions-results' id='search-results'>
        {!isEmpty && results.map((result, i) => <ResultCard cardType={cardType} renderDateSelectBtn={renderDateSelectBtn} onDateBtnClick={onDateBtnClick} key={i} result={result} />)}
        {isEmpty && !fetching && <EmptyResult />}
        {pagination && !isEmpty && (
          <PaginationBlock
            scrollId='#search-results'
            totalPages={pagination.totalPages}
            page={pagination.page}
            sort={pagination.sort}
            // size={pagination.size || 10}
            size={PAGE_SIZE}
            changePage={startSearch}
            hasNext={pagination.hasNext}
            hasPrev={pagination.hasPrev}
          />
        )}
        {fetching && <Preloader showSpinner={false} />}
      </div>
    );
  }
}
