import React, {Component, Fragment} from 'react';
import classNames from 'classnames';
import {withRouter} from 'react-router';
import {inject, observer} from 'mobx-react';
import {isMobileOnly} from 'react-device-detect';
import ReactHtmlParser from 'react-html-parser';
import Lightbox from 'react-image-lightbox';

import {ReactComponent as Triangle} from '../../../images/icons/triangle.svg';
import {ReactComponent as PresentIcon} from '../../../images/icons/present.svg';
import {ReactComponent as FoodIcon} from '../../../images/icons/food.svg';
import {ReactComponent as CalendarIcon} from '../../../images/icons/calendar-event-fill.svg';
import {ReactComponent as HomeIcon} from '../../../images/icons/home.svg';
import {ReactComponent as LoupeIcon} from '../../../images/icons/loupe.svg';
import {ReactComponent as Zahlung} from '../../../images/payment/Rechnung.svg';
import {ReactComponent as Sepa} from '../../../images/payment/sepa.svg';
import {ReactComponent as Heart} from '../../../images/icons/heart.svg';

import {concatDateStrings, doSmoothScroll, returnObligatoryArr, returnPicSize, isTablet, setElemShadow} from '../../../utils/Utils';
import {DATE_FORMAT} from '../../../config/const';

import {MobileSlider} from '../../mainPages/hotelRooms/MobileSlider';
import {GuestsCount} from '../../mainPages/hotelRooms/GuestsCount';
import {RoomOptions} from '../../modals/roomOptions/RoomOptions';
import {BookingBtn} from '../../mainPages/hotelRooms/BookingBtn';
import {FooterBtns} from '../../mainPages/hotelRooms/FooterBtns';
import {TaxBill} from '../../modals/taxBill/TaxBill';

import {RoomDescriptionLink} from '../roomDescriptionLink/RoomDescriptionLink';
import {TreatmentMinDays} from '../treatmentMinDays/TreatmentMinDays';
import {RoomsFeeLink} from '../roomsFeeLink/RoomsFeeLink';
import {TaxIncluded} from '../taxIncluded/taxIncluded';
import {OfferCard} from '../gridSuggestions/OfferCard';
import {MEALS_TYPES} from '../searchForm/SearchForm';
import {RoomNotice} from '../roomNotice/RoomNotice';
import {PriceView} from '../priceView/PriceView';
import {Slider} from '../slider/Slider';
import {I18n} from '../i18n/I18n';

import {CopyField} from '../copyField/CopyField';
import {Price} from '../price/Price';
import {prosOptions} from '../../mainPages/payment/Payment';
import {ProsList} from '../prosList/ProsList';

const ImgWrapper = ({showHotelPic, room, hotel, sliderSettings, slider, activeSlide, openGallery}) => {
  if (showHotelPic) {
    const querySize = returnPicSize('roomTopCheckout', window.innerWidth);
    return hotel.media || [].length > 0 ? <img alt='hotel-img' src={`${hotel.media[0]}${querySize}`} /> : <div className='empty-room-pic' />;
  } else {
    if (room.media || [].length > 0) {
      return (
        <div className='img-container'>
          {!isTablet() ? (
            <Fragment>
              <div className='slide-counter'>{`${activeSlide + 1} von ${room.media.length}`}</div>
              <Slider settings={sliderSettings} wrapperClass='sm' renderSlides={slider} />
            </Fragment>
          ) : (
            <Fragment>
              <img alt='hotel-img' className='single-photo' src={`${room.media[0]}${returnPicSize('room', window.innerWidth)}`} onClick={openGallery} />
              <div className='counter-wrapper' onClick={openGallery}>
                <LoupeIcon />
                <div className='slide-counter with-loupe'>{`${activeSlide + 1} von ${room.media.length}`}</div>
              </div>
            </Fragment>
          )}
        </div>
      );
    } else {
      return <div className='empty-room-pic' />;
    }
  }
};

@withRouter
@inject('popup')
@observer
class Room extends Component {
  state = {
    descriptionIsOpen: false,
    activeSlide: 0,
    isOpen: false,
    photoIndex: 0,
  };

  toggleExpander = () => {
    this.setState({descriptionIsOpen: !this.state.descriptionIsOpen});
  };

  bookRoom = (room, hotel, request) => {
    this.props.history.push(`/${hotel.nameUrl}/payment/${room.resultId}`);
  };

  openModal = (modalCmp, txt) => {
    this.props.popup.open(modalCmp, null, null, txt);
  };

  openObligatoryModal = (modalCmp, params) => {
    if (!params.description) {
      return;
    }

    this.props.popup.open(modalCmp, null, null, params);
  };

  highlightDateInputs = () => {
    setElemShadow('#search_start_date');
    setElemShadow('#search_end_date');
  };

  scrollToForm = room => {
    isMobileOnly && doSmoothScroll('#main-search-form', 36);
    this.props.checkDateInputs && this.props.checkDateInputs();
    room.treatmentNeedsExtraDays && this.highlightDateInputs();
  };

  returnHostAddress = () => {
    const protocol = location.protocol;
    const hostName = window.location.hostname === 'localhost' ? `${window.location.hostname}:3000` : window.location.hostname;
    const slashes = protocol.concat('//');
    return slashes.concat(hostName);
  };

  returnCopyLink = (url, resultId) => {
    const urlParams = this.props.match.params;
    const searchRequestId = urlParams.searchRequestId;
    return this.returnHostAddress() + url + `${searchRequestId ? `/${searchRequestId}` : ''}` + `?resultId=${resultId}`;
  };

  renderRoomSlides = media => {
    const querySize = returnPicSize('room', window.innerWidth);
    return media.map((elem, i) => <img className='room-pic' key={i} itemProp='photo' alt='hotel img' src={`${elem}${querySize}`} />);
  };
  toggleGallery = newVal => {
    this.setState({isOpen: newVal});
  };
  onMoveRequest = val => {
    this.setState({photoIndex: val});
  };

  render() {
    const {isOpen, photoIndex} = this.state;
    const {room, hotel, request, formData = {}, hideRoomDescription, showFees, showHotelPic, paymentsType} = this.props;
    const {kurFeeExists, kurFeeFreeAdults, kurFeeFreeAdultsFrom, kurFeeFreeChildren, kurFeeFreeChildrenTill, kurFeePrice, kurFeeType, url} = hotel;
    const fees = {
      kurFeeExists,
      kurFeeFreeAdults,
      kurFeeFreeAdultsFrom,
      kurFeeFreeChildren,
      kurFeeFreeChildrenTill,
      kurFeePrice,
      kurFeeType,
    };
    const linkForCopy = this.returnCopyLink(url, room.resultId);
    const noticeTxt = (room.treatmentDescription.split('</ul>')[1] || '').trim();
    const optionsList = (room.treatmentDescription.split('</ul>')[0] || '') + '</ul>';
    const obligatoryArray = returnObligatoryArr(hotel.compulsories || [], request);
    const sliderSettings = {
      beforeChange: (current, next) => this.setState({activeSlide: next}),
      lazyLoad: 'ondemand',
    };
    const images = room.media || [];
    const slider = () => {
      const querySize = returnPicSize('room', window.innerWidth);
      return (room.media || []).map((url, i) => <img itemProp='photo' className='main-img' alt='hotelImg' src={`${url}${querySize}`} />);
    };
    const priceWithoutTransfer = room.priceWithoutDiscount - room.transferPrice;
    const newPriceWithoutTransfer = room.price - room.transferPrice;
    return (
      <div className='wrapper-card-room'>
        <OfferCard discount={room.discountInfo} />
        <div className={classNames('hotel-room', {'with-discount': room.discountInfo.discountType})} itemScope itemType='http://schema.org/HotelRoom http://schema.org/Product'>
          {isOpen && (
            <Lightbox
              mainSrc={images[photoIndex]}
              nextSrc={images[(photoIndex + 1) % images.length]}
              prevSrc={images[(photoIndex + images.length - 1) % images.length]}
              onCloseRequest={this.toggleGallery.bind(this, false)}
              onMovePrevRequest={this.onMoveRequest.bind(this, (photoIndex + images.length - 1) % images.length)}
              onMoveNextRequest={this.onMoveRequest.bind(this, (photoIndex + 1) % images.length)}
            />
          )}
          <div className='left'>
            <div className='room-img-wrapper'>
              <ImgWrapper
                room={room}
                hotel={hotel}
                slider={slider}
                sliderSettings={sliderSettings}
                activeSlide={this.state.activeSlide}
                showHotelPic={showHotelPic}
                openGallery={this.toggleGallery.bind(this, true)}
              />
            </div>
          </div>
          <div className='float-wrapper'>
            <div>
              <div>
                <div className={classNames('options-wrapper', {'active-collapse': this.state.descriptionIsOpen})}>
                  <div className='banner-profit spa-package room-note'>
                    <span onClick={this.props.copyTxt(true)} className=''>
                      {room.treatmentName}
                    </span>
                  </div>
                  <div className='room-info'>
                    <span itemProp='name' className='room-name'>
                      {room.roomName}
                    </span>
                    <div className='actions-popup-wrapper'>
                      {!hideRoomDescription && (
                        <RoomDescriptionLink
                          hotel={hotel}
                          room={room}
                          openRoomModal={this.openModal}
                          modal={RoomOptions}
                          roomAmenities={room.roomAmenities}
                          classModal='room-modal'
                          classIcon='custom-icon sm-md check green'
                        />
                      )}
                      {showFees && <RoomsFeeLink price={room.price} openModal={this.openModal} arrivalDate={request.arrivalDate} cancellationOptions={hotel.cancellationFees} />}
                    </div>
                    {isMobileOnly && !showHotelPic && (
                      <MobileSlider
                        room={room}
                        request={request}
                        hideRoomDescription={hideRoomDescription}
                        showFees={showFees}
                        hotel={hotel}
                        openModal={this.openModal}
                        renderRoomSlides={this.renderRoomSlides}
                      />
                    )}
                  </div>
                  {showHotelPic && isMobileOnly && (
                    <div onClick={this.toggleExpander} className='mobile-toggle-btn'>
                      <I18n t='Leistungsbeschreibung' />
                      <Triangle className={classNames('look-down', {'look-up': this.state.descriptionIsOpen})} />
                    </div>
                  )}
                  {obligatoryArray.map((elem, i) => (
                    <div
                      key={i}
                      className={classNames('present-row', {'show-present': showHotelPic && this.state.descriptionIsOpen})}
                      onClick={this.openObligatoryModal.bind(this, TaxBill, {
                        description: elem.description,
                        name: elem.name,
                      })}
                    >
                      <PresentIcon />
                      <I18n t={elem.name} className={classNames('tooltip-title', {hoverable: !!elem.description})} />
                    </div>
                  ))}
                  <div
                    className={classNames('room-accordion-options', {
                      open: this.state.descriptionIsOpen,
                      'with-present': obligatoryArray.length > 0,
                    })}
                  >
                    <div className='meal-type'>
                      <FoodIcon />
                      <div>
                        <I18n t='Verpflegung:' />
                        <span itemProp='name'>{MEALS_TYPES[room.kindOfCatering]}</span>
                      </div>
                    </div>
                    <div>{ReactHtmlParser(optionsList)}</div>
                    {showHotelPic && isMobileOnly && <RoomNotice>{ReactHtmlParser(noticeTxt)}</RoomNotice>}
                  </div>
                  {!this.state.descriptionIsOpen && (
                    <div className='expander'>
                      <I18n onClick={this.toggleExpander} t='Weiteres' /> <Triangle onClick={this.toggleExpander} />
                    </div>
                  )}
                </div>
                {this.state.descriptionIsOpen && (
                  <div className='opened-description'>
                    <RoomNotice>{ReactHtmlParser(noticeTxt)}</RoomNotice>
                    <div className='expander active'>
                      <I18n onClick={this.toggleExpander} t='Schließen' /> <Triangle onClick={this.toggleExpander} />
                    </div>
                  </div>
                )}
              </div>
              {this.props.children ? (
                this.props.children
              ) : request.departureDate && request.arrivalDate ? (
                <div className='right'>
                  <div className='order-options-info'>
                    <div className='date-from-to'>
                      <CalendarIcon />
                      <span>
                        {concatDateStrings(request.arrivalDate).format(DATE_FORMAT)} - {concatDateStrings(request.departureDate).format(DATE_FORMAT)}
                      </span>
                    </div>
                    <span className='nights-count'>
                      {room.nights} <I18n t='common.night' />
                    </span>
                  </div>
                  <div className='room-info-block'>
                    <div className='room-title'>
                      <HomeIcon />
                      <I18n t={room.roomName} />
                    </div>
                    {/* <PriceView className='price-direction' hasDiscount={room.discountInfo.discountType} oldPrice={priceWithoutTransfer} price={newPriceWithoutTransfer} /> */}
                  </div>
                  <GuestsCount transferPrice={room.transferPrice} guests={room.perGuestPrice} />
                  <div className='price-block'>
                    <div className='total-price-block'>
                      <I18n t='Gesamtpreis:' className='title-block' />
                      <PriceView className='price-direction total-price' hasDiscount={room.discountInfo.discountType} oldPrice={room.priceWithoutDiscount} price={room.price} />
                    </div>
                    <span className='tax-bill'>
                      <TaxIncluded openModal={this.openModal.bind(this, TaxBill, fees)} />
                    </span>
                  </div>
                  {!room.transferIndex && (
                    <button className='custom-transfer-link' onClick={this.props.activeTransfer}>
                      <I18n t='Haustürtransfer hinzufügen?' />
                    </button>
                  )}
                  {/*<CopyField copyTxtValue={linkForCopy} wrapperClass="label-right" cdTxt="Kopiert" txt="Link kopieren"/>*/}
                  <div className='room-btns'>
                    <a className='custom-empty-btn custom-empty-link' href={this.props.emailLink} target='_blank' onClick={this.props.copyTxt(false)}>
                      <I18n t='Angebot per E-Mail' />
                    </a>
                    {/*{!isMobileOnly && (*/}
                    {/*  <a className='custom-empty-btn custom-empty-link' href={this.props.printLink} target='_blank'>*/}
                    {/*    <Heart />*/}
                    {/*    <I18n t='Merken' />*/}
                    {/*  </a>*/}
                    {/*)}*/}
                    <a className='custom-empty-btn custom-empty-link' href={this.props.questionLink} target='_blank' onClick={this.props.copyTxt(false)}>
                      <I18n t='Frage zum Angebot' />
                    </a>
                  </div>
                  <a className='custom-empty-btn custom-green-link' href={this.props.favouriteLink} target='_blank' onClick={this.props.copyTxt(false)}>
                    <I18n t='Individuelles Angebot anfordern' />
                  </a>
                  <BookingBtn
                    extraDays={room.treatmentNeedsExtraDays}
                    bookRoom={this.bookRoom.bind(this, room, hotel)}
                    scrollToForm={this.scrollToForm.bind(this, room)}
                    titleBtn={room.treatmentNeedsExtraDays ? 'Anreisedatum wählen' : 'Zur Buchung'}
                  />
                  <div className='room-pros'>
                    <ProsList list={[prosOptions[1]]} />
                  </div>
                  <TreatmentMinDays extraDays={room.treatmentNeedsExtraDays} minimalStay={room.treatmentMinimalStay} />
                  {paymentsType && (
                    <div className='payment-types'>
                      {this.props.onlyInvoice ? (
                        <Zahlung />
                      ) : (
                        <Fragment>
                          <Sepa />
                          {this.props.isInvoicePaymentEnabled && <Zahlung />}
                          <i className='custom-icon master-card' />
                          <i className='custom-icon visa' />
                          <i className='custom-icon express' />
                        </Fragment>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div className='right items-right'>
                  <div className='days-count'>
                    <div>
                      <div>
                        <span>{room.perGuestPrice[0].days} </span>
                        <I18n class='person-price' t='Nächte' />
                      </div>
                    </div>
                  </div>
                  <div className='price-block'>
                    <div className='total-price-block'>
                      <I18n t='Gesamtpreis:' className='title-block' />
                      <PriceView className='price-direction total-price' request={request} hasDiscount={room.discountInfo.discountType} oldPrice={room.priceWithoutDiscount} price={room.price} />
                    </div>
                    <span className='tax-bill'>
                      <TaxIncluded openModal={this.openModal.bind(this, TaxBill, fees)} />
                    </span>
                  </div>
                  {/*<CopyField copyTxtValue={linkForCopy} cdTxt="Kopiert" txt="Link kopieren"/>*/}
                  <FooterBtns onClick={this.scrollToForm} />
                </div>
              )}
            </div>
            {/* <BookingBtn className='mobile-view' extraDays={room.treatmentNeedsExtraDays} bookRoom={this.bookRoom.bind(this, room, hotel)} scrollToForm={this.scrollToForm} /> */}
          </div>
        </div>
      </div>
    );
  }
}

export default Room;
