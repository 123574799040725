import React, {Component, Fragment} from 'react';
import {I18n} from '../../helpers/i18n/I18n';
import {withRouter} from 'react-router';

export class FooterBtns extends Component {
  render() {
    return (
      <div className='footer-btn-wrapper'>
        <button className='custom-empty-btn' onClick={this.props.onClick}>
          <I18n t='Anreisedatum wählen' />
        </button>
        {/*<I18n class="trip-date" t="Reisetermin wählen"/>*/}
      </div>
    );
  }
}
