import React, {Component} from 'react';
import classNames from 'classnames';

export class ProsList extends Component {
  render() {
    const {list} = this.props;
    return (
      <ul className='payment-list'>
        {list.map((item, index) => {
          return (
            <li key={index} className={classNames('payment-item', item.isBold ? 'payment-item-bold' : '')}>
              {item.title}
            </li>
          );
        })}
      </ul>
    );
  }
}
