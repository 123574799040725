import React, {Component} from 'react';
import Modal from 'antd/lib/modal';
import classNames from 'classnames';

import {I18n} from '../../helpers/i18n/I18n';
import {Slider} from '../../helpers/slider/Slider';

import {ReactComponent as Close} from '../../../images/icons/cross.svg';
import {ServiceIcons} from '../../helpers/serviceIcons/ServiceIcons';

class RoomOptions extends Component {
  state = {
    activeSlide: 0,
  };

  render() {
    const {modalOpen, closeModal, params} = this.props;
    const {activeSlide} = this.state;
    const {hotel, room} = params;
    const media = room.media || [];
    const mainFields = hotel.mainFields || {};
    const roomAttribites = room.roomAmenities || [];
    const nextSlides = media.slice(activeSlide, activeSlide + 3);
    const service = roomAttribites.filter(elem => elem.area.toLowerCase() === 'service');
    const bathroom = roomAttribites.filter(elem => elem.area === 'Badezimmer');
    const roomEquipment = roomAttribites.filter(elem => elem.area === 'Zimmereinrichtung');
    const renderSlides = () => {
      return media.map((url, i) => (
        <div key={i} className='slider-item'>
          <img className='main-img' alt='hotelImg' src={url} />
        </div>
      ));
    };

    const settings = {
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      lazyLoad: 'ondemand',
      speed: 500,
      beforeChange: (current, next) => this.setState({activeSlide: next}),
    };

    return (
      <div>
        <Modal
          wrapClassName='vertical-center-modal'
          className={classNames('custom-modal txt-box-modal', params.classModal)}
          closeIcon={<Close />}
          visible={modalOpen}
          onOk={closeModal}
          onCancel={closeModal}
          footer={null}
        >
          <div className='modal-body'>
            {params.roomAmenities && (
              <div className='room-options'>
                <div className='wrapper-txt'>
                  <div className='room-services-block'>
                    <I18n t={room.roomName} className='room-amenities-title' />
                    <ServiceIcons
                      roomAmenities={roomAttribites}
                      checkOptionsInRoom
                      shownIcName
                      classContainer='wrapper-service-room'
                      wrapperClass='amenities-item'
                      roomSize={room.roomSize}
                      bedSize={room.bedSize}
                    />
                  </div>
                  <div className='wrapper-amenities'>
                    <div className='block-txt-amenities'>
                      {roomEquipment.length > 0 && (
                        <div className='service-amenities'>
                          <I18n t='Zimmereinrichtung' className='title-block-amenities' />
                          <div className='wrapper-amenities'>
                            {roomEquipment.map((elem, i) => (
                              <div key={i} className='amenities-item'>
                                <i className='custom-icon sm-md check green' />
                                <span> {elem.label} </span>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                      {bathroom.length > 0 && (
                        <div className='service-amenities'>
                          <I18n t='Badezimmer' className='title-block-amenities' />
                          <div className='wrapper-amenities'>
                            {bathroom.map((elem, i) => (
                              <div key={i} className='amenities-item'>
                                <i className='custom-icon sm-md check green' />
                                <span> {elem.label} </span>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                      {service.length > 0 && (
                        <div className='service-amenities'>
                          <I18n t='Service' className='title-block-amenities' />
                          <div className='wrapper-amenities'>
                            {service.map((elem, i) => (
                              <div key={i} className='amenities-item'>
                                <i className='custom-icon sm-md check green' />
                                <span> {elem.label} </span>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className='slider-block'>
                  <Slider settings={settings} wrapperClass='mobile-gallery room-view room-slider' renderSlides={renderSlides} showControlls={room.media.length > 1} />
                  <div className='slide-counter'> {`${activeSlide + 1} von ${room.media.length}`} </div>
                  {room.media.length > 1 && (
                    <div className='preview-image-block'>
                      {nextSlides.map((url, i) => (
                        <div key={i} className='preview-slider-item'>
                          <img className='main-img' alt='roomImg' src={url} />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

export {RoomOptions};
