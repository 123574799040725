import React, {Component, Fragment} from 'react';
import {I18n} from '../../helpers/i18n/I18n';
import {QuestionCircleOutlined} from '@ant-design/icons';
import Popover from 'antd/lib/popover';
import {inject, observer} from 'mobx-react';
import {isMobile, isTablet} from 'react-device-detect';
import ReactHtmlParser from 'react-html-parser';
import {Payment} from './Payment';

class PaymentWrapper extends Component {
  state = {stripeLoaded: false};

  componentWillMount() {
    console.log('Load stripe script...');
    const script = document.createElement('script');
    script.src = '//js.stripe.com/v3/';
    script.async = true;
    document.body.appendChild(script);
    script.addEventListener('load', ev => {
      this.setState({stripeLoaded: true});
      console.log('...stripe script loaded');
    });
  }

  render() {
    return this.state.stripeLoaded ? <Payment {...this.props} /> : <Fragment />;
  }
}

export default PaymentWrapper;
