import React, {Component} from 'react';
import {I18n} from '../../helpers/i18n/I18n';
import {InputTxt} from '../../helpers/inputTxt/InputTxt';
import {Preloader} from '../../helpers/preloader/Preloader';
import {findOrder, getText} from '../../../utils/Fetcher';
import {FoundOrder} from './FoundOrder';
import ReactHtmlParser from 'react-html-parser';

export class ReservationInputs extends Component {
  state = {
    fetching: false,
    orderFound: null,
    sideBarContent: '',
  };

  componentDidMount() {
    getText('cancelation_sidebar_static').then(resp => this.setState({sideBarContent: resp.data?.value}));
  }

  findOrder = () => {
    if (!this.checkData()) {
      return;
    }
    this.setState({fetching: true});
    findOrder(this.props.email, this.props.reservationId).then(resp => {
      let isFound = false;
      !resp.isError ? (isFound = true) : this.props.removeWrongEmail();
      this.setState({fetching: false, orderFound: isFound});
      isFound && this.props.changeCard(FoundOrder, resp.data);
    });
  };

  checkData = () => {
    const {email, reservationId} = this.props;
    let isCorrect = true;
    const data = {
      email: email,
      reservationId: reservationId,
    };
    let errors = {};
    Object.keys(data).forEach(key => {
      if (!data[key]) {
        errors[key] = true;
      }
    });
    if (Object.keys(errors).length) {
      this.props.onChange('inputError', errors);
      isCorrect = false;
    }
    return isCorrect;
  };

  render() {
    const {fetching, orderFound} = this.state;
    const {reservationId, email, onChange, inputError} = this.props;
    return (
      <article className='cancel-card'>
        <div className='left-section'>{ReactHtmlParser(this.state.sideBarContent)}</div>
        <div className='right-section user-info'>
          <div className='reservation-data-form'>
            <div className='inputs-row'>
              <InputTxt
                title='Ihr Storno-token (bereits erkannt):'
                inputError={inputError.reservationId}
                value={reservationId}
                onChange={onChange}
                name='reservationId'
                placeholder='Reservierungsnummer*'
              />
              <InputTxt
                title='Ihre E-Mail Adresse:'
                inputError={inputError.email}
                value={email}
                onChange={onChange}
                name='email'
                placeholder='E-Mail**'
                description='E-mail Adresse, die Sie bei der Buchung angegeben haben'
              />
            </div>
            {orderFound === false && (
              <div className='order-not-found'>
                <I18n t={'Diese E-Mail Adresse kann Ihrer Buchung nicht zugeordnet werden.\nBitte geben Sie die E-Mail Adresse ein, die Sie bei Ihrer Buchung verwendet Haben'} />
              </div>
            )}
            <button className='custom-btn' onClick={this.findOrder}>
              <I18n t='Weiter' />
            </button>
          </div>
        </div>
        {fetching && <Preloader />}
      </article>
    );
  }
}
