import React, {Component} from 'react';
import Modal from 'antd/lib/modal';

import {SORTING_OPTIONS} from '../../helpers/gridSuggestions/GridSuggestions';
import {ReactComponent as Close} from '../../../images/icons/cross.svg';
import {I18n} from '../../helpers/i18n/I18n';
import Radio from 'antd/es/radio';

class Sorting extends Component {
  setNewFilter = value => {
    this.props.params.changeSelection(value, 'pagesSort');
    this.props.closeModal();
  };

  render() {
    const {modalOpen, closeModal, params} = this.props;
    return (
      <div>
        <Modal
          wrapClassName='vertical-center-modal full-size'
          className='custom-modal table-filter-modal'
          closeIcon={<Close />}
          visible={modalOpen}
          onOk={closeModal}
          onCancel={closeModal}
          footer={null}
        >
          <h3 className='modal-header md'>
            <I18n t='search.table.header.sorting' />:
          </h3>
          <div className='modal-body'>
            <Radio.Group value={params.pagesSort} className='radio-container'>
              {SORTING_OPTIONS.map((elem, i) => (
                <Radio onChange={this.setNewFilter.bind(this, elem.value)} value={elem.value} key={i} className='radio-option'>
                  {elem.label}
                </Radio>
              ))}
            </Radio.Group>
            {/*<button className="custom-btn"><I18n t="modal.button.apply"/></button>*/}
          </div>
        </Modal>
      </div>
    );
  }
}

export default Sorting;
