import React, {Component} from 'react';
import {I18n} from '../i18n/I18n';
import {GalleryCard} from './GalleryCard';
import {isMobileOnly} from 'react-device-detect';
import {Slider} from '../slider/Slider';
import {Preloader} from '../preloader/Preloader';
import {EmptyResults} from '../EmptyResults';

export class HotelsGallery extends Component {
  renderSlides = () => {
    const {items = []} = this.props;
    if (items.length > 0) {
      return items.slice(0, 7).map((elem, i) => <GalleryCard key={i} elemOrder={i} card={elem} />);
    }
    return <EmptyResults />;
  };

  render() {
    const settings = {
      arrows: false,
      infinite: true,
      slidesToShow: 1,
      variableWidth: true,
    };
    const {galleryHeader, isFetching} = this.props;
    return (
      <section className='hotels-gallery'>
        <h2 className='section-header'>
          <I18n t={galleryHeader} />
        </h2>
        {isFetching ? (
          <Preloader />
        ) : (
          <div className='hotels-flex-gallery'>{isMobileOnly ? <Slider settings={settings} wrapperClass='mobile-gallery hotels' renderSlides={this.renderSlides} /> : this.renderSlides()}</div>
        )}
      </section>
    );
  }
}
