import React, {Component, Fragment} from 'react';
import {I18n} from '../i18n/I18n';

export class TreatmentsPeriod extends Component {
  render() {
    const {perDay, perStay, perWeek, separator = ' '} = this.props;
    return (
      <Fragment>
        {(perDay || perStay || perWeek) && (
          <span>
            {perDay && (
              <span>
                {separator}
                {perDay} <I18n t='Anwendungen' /> <I18n t='common.per_days' />
              </span>
            )}
            {perWeek && (
              <span>
                {separator}
                {perWeek} <I18n t='Anwendungen' /> <I18n t='common.per_weeks' />
              </span>
            )}
            {perStay && (
              <span>
                {separator}
                {perStay} <I18n t='Anwendungen' /> <I18n t='common.per_stay' />
              </span>
            )}
          </span>
        )}
      </Fragment>
    );
  }
}
