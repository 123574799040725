import React from 'react';

import {Link} from 'react-router-dom';

class IconsBlock extends React.Component {
  scriptUrl = 'https://www.provenexpert.com/widget/circlewidget.js?s=150&id=bafch&u=18zA0Vwol82ZjRmA1HGplOKB48TA1LQB';

  componentDidMount() {
    const scriptCollection = document.getElementsByTagName('script');
    const isScriptExist = [...scriptCollection].some(({src}) => src === this.scriptUrl);

    if (!isScriptExist) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = this.scriptUrl;

      document.body.append(script);
    }
  }

  render() {
    return (
      <article className='circle-icons-container'>
        {['ssl', 'booksana', 'master-card', 'visa', 'express'].map((classN, index) =>
          classN === 'booksana' ? <Link to='/information/best-price-guarantee' key={index} className={`circle-block sm booksana`} /> : <div key={index} className={`circle-block sm ${classN}`} />
        )}
        {/*<span id='provenexpert_circle_widget_bafch' style='text-decoration:none;' />*/}
      </article>
    );
  }
}

export default IconsBlock;
