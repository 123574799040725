import React, {Component} from 'react';
import {doSmoothScroll} from '../../../utils/Utils';
import {CaretRightFilled, CaretLeftOutlined} from '@ant-design/icons';

export class PaginationBlock extends Component {
  doScroll = () => {
    const {scrollId} = this.props;
    scrollId && doSmoothScroll(scrollId);
  };

  goFurther = () => {
    const {hasNext, changePage, size, sort, page} = this.props;
    hasNext && changePage({size: size, page: page + 1});
    this.doScroll();
  };

  goBack = () => {
    const {hasPrev, changePage, size, sort, page} = this.props;
    hasPrev && changePage({size: size, page: page - 1});
    this.doScroll();
  };

  onPageClick = number => {
    const {changePage, size, sort} = this.props;
    changePage({size: size, page: number});
    this.doScroll();
  };

  render() {
    const {totalPages, page, hasNext, hasPrev, size} = this.props;
    const maxSize = size + page;
    return (
      <div className='pagination-block'>
        <CaretLeftOutlined className={`${hasPrev ? '' : 'hide'}`} onClick={this.goBack} />
        <ul className='figure-block'>
          {totalPages > 0 &&
            [...Array(totalPages)].map((elem, i) => {
              const pageNumber = i;
              if (totalPages <= size) {
                return (
                  <li onClick={this.onPageClick.bind(this, i)} className={`${page === i ? 'active' : ''}`} key={i}>
                    {pageNumber + 1}
                  </li>
                );
              } else if (totalPages - page <= size && pageNumber >= totalPages - size) {
                return (
                  <li onClick={this.onPageClick.bind(this, i)} className={`${page === i ? 'active' : ''}`} key={i}>
                    {pageNumber + 1}
                  </li>
                );
              } else if (pageNumber < maxSize && pageNumber > page) {
                return (
                  <li onClick={this.onPageClick.bind(this, i)} className={`${page === i ? 'active' : ''}`} key={i}>
                    {pageNumber + 1}
                  </li>
                );
              } else {
                return null;
              }
            })}
          {/*todo refactoring*/}
        </ul>
        <CaretRightFilled className={`${hasNext ? '' : 'hide'}`} onClick={this.goFurther} />
      </div>
    );
  }
}
