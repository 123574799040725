import React, {Component} from 'react';
import {I18n} from '../i18n/I18n';
import {returnPicSize, isDesktop} from '../../../utils/Utils';

import {ReservationInputs} from '../../mainPages/cancelReservation/ReservationInputs';

export class GalleryCard extends Component {
  render() {
    const {elemOrder, card} = this.props;
    const {image, name, slug, hotelsCount} = card;
    const querySize = returnPicSize(`homeRegions${elemOrder > 1 && elemOrder < 5 ? 'Sm' : ''}`, window.innerWidth);
    return (
      <a href={`/region/${slug}`} target='_blank' className={`item ${elemOrder > 1 && elemOrder < 5 ? '' : 'lg'}`}>
        {image ? (
          <img itemProp='photo' alt='hotel img' src={`${image}${querySize}`} />
        ) : (
          <div className='empty-img'>
            <I18n t='Region image' />
          </div>
        )}
        <div>
          <div className='item-footer'>
            <p className='hotels-count'>
              <I18n t='Über' />
              <span itemProp='numberOfRooms' className='bold-txt'>
                {hotelsCount} <I18n t='Hotels' />
              </span>
            </p>
          </div>
          <div className='item-header'>
            <h4>{name}</h4>
          </div>
          {/* {isDesktop() && (
            <button className='custom-empty-btn'>
              <I18n t='Kur-Hotel finden' />
            </button>
          )} */}
        </div>
      </a>
    );
  }
}
