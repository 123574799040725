import React, {Component} from 'react';
import classNames from 'classnames';
import {I18n} from '../i18n/I18n';
import {ReactComponent as Cross} from '../../../images/icons/cross.svg';

//ant-select-dropdown
export class GuestsForm extends Component {
  state = {
    isOpen: false,
  };

  guestFormRef = React.createRef();

  componentWillUnmount() {
    document.removeEventListener('click', this.closeMenu);
  }

  closeMenu = e => {
    const listAdults = document.querySelector('.adults-dd-list');
    const listChildren = document.querySelector('.children-dd-list');
    const listChildrenAge1 = document.querySelector('.children-age-dd-list-0');
    const listChildrenAge2 = document.querySelector('.children-age-dd-list-1');

    const islistAdultsElem = listAdults ? listAdults.contains(e.target) : false;
    const islistChildrenElem = listChildren ? listChildren.contains(e.target) : false;
    const islistChildrenAge1 = listChildrenAge1 ? listChildrenAge1.contains(e.target) : false;
    const islistChildrenAge2 = listChildrenAge2 ? listChildrenAge2.contains(e.target) : false;

    if (!islistAdultsElem && !islistChildrenElem && !islistChildrenAge1 && !islistChildrenAge2) {
      this.setState({isOpen: false}, () => document.removeEventListener('click', this.closeMenu));
    }
  };

  changeOpenState = (isError = false) => {
    this.setState({isOpen: !this.state.isOpen, isError}, () => {
      this.state.isOpen && document.addEventListener('click', this.closeMenu);
    });
  };

  toggleState = e => {
    e.stopImmediatePropagation();
    if (e.target.classList.contains('guests-options') || e.target.classList.contains('guests-form') || (this.guestFormRef.current.contains(e.target) && !this.state.isOpen)) {
      this.changeOpenState();
    }
  };

  render() {
    const {adults, childrenCount, removeGuests} = this.props;
    // let title = "Ein Gäste";
    // if (adults > 1 || childrenCount.length) {
    //   title = adults + childrenCount.length + " Gäste";
    //   title = `Erwachsene: ${adults}`;
    //   if (childrenCount.length) {
    //     childrenCount.forEach((age, i) => {
    //       title += `, ${i + 1} Kinder (${age}) ${i === 1 ? "." : ""}`
    //     })
    //   }
    // }
    const title = adults + childrenCount.length;
    return (
      <div ref={this.guestFormRef} className={classNames('guests-form-wrapper', {open: this.state.isOpen})}>
        <I18n class='custom-select-header' t='Personen' />
        <div className='guests-form' onClick={this.toggleState}>
          <span className='guest-form-title'>{title}</span>
          {title > 2 && <Cross className='remove-ic' onClick={removeGuests} />}
          <div className='guests-options'>
            <div className='content-wrapper'>{this.props.children && this.props.children}</div>
          </div>
        </div>
      </div>
    );
  }
}
