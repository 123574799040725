import React, {Component} from 'react';
import {I18n} from '../i18n/I18n';
import Logo from '../../../images/logo.png';
import {Link, NavLink} from 'react-router-dom';
import {STATIC_PAGE_URL, MAIL} from '../../../config/const';
import {inject, observer} from 'mobx-react';
import moment from 'moment';

const firstColumn = [
  {label: 'Kontakt & Servicе', url: `${STATIC_PAGE_URL}/contacts`},
  {label: 'Haustürtransfer', url: `${STATIC_PAGE_URL}/transfer`},
  {label: 'Partner werden', url: `${STATIC_PAGE_URL}/partner`},
  {label: 'Gutschein', url: `${STATIC_PAGE_URL}/voucher`},
  {label: 'FAQ', url: `${STATIC_PAGE_URL}/faq`},
  {label: 'Newsletter', url: `${STATIC_PAGE_URL}/newsletter`},
];

const thirdColumn = [
  {label: 'Impressum', url: `${STATIC_PAGE_URL}/imprint`},
  {label: 'Datenschutzbestimmungen', url: `${STATIC_PAGE_URL}/privacy`},
  {label: 'AGB', url: `${STATIC_PAGE_URL}/agb`},
  {label: 'Best Preis Garantie', url: `${STATIC_PAGE_URL}/best-price-guarantee`},
  {label: 'Zahlungsmöglichkeiten', url: `${STATIC_PAGE_URL}/billing-options`},
];

@inject('contentStore', 'searchStore')
@observer
export class Footer extends Component {
  render() {
    const {contentStore, searchStore} = this.props;
    //console.log(searchStore.savedSearches);
    return (
      <footer className='main-footer'>
        <article className='footer-left'>
          <div>
            <img alt='logo' src={Logo} />
          </div>
          <address>
            <p className='phone-number'>{contentStore.returnContent('phone_number')}</p>
            <p className='working-time'>{contentStore.returnContent('working_time')}</p>
            <a className='email-address' href={`mailto: ${MAIL}`}>
              {MAIL}
            </a>
            <div className='social-network'>
              <i className='circle-block extra-sm instagram' />
              <i className='circle-block extra-sm fb' />
            </div>
          </address>
        </article>
        <article className='footer-right'>
          <nav>
            <ul className='tabs-list'>
              <li>
                <I18n t='Booksana' />
              </li>
              {firstColumn.map((elem, i) => (
                <li key={i}>
                  <NavLink activeClassName='active-link' to={elem.url}>
                    <I18n t={elem.label} />
                  </NavLink>
                </li>
              ))}
            </ul>
            <ul className='tabs-list'>
              <li>
                <I18n t='Reiseziele' />
              </li>
              {searchStore.savedSearches.map((elem, i) => (
                <li key={i}>
                  <Link to={`/region/${elem.slug}`} target='_blank'>
                    <I18n t={elem.name} />
                  </Link>
                </li>
              ))}
            </ul>
            <ul className='tabs-list'>
              <li>
                <I18n t='Rechtliches' />
              </li>
              {thirdColumn.map((elem, i) => (
                <li key={i}>
                  <NavLink activeClassName='active-link' to={elem.url}>
                    <I18n t={elem.label} />
                  </NavLink>
                </li>
              ))}
            </ul>
          </nav>
          <p className='private-rules'>
            <span>© {moment().year()} booksana | Alle Rechte vorbehalten</span>
          </p>
        </article>
      </footer>
    );
  }
}
