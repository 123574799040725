import React, {Component} from 'react';
import {Link} from 'react-router-dom';

export class HotelLink extends Component {
  click = e => {
    const {searchRequestId, target} = this.props;
    if (searchRequestId) {
      const url = e.target.closest('a').href + '/' + this.props.searchRequestId;
      if (target === '_blank') window.open(url);
      else window.location.href = url;
      e.stopPropagation();
      e.preventDefault();
    }
  };

  render() {
    const {hotel, target, className} = this.props;
    return (
      <Link className={className} target={target} to={hotel.url} onClick={this.click}>
        {this.props.children}
      </Link>
    );
  }
}
