import dictionary from '../config/dictionary.yml';

const LANG = 'de';

export const translate = key => {
  if (typeof key != 'string') {
    return key;
  }

  //console.log(key);
  const path = key.split('.');
  let level = dictionary;
  for (let bk of path) {
    if (!level[bk]) {
      level = null;
      break;
    }
    level = level[bk];
  }

  if (level && typeof level == 'object') {
    level = level[LANG];
  }

  return level || key;
};
