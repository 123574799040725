import React, {Component, Fragment} from 'react';
import {I18n} from '../../helpers/i18n/I18n';

export class TimeLine extends Component {
  render() {
    const {wrapperClass, result, resultFigure, timeZones = []} = this.props;
    const liWidth = 100 / timeZones.length;
    return (
      <div className={`time-line ${wrapperClass || ''}`}>
        <ul className='time-zone'>
          {timeZones.map((zone, i) => (
            <li key={i} style={{width: `${liWidth}%`}}>
              {zone && (
                <Fragment>
                  <div className='time-figure'>{zone}</div>
                  <div className='zone-delimiter' />
                </Fragment>
              )}
            </li>
          ))}
        </ul>
        <div className='sides-wrapper'>
          <div className='empty-side' />
          <div style={{width: `${result}%`}} className='color-side'>
            <I18n class='time-figure' t={resultFigure} />
            <div className='zone-delimiter' />
          </div>
        </div>
      </div>
    );
  }
}
