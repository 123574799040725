import React, {Component, Fragment} from 'react';
import {I18n} from '../../helpers/i18n/I18n';
import {QuestionCircleOutlined} from '@ant-design/icons';

import Popover from 'antd/lib/popover';
import {isMobile, isTablet} from 'react-device-detect';

const inputStyle = {
  base: {
    lineHeight: '40px',
    fontSize: '16px',
    padding: '4px 11px',
    '::placeholder': {
      color: '#b3b3b3',
    },
  },
};

class StripeIbanFields extends Component {
  reset = () => {};

  componentDidMount() {
    console.log('------------------------------');
    const STRIPE_ID = process.env.REACT_APP_STRIPE;
    const stripe = new window.Stripe(STRIPE_ID);
    const elements = stripe.elements();
    const sepa = elements.create('iban', {
      style: inputStyle,
      supportedCountries: ['SEPA'],
    });

    this.setState({
      stripe,
      elements,
      sepa,
    });

    sepa.mount('#sepa');
  }

  render() {
    return (
      <Fragment>
        <I18n class='input-title' t='IBAN' />
        <div>
          <div className='input-container'>
            <div className='stripe-wrapper custom-input inp-lg '>
              <div id='sepa' style={inputStyle} />
              <Popover overlayClassName='custom-popover payments md' content={this.props.tooltip} placement='right' trigger={isMobile || isTablet ? 'click' : 'hover'}>
                <QuestionCircleOutlined className='hint-icon' />
              </Popover>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default StripeIbanFields;
