import React, {Component, Fragment} from 'react';
import {inject, observer} from 'mobx-react';
import Popover from 'antd/lib/popover';
import classNames from 'classnames';
import moment from 'moment';

import {FooterTxtBlock} from '../../helpers/footerTxtBlock/FooterTxtBlock';
import {HomeGallery} from '../../helpers/homeGallery/HomeGallery';
import {SearchForm} from '../../helpers/searchForm/SearchForm';
import {Preloader} from '../../helpers/preloader/Preloader';
import {I18n} from '../../helpers/i18n/I18n';
import {DATE_FORMAT, LOW_PRICE, RECOMMENDATION} from '../../../config/const';
import {search} from '../../../utils/Fetcher';
import {HotelsGallery} from '../../helpers/hotelsGallery/HotelsGallery';
import {withRouter} from 'react-router';

import {EmptyResults} from '../../helpers/EmptyResults';
import savedSearchesBg from '../../../images/suggestions-top-bg.png';
import {Link} from 'react-router-dom';
import {removeMetaData, setMetaData, isTablet, isDesktop, getSundayByOrderNumber} from '../../../utils/Utils';
import ReactHtmlParser from 'react-html-parser';
import {isMobile} from 'react-device-detect';
import HotelSuggestion, {FILTER_TYPE, FILTER} from '../hotelSuggestion/HotelSuggestion';
import IconsBlock from './IconsBlock';

@withRouter
@inject('searchStore', 'contentStore')
@observer
export class Home extends Component {
  state = {
    results: [],
    featured: [],
    discount: [],
    firstMomentDiscount: [],
    fetching: false,
  };
  mainForm = React.createRef();
  suggestionsBlock = React.createRef();
  showPromo = location.href.indexOf('/region/') === -1;

  componentDidMount() {
    this.createSeoDescription();
    this.getSavedSearches();
    if (this.mainForm.current && !this.props.savedSearch) {
      setMetaData(this.props.contentStore.returnContent('homepage_seo_title') || 'Booksana.com');
      const data = this.mainForm.current.returnFormData();
      this.getHotelOffers(data);
    }
  }

  createSeoDescription = () => {
    const meta = document.createElement('meta');
    meta.name = 'description';
    meta.content = this.props.contentStore.returnContent('homepage_seo_description') || 'homepage_seo_description';
    document.getElementsByTagName('head')[0].appendChild(meta);
  };

  componentWillUnmount() {
    this.props.savedSearch && removeMetaData();
  }

  getHotelOffers = data => {
    this.setState({fetching: true});
    const arrivalDate = getSundayByOrderNumber(2).format(DATE_FORMAT);
    const departureDate = getSundayByOrderNumber(2).add(6, 'days').format(DATE_FORMAT);
    Promise.all([
      search(
        {...data, arrivalDate, departureDate, isFeatured: true, hasDiscount: false},
        {
          size: 10,
          sort: 'recommendations',
        }
      ),
      search({...data, arrivalDate, departureDate, hasDiscount: true}, {size: 10, sort: 'discount'}),
      search(
        {
          ...data,
          arrivalDate: getSundayByOrderNumber(9).format(DATE_FORMAT),
          departureDate: getSundayByOrderNumber(9).add(6, 'days').format(DATE_FORMAT),
          discountTypeId: FILTER[FILTER_TYPE.firstMoment],
          hasDiscount: true,
        },
        {size: 10, sort: 'discount'}
      ),
    ]).then(values => {
      const featured = !values[0].isError ? values[0].data.content : [];
      const discount = !values[1].isError ? values[1].data.content : [];
      const firstMomentDiscount = !values[2].isError ? values[2].data.content : [];

      this.setState({featured, discount, firstMomentDiscount, fetching: false});
    });
  };

  returnRef = () => {
    return this.props.savedSearch ? this.suggestionsBlock.current.mainForm.current : this.mainForm.current;
  };

  getSavedSearches = () => {
    const {searchStore, savedSearch, match} = this.props;
    const refCmp = this.returnRef();
    searchStore.getSavedSearches().then(resp => {
      if (refCmp && savedSearch) {
        this.changeBg();
        const data = refCmp.returnFormData();
        const certainSearch = searchStore.certainSearch(match.params.name);
        setMetaData(certainSearch?.seoTitle);
        this.getHotelOffers(data);
      }
    });
  };

  changeBg = () => {
    const img = this.props.searchStore.certainSearch(this.props.match.params.name)?.imageMenu;
    const bgBlock = document.querySelector('.top-form-bg');
    bgBlock && (bgBlock.style.backgroundImage = `url(${img || savedSearchesBg})`);
  };

  onBtnClick = formData => {
    this.props.searchStore.setFormData(formData);
    this.props.history.push('/search-results');
  };

  saveCurrentFilter = () => {
    const refCmp = this.returnRef();
    this.props.savedSearch && this.props.searchStore.setFormData(refCmp.state);
  };

  addPopoverToHeadline = (txt = '', popoverTxt) => {
    const txtParts = txt.split('%');
    if (txtParts.length === 1 || txtParts.length > 2) {
      return txt;
    } else {
      return (
        <Fragment>
          <span>{txtParts[0]}</span>%
          <span className='hint-box'>
            {txtParts[1]}
            <Popover
              overlayClassName='custom-popover lg home-header'
              content={ReactHtmlParser(popoverTxt ? popoverTxt : 'Lorem ipsum')}
              placement='bottomLeft'
              trigger={isMobile || isTablet ? 'click' : 'hover'}
              afterVisibleChange={this.changeTooltipArrowPosition}
              arrowPointAtCenter
              // visible={true}
            >
              <i className='custom-hint sm black' id='tooltipHome' />
            </Popover>
          </span>
        </Fragment>
      );
    }
  };

  changeTooltipArrowPosition = isOpen => {
    if (isOpen && (isMobile || isTablet)) {
      try {
        const iconPosition = document.querySelector('#tooltipHome').getBoundingClientRect().left;
        document.getElementsByClassName('home-header')[0].children[0].children[0].style.left = `${iconPosition + 4}px`;
      } catch (e) {
        //console.log("cant migrate arrow")
      }
    }
  };

  render() {
    const {fetching, featured, discount, firstMomentDiscount} = this.state;
    const {savedSearch, searchStore, contentStore} = this.props;
    if (savedSearch && searchStore.savedSearchesFetching) {
      return (
        <section className='home-page'>
          <Preloader />
        </section>
      );
    }
    const certainSearch = searchStore.certainSearch(this.props.match.params.name);
    if (savedSearch && !certainSearch) {
      return (
        <section className='home-page txt-center'>
          <EmptyResults />
        </section>
      );
    }
    return (
      <section className='home-page'>
        <section className='top-form-wrapper'>
          <div className={classNames('top-form-bg', {'region-height': savedSearch})} />
          <div className='top-form-content'>
            <article className='form-slogan'>
              {savedSearch ? (
                <h1 className='slogan-txt lg'>
                  <span>{this.addPopoverToHeadline(certainSearch.headline, contentStore.returnContent('rabatt_tooltip', true))}</span>
                  {/*{certainSearch.subHeadline && <span className='sub-headline'>{certainSearch.subHeadline}</span>}*/}
                </h1>
              ) : (
                <h1 className='slogan-txt lg'>
                  <span>{this.addPopoverToHeadline(contentStore.returnContent('h_one_home'), contentStore.returnContent('rabatt_tooltip', true))}</span>
                </h1>
              )}
              <Link to='/information/voucher' className='circle-block lg'>
                <span className='circle-price'>{ReactHtmlParser(contentStore.returnContent('gutschein_wert'))}</span>
                <I18n class='circle-description' t='Gutschein sichern!' />
                <Popover
                  overlayClassName='custom-popover md home-price'
                  content={contentStore.returnContent('gutschein_tooltip')}
                  placement='bottomRight'
                  trigger={isMobile || isTablet ? 'click' : 'hover'}
                >
                  <div className='icon-container'>
                    <i className='custom-hint blue lg' />
                  </div>
                </Popover>
              </Link>
            </article>
            {savedSearch ? (
              <HotelSuggestion
                useSearchQueryDates
                history={this.props.history}
                location={this.props.location}
                usePriceSorting={this.props.savedSearch}
                certainSearch={certainSearch}
                hideElevatorInput
                ref={this.suggestionsBlock}
                showTopImg={false}
                showFlexGallery={false}
              />
            ) : (
              <SearchForm
                maxTagCount={isDesktop() ? 2 : 1}
                ref={this.mainForm}
                cleanOldParams={true}
                startSearchOnClick={false}
                checkDates={true}
                showTopTab={this.props.showTopTab}
                // certainSearch={certainSearch}
                onBtnClick={this.onBtnClick}
                onSearchFinish={this.onSearchFinish}
                onSearchError={this.onSearchError}
                onSearchStart={this.onSearchStart}
                searchBeforeRender={false}
                listenCalendarDirection={true}
                isHome
                url={window.location.href}
              />
            )}
            <div className='form-description-wrapper'>
              {!savedSearch && (
                <div className='form-description'>
                  <p className='description-item'>
                    <i className='custom-icon-ok' />
                    <span>{contentStore.returnContent('usp_home_eins')}</span>
                  </p>
                  <p className='description-item'>
                    <i className='custom-icon-ok' />
                    <span>{contentStore.returnContent('usphome_two')}</span>
                  </p>
                  <p className='description-item'>
                    <i className='custom-icon-ok' />
                    <span>{contentStore.returnContent('usp_three')}</span>
                  </p>
                </div>
              )}
              <IconsBlock />
            </div>
          </div>
        </section>

        {fetching ? (
          <Preloader />
        ) : (
          this.showPromo && (
            <Fragment>
              <section className='hotels-cards-block'>
                <HomeGallery
                  cards={discount}
                  cardType={LOW_PRICE}
                  linkAddress='/sonderangebote'
                  onBtnClick={this.saveCurrentFilter}
                  // headerTxt="Im Zeitraum von 29.06.2019 bis 05.07.2019"
                  btnTxt='home.gallery.stock.btn'
                  headerTitle='home.gallery.stock.header'
                  wrapperClass='bordered bot-pad'
                />
                <HomeGallery
                  cards={firstMomentDiscount}
                  description={false}
                  cardType={LOW_PRICE}
                  onBtnClick={this.saveCurrentFilter}
                  linkAddress={`/sonderangebote/${FILTER_TYPE.firstMoment}`}
                  btnTxt='Alle Frühbucher-Angebote anzeigen'
                  wrapperClass='bordered bot-pad'
                  headerTitle='Frühbucher-Aktion'
                />
                <HomeGallery
                  cards={featured}
                  description={false}
                  cardType={RECOMMENDATION}
                  onBtnClick={this.saveCurrentFilter}
                  linkAddress='/recommendations'
                  btnTxt='home.gallery.recommendations.btn'
                  wrapperClass='bordered bot-pad recommended'
                  headerTitle='home.gallery.recommendations.header'
                />
              </section>
              {!savedSearch && <HotelsGallery isFetching={searchStore.savedSearchesFetching} items={searchStore.savedSearches} galleryHeader='home.gallery.regions.header' />}
            </Fragment>
          )
        )}
        <FooterTxtBlock savedSearch={savedSearch} hasOwnMenu={certainSearch} menuContent={certainSearch?.seoMenu} tabContent={certainSearch?.seoDescription} />
      </section>
    );
  }
}
