import {action, observable} from 'mobx';
import {bookRoom, getHotelRoomsById} from '../utils/Fetcher';

class HotelRoomsStore {
  @observable selectedHotel = {};

  @action
  selectHotel(hotelId, requestId, pagination) {
    const prom = getHotelRoomsById(hotelId, requestId, pagination);
    prom.then(resp => {
      if (!resp.isError) {
        this.selectedHotel = resp.data;
      }
      return resp;
    });
    return prom;
  }

  @action
  setEmptyHotel() {
    this.selectedHotel.content = [];
  }

  @action
  bookRoom(data = {}) {
    const prom = bookRoom(data);
    prom.then(resp => {
      return resp;
    });
    return prom;
  }
}

export default HotelRoomsStore;
