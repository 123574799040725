import moment from 'moment';
import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {isMobileOnly} from 'react-device-detect';
import {LoadingOutlined} from '@ant-design/icons';
import DateRangePicker from 'react-dates/lib/components/DateRangePicker';
import classNames from 'classnames';
import {Popover, Affix} from 'antd';
import {I18n} from '../i18n/I18n';
import Radio from 'antd/lib/radio';
import Select from 'antd/lib/select';
import {SelectList} from '../selectList/SelectList';
import {translate} from '../../../utils/Translater';
import {DATE_FORMAT, PAGE_SIZE} from '../../../config/const';
import {search as searchHotels} from '../../../utils/Fetcher';
import {SORTING_OPTIONS} from '../gridSuggestions/GridSuggestions';
import {concatDateStrings, doSmoothScroll, isTablet, isDesktop, removeElemShadow, setElemShadow, getSundayByOrderNumber} from '../../../utils/Utils';
import {GuestsForm} from './GuestsForm';
import {InputTxt} from '../inputTxt/InputTxt';
import {CalendarElevator} from './CalendarElevator';
import Transfer from '../../modals/transfer/Transfer';
import {ReactComponent as QuestionIcon} from '../../../images/icons/question-dark.svg';
import qs from 'qs';

const {Option, OptGroup} = Select;

const AGES = Array.from(Array(18), (elem, i) => ({value: i, label: i}));
const MAX_RANGE = 40;
const PERIOD_LIMIT = 400;
const ADD_PERIOD = 1;

const CHILDREN = [
  {value: 0, label: 0},
  {value: 1, label: 1},
  {value: 2, label: 2},
];

const ADULTS = [
  {value: 1, label: 1},
  {value: 2, label: 2},
  {value: 3, label: 3},
  {value: 4, label: 4},
];

export const MEALS_TYPES = {
  FB: 'Vollpension',
  HB: 'Halbpension',
};

const MEALS = [
  {value: 'FB', label: 'Vollpension'},
  {value: 'HB', label: 'Halbpension'},
  // {value: "ALL", label: " All inclusive"},
];

const DEFAULT_ROOM = {adults: 2, children: []};

@inject('searchStore', 'popup', 'contentStore')
@observer
export class SearchForm extends Component {
  constructor(props) {
    super(props);
    this.createState();
    this.guestForm = React.createRef();
  }

  isFirst = true;

  createState() {
    const {request, certainSearch, searchStore, cleanOldParams, sundayOrderNumber, useSearchQueryDates} = this.props;
    const savedForm = searchStore.formData;
    let departureDate = null;
    let arrivalDate = null;
    const searchQuery = (this.props.location || {}).search || '';
    const queryParams = qs.parse(searchQuery, {ignoreQueryPrefix: true, decode: false, decoder: str => str});

    if (savedForm && cleanOldParams) {
      //remove old params from form if user comes from search results to home
      searchStore.removeFormParams();
    }

    if (sundayOrderNumber) {
      departureDate = getSundayByOrderNumber(sundayOrderNumber).add(6, 'days');
      arrivalDate = getSundayByOrderNumber(sundayOrderNumber);
    }

    const defaultState = {
      filter: [],
      searchString: null,
      departureDate,
      arrivalDate,
      indicationsSearch: false,
      regionsAndHotels: [{label: translate('search.form.hotel.filter'), items: []}],
      indications: [{label: translate('search.form.indications'), items: []}],
      rooms: [{...DEFAULT_ROOM}],
      error: null,
      regionId: [],
      dictionaries: [],
      calendarDirection: 'down',
      hotelId: [],
      indicationId: [],
      kindOfCatering: 'HB',
      fetching: false,
      transferIsActive: false,
      transferIndex: '',
      clickedInput: '',
      dropdownIsOpen: false,
      childrenAgesAreEmpty: false,
      transferModalActive: false,
    };

    if (useSearchQueryDates && searchQuery) {
      const {arrivalDate, departureDate, adults = DEFAULT_ROOM.adults, country = null, children, indicationId} = queryParams;
      const childrenArr = children ? children.split(',').map(age => +age) : [];
      const indicationIdArr = indicationId ? indicationId.split(',').map(id => +id) : [];

      if (arrivalDate && departureDate) {
        defaultState.departureDate = moment(departureDate, DATE_FORMAT);
        defaultState.arrivalDate = moment(arrivalDate, DATE_FORMAT);
      }

      defaultState.rooms[0].adults = +adults;
      defaultState.rooms[0].children = childrenArr;
      defaultState.country = country;
      defaultState.indicationId = indicationIdArr;
      defaultState.indicationsSearch = !!indicationIdArr.length;
    }

    if (certainSearch) {
      const {kindOfCatering, regionId, hotelId, filter} = queryParams;

      if (useSearchQueryDates && searchQuery) {
        const regionIdArr = regionId ? regionId.split(',').map(id => +id) : [];
        const hotelIdArr = hotelId ? hotelId.split(',').map(id => +id) : [];

        defaultState.regionId = regionIdArr;
        defaultState.hotelId = hotelIdArr;
      } else {
        certainSearch.regionId === 0 ? (defaultState.country = certainSearch.country) : (defaultState.regionId = [certainSearch.regionId]);
      }

      if (useSearchQueryDates && filter) {
        const filterArr = filter ? filter.split(',').map(name => decodeURIComponent(name)) : [];

        defaultState.filter = filterArr;
      } else {
        defaultState.filter = [certainSearch.name];
      }

      if (useSearchQueryDates && kindOfCatering) {
        defaultState.kindOfCatering = kindOfCatering;
      } else {
        defaultState.kindOfCatering = certainSearch.meals.toUpperCase();
      }
    }

    if (searchStore.formData) {
      this.state = {...savedForm, fetching: false};
    } else {
      if (request) {
        defaultState.departureDate = request.departureDate ? concatDateStrings(request.departureDate) : null;
        defaultState.arrivalDate = request.arrivalDate ? concatDateStrings(request.arrivalDate) : null;
        defaultState.rooms = [
          {
            adults: request.numberAdults || 1,
            children: request.children || [],
            meal: 'HB',
          },
        ];
        defaultState.regionId = request.regionId;
        defaultState.kindOfCatering = request.kindOfCatering;
        defaultState.hotelId = request.hotelId;
        defaultState.indicationId = request.indicationId;
        defaultState.filter = (request.dictionaries || []).length ? request.dictionaries.map(elem => elem.value) : [this.props.currentHotel];
        defaultState.indicationsSearch = !!request.indicationId;
        defaultState.transferIndex = request.transferIndex || '';
        defaultState.transferIsActive = request.transfer;
      }
      this.state = defaultState;
    }
  }

  UNSAFE_componentWillMount() {
    moment.locale('de'); //changing language
    const {searchBeforeRender = true, searchStore, certainSearch, pageParams = {}} = this.props;
    searchStore.getRegionsAndHotels(null, true, certainSearch?.country).then(resp => {
      const newItems = [...(searchStore.hotelsAndRegions.items || [])];
      if (certainSearch && certainSearch.regionId) {
        this.setState({
          regionsAndHotels: [
            {
              label: translate('search.form.hotel.filter'),
              items: newItems.filter(({id}) => id !== certainSearch.regionId),
            },
          ],
        });
      } else {
        this.setState({regionsAndHotels: [{label: translate('search.form.hotel.filter'), items: newItems}]});
      }
    });
    searchStore.getIndicationOptions().then(resp =>
      this.setState({
        indications: [{label: translate('search.form.indications'), items: searchStore.indications.items}],
      })
    );
    searchBeforeRender && this.search({size: PAGE_SIZE, sort: SORTING_OPTIONS[0].value, ...pageParams}); //todo
  }

  componentDidMount() {
    const {transferIsActive} = this.state;
    const start = document.querySelector('#search_start_date');
    const end = document.querySelector('#search_end_date');
    start.parentNode.addEventListener('click', () => this.detectClickedInput('start', start));
    end.parentNode.addEventListener('click', () => this.detectClickedInput('end', end));
    const {listenCalendarDirection, certainSearch} = this.props;
    // !!certainSearch && this.highlightDateInputs();
    listenCalendarDirection && window.addEventListener('scroll', this.changeCalendarDirection);
    if (isMobileOnly) {
      transferIsActive && document.body.classList.add('transferIsActive');
    }
  }

  detectClickedInput = (inputName, elem) => {
    const {clickedInput} = this.state;
    if (inputName === clickedInput) {
      this.setInputWrapperClass(null);
      this.setState({clickedInput: '', focusedInput: null}, () => elem.blur());
    } else {
      this.setState({clickedInput: inputName});
    }
  };

  componentWillUnmount() {
    const {listenCalendarDirection} = this.props;
    const start = document.querySelector('#search_start_date');
    const end = document.querySelector('#search_end_date');
    start.parentNode.removeEventListener('click', this.detectClickedInput);
    end.parentNode.removeEventListener('click', this.detectClickedInput);
    listenCalendarDirection && window.removeEventListener('scroll', this.changeCalendarDirection);
  }

  changeCalendarDirection = () => {
    const {calendarDirection} = this.state;
    const calendarInputId = '#search_start_date';
    const calendarHeight = 343;
    const bottomSpaceSize = window.innerHeight - document.querySelector(calendarInputId).getBoundingClientRect().bottom;
    let direction = 'down';
    bottomSpaceSize < calendarHeight && (direction = 'up');
    calendarDirection !== direction && this.setState({calendarDirection: direction});
  };
  inputChange = (propName, newValue) => {
    this.setState({[propName]: newValue});
  };
  onChange = (newValue, propName) => {
    if (propName === 'transferIsActive') {
      isMobileOnly && document.body.classList.toggle('transferIsActive');
      const newState = {
        [propName]: newValue,
        focusedInput: this.state.focusedInput ? 'startDate' : '',
        // departureDate: null,
        // arrivalDate: null,
      };

      if (newValue) {
        newState.focusedInput = 'startDate';
        newState.departureDate = null;
        newState.arrivalDate = null;
        const cb = () => {
          setTimeout(() => document.querySelector('#calendar-transfer-input').focus(), 100);
          this.setInputWrapperClass('startDate');
        };
        if (this.props.showTransfer) {
          this.props.showTransfer();
        }
        return this.setState(newState, cb);
      }

      this.isFirst = true;
      if (this.props.hideTransfer) {
        this.props.hideTransfer();
      }
      return this.setState(newState);
      // this.setState({arrivalDate: null, departureDate: null, focusedInput: this.state.focusedInput ? 'startDate' : ''});
    }
    if (propName === 'filter' && !newValue.length && this.props.certainSearch) {
      return;
    }
    this.setState({[propName]: newValue});
  };

  componentWillReceiveProps(nextProps) {
    const {activateTransfer = false} = nextProps;
    if (activateTransfer && this.isFirst) {
      isMobileOnly && document.body.classList.toggle('transferIsActive');
      this.setInputWrapperClass('startDate');
      this.isFirst = false;
      this.setState(() => ({
        transferIsActive: true,
        focusedInput: 'startDate',
        departureDate: null,
        arrivalDate: null,
      }));
    }
  }

  inputChanges = value => {
    const {searchStore, certainSearch} = this.props;
    const callBack = this.state.indicationsSearch ? searchStore.getIndicationOptions : searchStore.getRegionsAndHotels;
    if (value) {
      callBack(value, false, certainSearch?.country).then(resp => {
        if (!resp.isError) {
          const mappedData = resp.data.map(dic => ({...dic, label: dic.value}));
          this.setState({
            searchString: [
              {
                label: 'Search results',
                items: this.props.certainSearch && this.props.certainSearch.regionId ? mappedData.filter(({id}) => id !== this.props.certainSearch.regionId) : mappedData,
              },
            ],
          });
        }
      });
    } else {
      this.resetSearchResults();
    }
  };

  resetSearchResults = () => {
    this.setState({searchString: null});
  };

  changeRoomDetails = (index, key, value) => {
    const rooms = [...this.state.rooms];
    if (key === 'children') {
      let ages = [];
      if (Number(value) === 1) {
        ages = [rooms[index][key][0] || '-'];
      } else if (Number(value) === 2) {
        ages = [rooms[index][key][0] || '-', rooms[index][key][1] || '-'];
      }
      rooms[index][key] = ages;
      // rooms[index][key] = Array.from(Array(value), elem => 0);
    } else {
      rooms[index][key] = value;
    }
    const newState = {
      rooms,
      ...(key === 'children' && !value && {childrenAgesAreEmpty: false}), // if we set 0 children count and there was the error, we will remove it
    };
    //console.log(rooms, index, key);
    this.setState(newState);
  };

  changeChildAge = (roomIndex, childIndex, key, value) => {
    const rooms = [...this.state.rooms];
    rooms[roomIndex][key][childIndex] = value;
    this.setState({rooms, childrenAgesAreEmpty: rooms[roomIndex][key].some(age => age === '-')});
  };

  radioChange = e => {
    this.setState({
      searchString: null,
      transferIndex: '',
      indicationsSearch: e.target.value,
      filter: [],
      rooms: [{...DEFAULT_ROOM}],
      regionId: [],
      dictionaries: [],
      hotelId: [],
      indicationId: [],
      arrivalDate: null,
      transferIsActive: false,
      departureDate: null,
      country: null,
      childrenAgesAreEmpty: false,
    });
  };

  checkSelectedValue = selectedValue => {
    return this.state.filter.find(value => selectedValue === value);
  };

  dropdownItemsRender = (options, onChange) => {
    if (!Object.keys(options[0]).length) {
      return null;
    }
    return (options || []).map((item, i) => (
      <OptGroup key={i} label={item.label}>
        {item.items.map((option, key) => (
          <Option className='with-checkbox' style={{...(option.hide && {display: 'none'})}} key={key} obj={option} value={option.value} label={option.label}>
            <div className='fake-box'>
              <div className={`${this.checkSelectedValue(option.label) ? 'checked' : ''} box-checked`} />
            </div>
            <span className='custom-option-txt'> {option.label || option.value}</span>
          </Option>
        ))}
      </OptGroup>
    ));
  };

  returnFormData = () => {
    const {departureDate, arrivalDate, country, rooms, indicationsSearch, dictionaries, regionId, hotelId, indicationId, kindOfCatering, transferIndex, transferIsActive} = this.state;
    const {isFeatured, hasDiscount, discountTypeId} = this.props;
    return {
      departureDate: departureDate?.format(DATE_FORMAT),
      arrivalDate: arrivalDate?.format(DATE_FORMAT),
      numberAdults: rooms[0].adults,
      children: rooms[0].children,
      kindOfCatering: kindOfCatering,
      searchString: '',
      bestPrice: 0,
      hasDiscount: hasDiscount || null,
      discountTypeId: discountTypeId || null,
      isFeatured: isFeatured || null,
      showTreatments: indicationsSearch,
      checkAvailability: false,
      regionId: regionId?.length > 0 ? regionId : null,
      hotelId: hotelId?.length > 0 ? hotelId : null,
      indicationId: indicationId?.length > 0 ? indicationId : null,
      country: country || null,
      dictionaries: dictionaries,
      roomId: null,
      transferIndex: transferIndex || null,
      transfer: transferIsActive,
    };
  };

  search = (page = {size: this.props.pagesSize || PAGE_SIZE}, filters = []) => {
    //filters prop which consists of checked filters in left side of search results
    this.setState({fetching: true});
    const {onSearchFinish, onSearchError, onSearchStart, onBtnClick, certainSearch} = this.props;

    onSearchStart && onSearchStart();

    let form = this.returnFormData();
    form.attributes = filters;

    if (this.props.useSearchQueryDates) {
      const {pathName} = this.props.location;
      const {filter} = this.state;
      const {departureDate, arrivalDate, kindOfCatering, numberAdults, children, regionId, country, hotelId, indicationId} = form;

      let queryString = `?arrivalDate=${arrivalDate}&departureDate=${departureDate}&kindOfCatering=${kindOfCatering}`;

      const gclid = new URLSearchParams(window.location.search);
      if (gclid.get('gclid')) {
        queryString += `&gclid=${gclid.get('gclid')}`;
      }

      if (country || (certainSearch && certainSearch.regionId === 0)) {
        queryString += `&country=${country || certainSearch.country}`;
      }

      if ((filter || []).length) {
        queryString += `&filter=${filter.map(name => encodeURIComponent(name))}`;
      }

      if ((regionId || []).length || (certainSearch && certainSearch.regionId !== 0)) {
        queryString += `&regionId=${regionId || [certainSearch.regionId]}`;
      }

      if ((indicationId || []).length) {
        queryString += `&indicationId=${indicationId}`;
      }

      if ((children || []).length) {
        queryString += `&children=${children}`;
      }

      if ((hotelId || []).length) {
        queryString += `&hotelId=${hotelId}`;
      }

      if (numberAdults) {
        queryString += `&adults=${numberAdults}`;
      }

      this.props.history.replace({pathName, search: queryString});
    }

    searchHotels(form, page).then(req => {
      if (req.isError) {
        this.setState({fetching: false});
        onSearchError && onSearchError(req);
      } else {
        //console.log(req);
        this.setState({fetching: false});
        onSearchFinish && onSearchFinish(req.data, {...form, destination: this.state.filter});
      }
      document.dispatchEvent(new Event('search-finished'));
    });
  };

  checkDateInputs = () => {
    !this.state.arrivalDate && setElemShadow('#search_start_date');
    !this.state.departureDate && setElemShadow('#search_end_date');
    return !!(this.state.departureDate && this.state.arrivalDate);
  };

  changeAgesErrorFlag = newState => {
    this.setState({childrenAgesAreEmpty: newState});
  };

  checkChildrenAges = ev => {
    // fucking magic, be careful
    //todo add scroll to form
    const result = this.state.rooms[0].children.some(age => age === '-');
    this.state.childrenAgesAreEmpty !== result && this.changeAgesErrorFlag(result); // prevent double setstating =)
    if (result && !this.guestForm.current.state.isOpen) {
      ev.stopImmediatePropagation();
      this.guestForm.current.changeOpenState();
    }
    return !result;
  };

  highlightDateInputs = () => {
    setElemShadow('#search_start_date');
    setElemShadow('#search_end_date');
  };

  onSearchClick = ev => {
    const {onBtnClick, startSearchOnClick = true, checkDates, pageParams, filterParams, scrollElemId, scrollOffset = 0} = this.props;
    const canStarSearch = checkDates ? this.checkDateInputs() : true;
    const childrenAgesAreValid = this.checkChildrenAges(ev);
    scrollElemId && childrenAgesAreValid && doSmoothScroll(scrollElemId, scrollOffset);
    if (canStarSearch && childrenAgesAreValid) {
      startSearchOnClick && this.search(pageParams, filterParams);
      onBtnClick && onBtnClick(this.state);
    }
  };

  detectOptionType = (type, id, value, removeId = false) => {
    let propName = null;
    id = Number(id);
    //here we check type of Obj, type is property which comes from server.Depending on the type ,we add id into some arr in our state
    if (type === 'Region') {
      propName = 'regionId';
    } else if (type === 'Indication') {
      propName = 'indicationId';
    } else if (type === 'Hotel') {
      propName = 'hotelId';
    } else if (type === 'Country') {
      this.setState({country: null}); //todo!!
      return;
    } else {
      console.log('Unknown type');
    }
    const listOfId = !removeId ? [...this.state[propName], id] : this.state[propName].filter(item => item !== id);
    const dictionaries = !removeId
      ? [
          ...this.state.dictionaries,
          {
            type,
            id,
            value,
          },
        ]
      : this.state.dictionaries.filter(item => item.value !== value && item.id !== id);
    propName && this.setState({[propName]: listOfId, dictionaries: dictionaries});
  };

  disableDates = day => {
    const {arrivalDate, focusedInput} = this.state;
    const availableDay = moment().add(ADD_PERIOD, 'days').startOf('day');
    const calendarDay = day.valueOf();
    if (arrivalDate && !(focusedInput === 'startDate')) {
      return calendarDay <= availableDay.valueOf() || calendarDay > moment(arrivalDate).add(MAX_RANGE, 'days').valueOf();
    }
    return calendarDay <= availableDay.valueOf() || calendarDay > availableDay.add(PERIOD_LIMIT, 'days').valueOf();
  };

  changeSelectArray = (removeId, value, option) => {
    const optionData = option.props.obj;
    if (Object.keys(optionData || {}).length) {
      this.detectOptionType(optionData.type, optionData.id, optionData.value, removeId);
    }
  };

  calendarChooseDate = ({startDate, endDate}) => {
    if (this.props.checkDates || this.props.removeDateShadow) {
      startDate && removeElemShadow('#search_start_date');
      endDate && removeElemShadow('#search_end_date');
    }
    const newState = {arrivalDate: startDate, departureDate: endDate};
    if (startDate && endDate) {
      newState.clickedInput = '';
      newState.focusedInput = null;
      this.setInputWrapperClass(null);
    }

    this.setState(newState);
  };

  onFocusChange = focusedInput => {
    this.setInputWrapperClass(focusedInput);
    this.setState({focusedInput});
  };

  setInputWrapperClass = input => {
    const startInput = document.querySelector('#search_start_date');
    const endInput = document.querySelector('#search_end_date');
    if (input === 'startDate') {
      startInput && startInput.parentNode.classList.remove('unfocused');
      endInput && endInput.parentNode.classList.add('unfocused');
    } else if (input === 'endDate') {
      startInput && startInput.parentNode.classList.add('unfocused');
      endInput && endInput.parentNode.classList.remove('unfocused');
    } else {
      [startInput, endInput].forEach(elem => {
        elem && elem.parentNode.classList.remove('unfocused');
      });
    }
  };

  removeGuests = () => {
    this.setState({rooms: [{...DEFAULT_ROOM}], childrenAgesAreEmpty: false});
  };

  openModal = (modalCmp, onOk, data) => {
    this.props.popup.open(modalCmp, onOk, null, data);
  };

  isDayBlocked = (transferIndex, day) => {
    const {focusedInput, arrivalDate, departureDate} = this.state;
    if (transferIndex.length !== 5 || !this.props.searchStore.transferIndexes.some(index => index.toString() === `${transferIndex[0]}${transferIndex[1]}`)) {
      return true;
    }
    if (focusedInput === 'startDate') {
      return moment(day).valueOf() < moment(departureDate).subtract(MAX_RANGE, 'days').valueOf() || moment(day).valueOf() < moment().valueOf() || moment(day).day() !== 0;
    } else {
      return moment(day).valueOf() < moment().day(7).valueOf() || moment(day).valueOf() <= moment(arrivalDate).valueOf() || moment(day).day() !== 6;
    }
  };

  closeCalendar = () => {
    this.setState({clickedInput: '', focusedInput: null});
  };

  changeMoreTagName = omittedValues => {
    return (
      <span className='more-counter'>
        &#43; {omittedValues.length}
        <I18n t='more' />
      </span>
    );
  };
  toggleDropdown = () => {
    this.setState({dropdownIsOpen: !this.state.dropdownIsOpen});
  };

  clearInputs = () => {
    this.setState({arrivalDate: null, departureDate: null, focusedInput: 'startDate', clickedInput: 'start'});
  };

  toggleTransferModal = () => {
    this.setState({
      ...this.state,
      transferModalActive: !this.state.transferModalActive,
    });
  };

  render() {
    const {
      showTopTab = true,
      hideElevatorInput,
      searchListDisabled,
      certainSearch,
      hideMultiBox,
      wrapperClass,
      showElevator = true,
      maxTagCount,
      dropdownClassName,
      calendarIsRight = true,
      indexReadOnly,
      contentStore,
      isHome,
      url = '',
    } = this.props;

    const {
      indicationsSearch,
      focusedInput,
      calendarDirection,
      searchString,
      regionsAndHotels,
      indications,
      filter,
      rooms,
      departureDate,
      arrivalDate,
      fetching,
      kindOfCatering,
      transferIsActive,
      transferIndex,
      dropdownIsOpen,
      transferModalActive,
    } = this.state;

    const filterTitle = indicationsSearch ? 'search.form.indications' : 'search.form.destination';
    const filterOptions = indicationsSearch ? indications : regionsAndHotels;

    const selectTagCount = !dropdownIsOpen ? maxTagCount : null;
    const filterPlaceholder = !indicationsSearch ? translate('search.form.placeholder.hotel') : translate('search.form.placeholder.indications');
    const searchBtn = (
      <button disabled={fetching} className={classNames('custom-btn-md btn-apply', {'full-size': hideElevatorInput})} onClick={this.onSearchClick}>
        {fetching ? <LoadingOutlined className='btn-spinner' /> : <I18n t={this.props.serachBtnLabel || 'search.form.button.search'} />}
      </button>
    );

    return (
      <article className={`main-inputs-form ${wrapperClass || ''} form-region`}>
        {showTopTab && (
          <div className='radio-switch'>
            <div className={`${!indicationsSearch ? 'radio-selected' : ''}`}>
              <Radio className='custom-radio' value={false} onChange={this.radioChange} checked={!indicationsSearch}>
                <I18n t='search.form.hotel.filter' />:
              </Radio>
            </div>
            <div className={`${indicationsSearch ? 'radio-selected' : ''}`}>
              <Radio className='custom-radio' value={true} onChange={this.radioChange} checked={indicationsSearch}>
                <I18n t='search.form.hotel.map' />:
              </Radio>
            </div>
          </div>
        )}
        <div className='form' id='main-search-form'>
          <div className='period-inputs'>
            {showElevator && (
              <div className={classNames('transfer-options', {'full-size': hideElevatorInput})}>
                <Radio className='custom-radio' value={false} onChange={this.onChange.bind(this, false, 'transferIsActive')} checked={!transferIsActive}>
                  <I18n t='Eigene Anreise' />
                </Radio>
                <Radio className='custom-radio' value={true} onChange={this.onChange.bind(this, true, 'transferIsActive')} checked={transferIsActive}>
                  <I18n t='Haustürtransfer' />
                  {(isDesktop() || isTablet()) && (
                    <Popover
                      placement='right'
                      overlayClassName='custom-popover lg'
                      content={
                        <div className='transfer-detail'>
                          <h3 className='transfer-title'>
                            <span className='sm'>Haustürtransfer</span>
                            <span className='md'>Werfen Sie Ihre Sorgen über Bord und starten Sie entspannt in den Urlaub.</span>
                          </h3>
                          <div className='transfer-body'>
                            <p>
                              Bei uns beginnt Ihre Reise nach Ihrem Wunsch bereits ab Ihrer Haustür.
                              <br />
                              Für Sie bedeutet dies, dass Sie sich um nichts kümmern müssen und Ihrer Erholungs-Pur bereits ab der ersten Minuten geniessen können!
                            </p>
                            <ul>
                              <li>Direkt ab ihrer Haustür</li>
                              <li>Stress frei und Entspannt in den Urlaub</li>
                              <li>Keine schweren Koffer tragen</li>
                              <li>Keine Parkgebühren vor Ort</li>
                            </ul>
                            <p className='cancelation-fee'>
                              <strong>Buchen Sie Ihrer Aufenthalt gleich mit Haustürabholung</strong>
                            </p>
                            <p>Und so geht’s:</p>
                            <ul>
                              <li>Wählen Sie in die Suchmaske eine Option „mit Bustransfer“</li>
                              <li>Geben Sie Ihre Postleitzahl und wählen Sie Ihr An- und Abreisetermin</li>
                              <li>Wählen Sie Ihre Wunschaufenthalt und klicken Sie auf den Button "zur Buchung"</li>
                              <li>Schliessen Sie Ihren Buchungsvorgang mit der Abholadresse ab</li>
                            </ul>
                            <p className='cancelation-fee'>Den Rest werden wir für Sie erledigen, Sie müssen nun Ihr Koffer zum Reisetermin selber packen!</p>
                            <p>Über die Abholzeiten wird Ihnen spätestens 1 Tag vor der Abreise vom Fahrdienst telefonisch benachrichtigt.</p>
                            <p className='cancelation-fee'>
                              <strong>Brauchen Sie mehr Informationen?</strong>
                            </p>
                            <p>
                              Gern sind Ihnen auch unsere Reiseberater unter der Rufnummer:
                              <br />
                              030 40 368 77 99 persönlich für Sie da!
                            </p>
                          </div>
                        </div>
                      }
                    >
                      <span className='hint-icon'>
                        <QuestionIcon />
                      </span>
                    </Popover>
                  )}
                </Radio>
              </div>
            )}
            {!hideMultiBox && (
              <SelectList
                maxTagCount={selectTagCount}
                dropdownClassName={dropdownClassName}
                maxTagPlaceholder={this.changeMoreTagName}
                onDropdownVisibleChange={this.toggleDropdown}
                selectTitle={filterTitle}
                selectedItems={filter}
                placeholder={filterPlaceholder}
                selectListName='filter'
                wrapperClass={classNames('lg type-check', {
                  disabled: searchListDisabled,
                  'tag-hide-mode': certainSearch,
                  'hide-tags': !dropdownIsOpen,
                })}
                mode='tags'
                disabled={searchListDisabled}
                resetSearch={this.resetSearchResults}
                onSearch={this.inputChanges}
                onChange={this.onChange}
                optionLabelProp='label'
                onSelect={this.changeSelectArray.bind(this, false)}
                onDeselect={this.changeSelectArray.bind(this, true)}
                // dropdownRender={this.dropdownRender}
                itemsRender={this.dropdownItemsRender}
                options={searchString || filterOptions}
              />
            )}
            <div className={classNames('rangepicker-wrapper', {transfer: transferIsActive})}>
              <div>
                <I18n class='rangepicker-header' t='search.form.arrival' />
                <div className='headers-delimiter' />
                <I18n class='rangepicker-header' t='search.form.departure' />
              </div>
              <DateRangePicker
                onClose={this.onChange.bind(this, '', 'clickedInput')}
                startDate={arrivalDate}
                endDate={departureDate}
                focusedInput={focusedInput}
                startDatePlaceholderText={translate('search.form.select_date')}
                endDatePlaceholderText={translate('search.form.select_date')}
                startDateId='search_start_date'
                endDateId='search_end_date'
                displayFormat={DATE_FORMAT}
                anchorDirection={showElevator && calendarIsRight ? 'right' : 'left'}
                openDirection={calendarDirection}
                firstDayOfWeek={1}
                readOnly={isMobileOnly}
                withFullScreenPortal={isMobileOnly}
                orientation={isMobileOnly ? 'verticalScrollable' : 'horizontal'}
                numberOfMonths={isMobileOnly ? 12 : 2}
                isOutsideRange={this.disableDates}
                isDayBlocked={transferIsActive ? this.isDayBlocked.bind(this, transferIndex) : () => false}
                onDatesChange={this.calendarChooseDate}
                onFocusChange={this.onFocusChange}
                customArrowIcon={<div className='dates-delimiter' />}
                hideKeyboardShortcutsPanel={true}
                keepOpenOnDateSelect
                calendarInfoPosition='top'
                renderCalendarInfo={() => (
                  <CalendarElevator
                    transferIndexes={this.props.searchStore.transferIndexes}
                    transferIndex={transferIndex}
                    inputChange={this.inputChange}
                    transferIsActive={transferIsActive}
                    onChange={this.onChange}
                    indexReadOnly={indexReadOnly}
                    toggleTransferModal={this.toggleTransferModal}
                    favLink={`https://betterbusiness.typeform.com/to/JiFI8oH7#url=${url}&hotel=${this.props.currentHotel || ''}&anreise=${arrivalDate}&abreise=${departureDate}&anzahl=${
                      rooms[0].adults
                    }&verpflegung=${kindOfCatering}&transfer=${transferIsActive}&plz=${transferIndex}`}
                    callLink={`https://betterbusiness.typeform.com/to/oL1b4nLk#url=${url}`}
                    isHotel={!!(this.props.currentHotel || '')}
                  />
                )}
                noBorder
              />
            </div>
            <div className='inputs-direction-wrapper'>
              {rooms.map((room, i) => {
                return (
                  <div
                    className={classNames('other-parameters', {
                      'error-view': this.state.childrenAgesAreEmpty,
                      'hide-title': rooms.length > 1,
                    })}
                    key={i}
                  >
                    <GuestsForm ref={this.guestForm} removeGuests={this.removeGuests} adults={room.adults} childrenCount={room.children}>
                      <div className='select-wrapper'>
                        <div className='options-line'>
                          <SelectList
                            dropdownClassName='adults-dd-list'
                            selectTitle='search.form.adults'
                            selectedItems={room.adults}
                            wrapperClass='sm'
                            options={ADULTS}
                            onChange={val => this.changeRoomDetails(i, 'adults', val)}
                          />
                          <SelectList
                            dropdownClassName='children-dd-list'
                            selectTitle='search.form.child'
                            selectedItems={room.children.length}
                            wrapperClass='sm'
                            options={CHILDREN}
                            onChange={val => this.changeRoomDetails(i, 'children', val)}
                          />
                        </div>
                        {room.children.length > 0 && (
                          <div className='children-age-wrapper'>
                            <I18n class='custom-select-header' t='search.form.children_age' />
                            <div className='select-list-row'>
                              {room.children.map((childAge, childNumber) => (
                                <SelectList
                                  dropdownClassName={`children-age-dd-list-${childNumber}`}
                                  key={childNumber}
                                  selectedItems={childAge}
                                  wrapperClass={classNames(room.children.length === 1 ? 'sm-mdx' : 'sm-md', 'children-age-selector', {'empty-value': childAge === '-'})}
                                  options={AGES}
                                  onChange={val => this.changeChildAge(i, childNumber, 'children', val)}
                                />
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                      {room.children.length > 0 && (
                        <div className='select-txt'>
                          <I18n
                            t='Das genaue Alter der Kinder wird benötigt, um Kinderermäßigungen gewähren zu können.
                        Bitte geben Sie unbedingt das Alter des/der Kindes/Kinder bei Reiseantritt an.'
                          />
                        </div>
                      )}
                    </GuestsForm>
                    <SelectList selectTitle='search.form.meals' selectListName='kindOfCatering' selectedItems={kindOfCatering} wrapperClass='md mobile-view' options={MEALS} onChange={this.onChange} />
                  </div>
                );
              })}
            </div>
            <SelectList selectTitle='search.form.meals' selectListName='kindOfCatering' selectedItems={kindOfCatering} wrapperClass='md desktop-view' options={MEALS} onChange={this.onChange} />
          </div>
          <div className='inputs-direction-wrapper size-watch'>
            {/*{showElevator && !hideElevatorInput && (*/}
            {/*  <InputTxt*/}
            {/*    onChange={this.inputChange}*/}
            {/*    onlyNumbers={true}*/}
            {/*    disabled={!transferIsActive}*/}
            {/*    name='transferIndex'*/}
            {/*    maxLength={5}*/}
            {/*    title='PLZ/Abfahrtsort'*/}
            {/*    placeholder='PLZ'*/}
            {/*    value={transferIndex}*/}
            {/*    wrappersClass={`transfer-input ${transferIsActive ? '' : 'disabled'}`}*/}
            {/*  />*/}
            {/*)}*/}
            {/*{((wrapperClass && wrapperClass !== 'short-form') || isMobileOnly) && (*/}
            {/*  <div className={classNames('hint-text pr', {home: isHome})}>*/}
            {/*    {!transferIsActive && !isMobileOnly && (*/}
            {/*      <>*/}
            {/*        <I18n t='Geben Sie bitte Ihre Postleitzahl an. Danach können sie Ihr Anreise- und Abreisedatum wählen.' />*/}
            {/*        <div className='transfer-txt'>*/}
            {/*          {contentStore.returnContent('transfer search result')}{' '}*/}
            {/*          <button type='button' onClick={this.toggleTransferModal}>*/}
            {/*            mehr*/}
            {/*          </button>*/}
            {/*        </div>*/}
            {/*      </>*/}
            {/*    )}*/}
            {/*  </div>*/}
            {/*)}*/}
            {this.props.makeBtnFixed ? (
              <Affix className='fixed-search-start-btn' offsetTop={this.props.affixOffset || 0}>
                <div className='btn-color-wrapper'>{searchBtn}</div>
              </Affix>
            ) : (
              searchBtn
            )}
            <Transfer isActive={transferModalActive} onClose={this.toggleTransferModal} />
          </div>
        </div>
      </article>
    );
  }
}
