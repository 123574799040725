import React from 'react';
import Tabs from 'antd/lib/tabs';
import * as Scroll from 'react-scroll/modules';
import {I18n} from '../../helpers/i18n/I18n';

const SpyLink = Scroll.Link;

const {TabPane} = Tabs;

const tabNavItems = [
  {key: '1', label: 'Ihre persönlichen Daten', sectionId: 'consumer-info'},
  {key: '2', label: 'Reisende Personen', sectionId: 'guests-info'},
  {key: '3', label: 'Hotel-Zusatzleistungen', sectionId: 'hotelServices'},
  {key: '4', label: 'Transfer', sectionId: 'newIndexAddress'},
  {key: '5', label: 'Reiseschutz', sectionId: 'insurance'},
  {key: '6', label: 'Zahlungsdaten', sectionId: 'paymentDetails'},
];

class TabsNavigation extends React.PureComponent {
  state = {
    activeTab: '1',
  };

  changeTab = key => {
    this.setState({activeTab: key});
  };

  render() {
    const {activeTab} = this.state;
    const {transferIsActive, options, isNewPage} = this.props;

    const offSet = -50;

    return (
      <div className='navigate-wrapper'>
        <Tabs activeKey={activeTab} animated={false}>
          {tabNavItems.map(tabItem => {
            if (
              (!options.length && tabItem.sectionId === 'hotelServices') ||
              (!transferIsActive && tabItem.sectionId === 'newIndexAddress') ||
              (!options.length && isNewPage && tabItem.sectionId === 'insurance') ||
              (!options.length && isNewPage && tabItem.sectionId === 'paymentDetails')
            ) {
              return null;
            }

            return (
              <TabPane
                key={tabItem.key}
                tab={
                  <SpyLink spy={true} smooth={true} delay={0} onSetActive={() => this.changeTab(tabItem.key)} duration={800} offset={offSet} to={tabItem.sectionId}>
                    <I18n t={tabItem.label} />
                  </SpyLink>
                }
              />
            );
          })}
        </Tabs>
      </div>
    );
  }
}

export default TabsNavigation;
