import React, {Component} from 'react';
import Checkbox from 'antd/lib/checkbox';
import {I18n} from '../i18n/I18n';

export class CheckBox extends Component {
  render() {
    const {title, inputError, className, onChange, checked} = this.props;
    return (
      <div className={`check-box-wrapper ${className || ''} ${inputError ? 'box-error' : ''}`}>
        <Checkbox checked={checked} onChange={onChange}>
          <I18n class='checkbox-txt' t={title} />
        </Checkbox>
      </div>
    );
  }
}
