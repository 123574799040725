import React from 'react';
import {Price} from '../price/Price';

export const PriceView = ({hasDiscount, oldPrice, price, className}) => (
  <div className='final-sum'>
    <div className={`price ${className ? className : ''}`}>
      <div>
        <span className='current-price'>
          <Price withDiscount={hasDiscount} price={price} />
        </span>
      </div>
      {hasDiscount ? (
        <span className='old-price'>
          <Price isDiscount price={oldPrice} /> /
        </span>
      ) : null}
    </div>
  </div>
);
