import React, {Component} from 'react';
import {I18n} from '../../helpers/i18n/I18n';
import {ApplyCancellation} from './ApplyCancellation';
import {ReservationInputs} from './ReservationInputs';
import {Price} from '../../helpers/price/Price';
import {calcNights} from '../../../utils/Utils';
import {CancelationOption} from '../../helpers/CancelationOption';
import {getText} from '../../../utils/Fetcher';
import {DATE_FORMAT, ORDER_STATUS} from '../../../config/const';

import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';

export class FoundOrder extends Component {
  state = {
    cardContent: '',
  };

  componentDidMount() {
    getText('cancelation_sidebar_static').then(resp => this.setState({cardContent: resp.data?.value}));
  }

  render() {
    const {changeCard, order} = this.props;
    return (
      <article className='cancel-card'>
        <div className='left-section'>
          <h2>
            <I18n t='Ihre Reservierungsdetails' />
          </h2>
          <h3>
            <I18n t='Reservierungsnr:' /> <span>{order.booking.id}</span>
          </h3>
          <h3>
            <I18n t='Reservierungsdatum:' />
            <span>{moment(`${order.booking.created.year}.${order.booking.created.monthValue}.${order.booking.created.dayOfMonth}`, 'YYYY.MM.DD').format(DATE_FORMAT)}</span>
          </h3>
          <h3>
            <I18n t='Status:' /> <span>{ORDER_STATUS.get(order.booking.status)}</span>
          </h3>
          <h3>
            <I18n t='Aufenthaltsdauer:' />
            <span>{calcNights(order.booking.arrivalDate, order.booking.departureDate)} Nächte</span>
          </h3>
          <h3>
            <I18n t='Anreisedatum:' /> <span>{order.booking.arrivalDate}</span>
          </h3>
          <h3>
            <I18n t='Abreisedatum:' /> <span>{order.booking.departureDate}</span>
          </h3>
          <h3>
            <I18n t='Gesamtpreis:' /> <Price price={order.booking.price} />
          </h3>
        </div>
        <div className='right-section details'>
          <div className='sections-txt'>
            <h4>
              <I18n t={`Hallo ${order.booking.client.salutation || ''} ${order.booking.client.firstName} ${order.booking.client.lastName},`} />
            </h4>
            {order.booking.status < 4 ? (
              <div>
                {/*{ReactHtmlParser(this.state.cardContent)}*/}
                <div className='cancelation-fees'>
                  <CancelationOption cancelationPage price={order.booking.price} arrivalDate={order.booking.arrivalDate} options={order.hotel.cancellationFees} />
                </div>
              </div>
            ) : (
              <div style={{marginBottom: 0}}>
                <h4>
                  <I18n t='Ihre Buchung wurde bereits storniert!' />
                </h4>
                <h4 className='m-0'>
                  <I18n t='Haben Sie noch Fragen?' />
                </h4>
                <p>
                  <I18n t='Sie erreichen unser Service-Team montags bis freitags von 10:00 bis 19:00 Uhr telefonisch unter 030 / 40 368 77 99 oder per E-mail über kundenservice@booksana.com' />
                </p>
              </div>
            )}
          </div>
          <div className='sections-footer'>
            {order.booking.status < 4 && (
              <h4>
                <I18n t='Wie möchten Sie fortfahren?' />
              </h4>
            )}
            <button className='custom-empty-btn' onClick={changeCard.bind(this, ReservationInputs)}>
              <I18n t='Abbrechen ' />
            </button>
            {order.booking.status < 4 && (
              <button className='custom-btn' onClick={changeCard.bind(this, ApplyCancellation)}>
                <I18n t='Stornieren' />
              </button>
            )}
          </div>
        </div>
      </article>
    );
  }
}
