import React from 'react';
import SadFace from '../../../images/sad-face.png';
import {I18n} from '../i18n/I18n';

const defaultHeader = 'Es tut uns leid. Leider konnten wir zu Ihren Suchkriterien keine Ergebnisse finden.';
const defaultTxt = 'Versuchen Sie es mit anderen Reisedaten, anderer Verpflegung\n oder wählen Sie eine alternative Region.';
export const EmptyResult = ({header = defaultHeader, txt = defaultTxt}) => (
  <article className='empty-results'>
    <img src={SadFace} alt='sad-face' />
    <h4>
      <I18n t={header} />
    </h4>
    <p>
      <I18n t={txt} />
    </p>
  </article>
);
