import React, {Fragment} from 'react';

import {ReactComponent as ArrowIc} from '../../../images/icons/rectangle-separator.svg';
import {ReactComponent as FilterIc} from '../../../images/icons/filter-fill.svg';
import {SORTING_OPTIONS} from '../../helpers/gridSuggestions/GridSuggestions';
import {SelectList} from '../../helpers/selectList/SelectList';
import {I18n} from '../../helpers/i18n/I18n';

export const FiltersNavTab = ({fetching, isEmpty, total, pagesSort, selectedFilters, removeFilters, openTableFilter, openSorting, changeSelection, renderResetBtn}) => (
  <div className='suggestions-header-wrapper'>
    <div className='suggestions-header'>
      <span className='items-count'>
        {fetching && <I18n t='search.table.header.loading' />}
        {!fetching && !isEmpty && (
          <Fragment>
            {total} <I18n t='Kur-Hotels für Ihre Kur-Reise' />
          </Fragment>
        )}
        {selectedFilters > 0 && renderResetBtn && (
          <span className='remove-btn' onClick={removeFilters}>
            <I18n t='Alle Filter zurücksetzen' />
          </span>
        )}
      </span>
      <div className='mobile-view'>
        <div onClick={openTableFilter}>
          <I18n class='sort-title' t='search.table.header.filter' />
          {selectedFilters > 0 ? `(${selectedFilters})` : ''}
          <FilterIc />
        </div>
        <div onClick={openSorting}>
          <I18n class='sort-title' t='search.table.header.sorting' />
          <ArrowIc />
        </div>
      </div>
      <div className='items-sort'>
        <I18n class='sort-title' t='search.table.header.sorting' />
        <SelectList onChange={changeSelection} disabled={fetching} wrapperClass='sort-list' selectListName='pagesSort' selectedItems={pagesSort} options={SORTING_OPTIONS} />
      </div>
    </div>
  </div>
);
